/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LoginRequest } from '../models/LoginRequest';
import type { LoginUserResponse } from '../models/LoginUserResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class LoginService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Login for user.
     * @param requestBody
     * @returns LoginUserResponse
     * @throws ApiError
     */
    public loginCreate(
        requestBody: LoginRequest,
    ): CancelablePromise<LoginUserResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/login/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
