import { createActionTypes, ActionStatus } from "./requestConstant";

export const LOAD_USER_PROFILE_ACTION: ActionStatus = createActionTypes( 'LOAD_USER_PROFILE' );
export const UPDATE_USER_PROFILE_ACTION: ActionStatus = createActionTypes( 'UPDATE_USER_PROFILE' );
export const UPDATE_USER_NOTIFICATIONS_ACTION: ActionStatus = createActionTypes( 'UPDATE_USER_NOTIFICATIONS' );
export const UPDATE_REFERRAL_CONTACTS_ACTION: ActionStatus = createActionTypes( 'UPDATE_REFERRAL_CONTACTS' );
export const USER_SUMMARY_ANALYTICS_ACTION: ActionStatus = createActionTypes( 'USER_SUMMARY_ANALYTICS' );
export const REFERRAL_NOTIFICATIONS_ACTION: ActionStatus = createActionTypes( 'REFERRAL_NOTIFICATIONS' );
export const REQUEST_HELP_ACTION: ActionStatus = createActionTypes( 'REQUEST_HELP' );


// export const PROFILE_FROM_STORAGE: string = 'PROFILE_FROM_STORAGE';

// export const LOAD_USER_DELEGATES_ACTION: ActionStatus = createActionTypes( 'LOAD_USER_DELEGATES' );


// export const LOAD_USER_DELEGATES_REQUEST: string =
// 	'LOAD_USER_DELEGATES_REQUEST';
// export const LOAD_USER_DELEGATES_SUCCESS: string =
// 	'LOAD_USER_DELEGATES_SUCCESS';
// export const LOAD_USER_DELEGATES_FAIL: string = 'LOAD_USER_DELEGATES_FAIL';

// export const DEACTIVATE_USER_DELEGATE_ACTION: ActionStatus = createActionTypes( 'DEACTIVATE_USER_DELEGATE' );

// export const DEACTIVATE_USER_DELEGATE_REQUEST: string =
// 	'DEACTIVATE_USER_DELEGATE_REQUEST';
// export const DEACTIVATE_USER_DELEGATE_SUCCESS: string =
// 	'DEACTIVATE_USER_DELEGATE_SUCCESS';
// export const DEACTIVATE_USER_DELEGATE_FAIL: string =
// 	'DEACTIVATE_USER_DELEGATE_FAIL';