import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import { referralsPropType } from '@constants/propTypes';
import ReferralCardsDisplay from '@components/referrals/lists/ReferralCardsDisplay';
import ReferralSentCardsDisplay from '@components/referrals/lists/ReferralSentCardsDisplay';
import ReferralAcceptedCardsDisplay from '@components/referrals/lists/ReferralAcceptedCardsDisplay';
import useScreenInfo from '@/hooks/app/useScreenInfo';
import ReferralListTabsDropDown from '@/components/referrals/ReferralListTabsDropDown';
import { useReferralService } from '@actions';

function ReferralsListScreen({
	referralsMade,
	accepted,
	waiting,
	referralsOpen,
}) {
	const referralService = useReferralService();

	const handelReferralsList = async () => {
		await referralService.getClinicianReferralsList();
	};
	useEffect(() => {
		handelReferralsList();
		return () => {};
	}, []);

	const [defaultTab, setDefaultTab] = useState('referralsReceivedWaiting');
	const [searchParams] = useSearchParams();
	const [isNarrow] = useScreenInfo(769);
	const tab = searchParams.get('tab');

	const tabKey = {
		pending: 'referralsReceivedWaiting',
		accepted: 'referralsReceivedAccepted',
		sent: 'referralsMade',
		open: 'referralsOpen',
	};

	const tabLabels = {
		referralsReceivedWaiting: 'Referrals Received',
		referralsReceivedAccepted: 'Referrals Accepted',
		referralsMade: 'Referrals Sent',
		referralsOpen: 'Referrals Open',
	};

	useEffect(() => {
		window.scroll(0, 0);
	}, []);
	useEffect(() => {
		if (tab !== null && tabKey[tab] !== null) {
			setDefaultTab(tabKey[tab.toLowerCase()]);
		}
	}, [tab]);

	const handleTabChange = (tab) => {
		setDefaultTab(tab);
	};

	const renderContent = () => {
		switch (defaultTab) {
			case 'referralsReceivedWaiting':
				return <ReferralCardsDisplay referralsList={waiting} />;
			case 'referralsReceivedAccepted':
				return <ReferralAcceptedCardsDisplay referralsList={accepted} />;
			case 'referralsMade':
				return <ReferralSentCardsDisplay referralsList={referralsMade} />;
			case 'referralsOpen':
				return <ReferralCardsDisplay referralsList={referralsOpen} />;
			default:
				return null;
		}
	};

	if (isNarrow) {
		return (
			<div className="patient-list">
				<h6 className="text-left p-1">Select referral group</h6>
				<ReferralListTabsDropDown
					viewName={tabLabels[defaultTab]}
					updateView={handleTabChange}
					viewOptions={[
						{ value: 'referralsReceivedWaiting', label: 'Referrals Received' },
						{ value: 'referralsReceivedAccepted', label: 'Referrals Accepted' },
						{ value: 'referralsMade', label: 'Referrals Sent' },
						{ value: 'referralsOpen', label: 'Referrals Open' },
					]}
				/>
				{renderContent()}
			</div>
		);
	}

	return (
		<div className="referral-list">
			<Tabs
				activeKey={defaultTab}
				onSelect={setDefaultTab}
				id="referrals-tab"
				className="custom-tabs mb-3"
			>
				<Tab
					eventKey="referralsReceivedWaiting"
					title="Referrals Received"
					className="custom-tabs"
				>
					<ReferralCardsDisplay referralsList={waiting} />
				</Tab>
				<Tab
					eventKey="referralsReceivedAccepted"
					title="Referrals Accepted"
					className="custom-tabs"
				>
					<ReferralAcceptedCardsDisplay referralsList={accepted} />
				</Tab>
				<Tab
					eventKey="referralsMade"
					title="Referrals Sent"
					className="custom-tabs"
				>
					<ReferralSentCardsDisplay referralsList={referralsMade} />
				</Tab>
				<Tab
					eventKey="referralsOpen"
					title="Referrals Open"
					className="custom-tabs"
				>
					<ReferralCardsDisplay referralsList={referralsOpen} />
				</Tab>
			</Tabs>
		</div>
	);
}

ReferralsListScreen.propTypes = {
	referralsMade: referralsPropType.isRequired,
	accepted: referralsPropType.isRequired,
	waiting: referralsPropType.isRequired,
	referralsOpen: referralsPropType.isRequired,
};

const mapStateToProps = (state) => ({
	referralsMade: state.referrals.referralsMade,
	accepted: state.referrals.referralsReceived.accepted,
	waiting: state.referrals.referralsReceived.waiting,
	referralsOpen: state.referrals.referralsOpen,
});

export default connect(mapStateToProps, {})(ReferralsListScreen);
