import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAuthService } from '@actions';


interface ProfileFullDeactivatePopupProps {
    userProfileId: number;
    showModal: boolean;
    handleClose: () => void;
};


const ProfileFullDeactivatePopup: React.FC<ProfileFullDeactivatePopupProps> = ( { userProfileId, showModal, handleClose } ) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authService = useAuthService();

    const handleDeactivateAccount = async () => {
        console.log( 'Deactivating account' );
        const success = await authService.deactivateAccount( userProfileId );
        if ( !success ) return;
        authService.goToLandingPage();
        handleClose();
    }

    return (
        <Modal show= { showModal } onHide = { handleClose } >
            <Modal.Header closeButton >
            <Modal.Title className='medr-inv' > MedR terms and conditions </Modal.Title>
                </Modal.Header>
                < Modal.Body className = 'medr-inv' >
                    Are you sure you would like to deactivate your account ?
                        </Modal.Body>
                        < Modal.Footer >
                        <Button variant="danger" onClick = { handleDeactivateAccount } >
                            Deactivate
                            </Button>
                            < Button variant = "info" onClick = { handleClose } >
                                Cancel
                                </Button>
                                </Modal.Footer>
                                </Modal>
      );
  }


export default ProfileFullDeactivatePopup;
