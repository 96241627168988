import React from "react";
import Select from 'react-select';
import { numberPropType } from '@constants/propTypes';
import usePatientSearchBar from '@hooks/patients/usePatientSearchBar';

function PatientSearchBar({ currentPatientId = null, sendPxId = () => {} }){

    const {
        patientsOptions,
        selectPatientByOption,
        selectedPatient,
     } = usePatientSearchBar(currentPatientId, sendPxId);

    const customStyles = {
        singleValue:(provided) => ({
          ...provided,
          color:'#247986',
        }),
      }
    return (
        <Select
            name='patient-search'
            options={patientsOptions}
            value={selectedPatient}
            onChange={opt => selectPatientByOption(opt)}
            placeholder='Select patient'
            styles={customStyles}
        />
    )
}

PatientSearchBar.propTypes = {
    currentPatientId: numberPropType,
};

export default PatientSearchBar;