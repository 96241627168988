import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { patientsPropType } from '@constants/propTypes';
import PatientBar from './PatientBar';
import PaginationBar from '../app/PaginationBar';
import usePatientsList from '@hooks/patients/usePatientsList';
import PatientFilterInput from './PatientFilterInput';
import usePatientFilter from '@hooks/patients/usePatientFilter';
import { useSelector } from 'react-redux';
import Loader from '@components/app/Loader';

function NoPatientsDisplay() {
	return (
		<Card className="mb-2 rounded d-flex flex-fill" style={{ padding: '1rem' }}>
			<Card.Title>No patients to display at the moment </Card.Title>
			<Card.Subtitle className="text-muted">
				If you have used the search bar, please modify your search criteria and
				try again.
			</Card.Subtitle>
		</Card>
	);
}

function PatientCardsDisplay({ patientsList, showSearch = true }) {
	const nPatientsWindow = 25;
	const start = 0;
	const end = nPatientsWindow;
	const isLoading = useSelector((state) => state.patients.loading);

	const [filterStr, setFilterStr] = usePatientFilter();
	const [patients, updatePatientsSlice, patientsLength] = usePatientsList(
		patientsList,
		start,
		end,
		filterStr
	);

	return (
		<>
			{ showSearch && (
				<Row className="text-left p-1">
					<Col className="px-filter-bar-col">
						<PatientFilterInput setFilterString={setFilterStr} />
					</Col>
				</Row>
			)}
			{isLoading && (
				<>
					<div className="gap mt-5" />
					<Loader text="Loading patients..." />
				</>
			)}
			{!isLoading && patientsLength === 0 && <NoPatientsDisplay />}
			{!isLoading &&
				patients.map((patient) => (
					<div className="p-1" key={`pxD${uuidv4()}`}>
						<PatientBar patient={patient} />
					</div>
				))}
			{!isLoading && patientsLength > nPatientsWindow && (
				<>
					<hr className="medr" />
					<PaginationBar
						arrayLength={patientsLength}
						setSliceValues={updatePatientsSlice}
						windowSize={nPatientsWindow}
					/>
				</>
			)}
		</>
	);
}

PatientCardsDisplay.propTypes = {
	patientsList: patientsPropType.isRequired,
};

export default PatientCardsDisplay;
