/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';
import { AdminService } from './services/AdminService';
import { AppVersionService } from './services/AppVersionService';
import { ClinicianInformationService } from './services/ClinicianInformationService';
import { ClinicianListService } from './services/ClinicianListService';
import { ClinicsService } from './services/ClinicsService';
import { ErrorNotificationService } from './services/ErrorNotificationService';
import { GetGpsService } from './services/GetGpsService';
import { GetVerifyDelegateService } from './services/GetVerifyDelegateService';
import { InsuranceAgreementsService } from './services/InsuranceAgreementsService';
import { JobRolesService } from './services/JobRolesService';
import { LoginService } from './services/LoginService';
import { LogoutService } from './services/LogoutService';
import { OperationsService } from './services/OperationsService';
import { PatientService } from './services/PatientService';
import { ReferralsService } from './services/ReferralsService';
import { RegisterService } from './services/RegisterService';
import { RegisterDelegateService } from './services/RegisterDelegateService';
import { SchemaService } from './services/SchemaService';
import { UserService } from './services/UserService';
import { UserCrpInformationService } from './services/UserCrpInformationService';
import { VerifyDelegateService } from './services/VerifyDelegateService';
type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;
export class AppClient {
    public readonly admin: AdminService;
    public readonly appVersion: AppVersionService;
    public readonly clinicianInformation: ClinicianInformationService;
    public readonly clinicianList: ClinicianListService;
    public readonly clinics: ClinicsService;
    public readonly errorNotification: ErrorNotificationService;
    public readonly getGps: GetGpsService;
    public readonly getVerifyDelegate: GetVerifyDelegateService;
    public readonly insuranceAgreements: InsuranceAgreementsService;
    public readonly jobRoles: JobRolesService;
    public readonly login: LoginService;
    public readonly logout: LogoutService;
    public readonly operations: OperationsService;
    public readonly patient: PatientService;
    public readonly referrals: ReferralsService;
    public readonly register: RegisterService;
    public readonly registerDelegate: RegisterDelegateService;
    public readonly schema: SchemaService;
    public readonly user: UserService;
    public readonly userCrpInformation: UserCrpInformationService;
    public readonly verifyDelegate: VerifyDelegateService;
    public readonly request: BaseHttpRequest;
    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '1.0.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });
        this.admin = new AdminService(this.request);
        this.appVersion = new AppVersionService(this.request);
        this.clinicianInformation = new ClinicianInformationService(this.request);
        this.clinicianList = new ClinicianListService(this.request);
        this.clinics = new ClinicsService(this.request);
        this.errorNotification = new ErrorNotificationService(this.request);
        this.getGps = new GetGpsService(this.request);
        this.getVerifyDelegate = new GetVerifyDelegateService(this.request);
        this.insuranceAgreements = new InsuranceAgreementsService(this.request);
        this.jobRoles = new JobRolesService(this.request);
        this.login = new LoginService(this.request);
        this.logout = new LogoutService(this.request);
        this.operations = new OperationsService(this.request);
        this.patient = new PatientService(this.request);
        this.referrals = new ReferralsService(this.request);
        this.register = new RegisterService(this.request);
        this.registerDelegate = new RegisterDelegateService(this.request);
        this.schema = new SchemaService(this.request);
        this.user = new UserService(this.request);
        this.userCrpInformation = new UserCrpInformationService(this.request);
        this.verifyDelegate = new VerifyDelegateService(this.request);
    }
}

