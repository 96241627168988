import { createActionTypes, ActionStatus } from "./requestConstant";

export const CLINICIAN_REFERRALS_ACTION: ActionStatus = createActionTypes( 'CLINICIAN_REFERRALS' );
export const CREATE_REFERRAL_ACTION: ActionStatus = createActionTypes( 'CREATE_REFERRAL' );
export const REFERRAL_TOGGLE_ACCEPT_ACTION: ActionStatus = createActionTypes( 'REFERRAL_TOGGLE_ACCEPT' );
export const REFERRAL_TOGGLE_COMPLETE_ACTION: ActionStatus = createActionTypes( 'REFERRAL_TOGGLE_COMPLETE' );
export const REFERRAL_CANCEL_ACTION: ActionStatus = createActionTypes( 'REFERRAL_CANCEL' );



export const REFERRAL_LIST_ACTION: ActionStatus = createActionTypes( 'REFERRAL_LIST' );

// Referrals
export const REFERRAL_LIST_REQUEST: string = 'REFERRAL_LIST_REQUEST';
export const REFERRAL_LIST_SUCCESS: string = 'REFERRAL_LIST_SUCCESS';
export const REFERRAL_LIST_FAIL: string = 'REFERRAL_LIST_FAIL';

export const REFERRAL_ACTION: ActionStatus = createActionTypes( 'REFERRAL' );

export const REFERRAL_REQUEST: string = 'REFERRAL_REQUEST';
export const REFERRAL_SUCCESS: string = 'REFERRAL_SUCCESS';
export const REFERRAL_FAIL: string = 'REFERRAL_FAIL';