import { createContext, useContext } from "react";
import { ClinicianRoleProfile, ClinicCenter } from '@client';


type SearchLocation = {
    postcode: string;
    searchLatitude: number;
    searchLongitude: number;
}

interface MapViewContextType {
    clinicians: Array<ClinicianRoleProfile>;
    refreshClinicians: () => void;
    showReferralButton: boolean;
    hoveredClinicianUserProfileIds: Array<number>;
    updateHovered: () => void;
    hoveredAddressIds: Array<number>;
    clinics: Array<ClinicCenter>;
    handleShowFavourites: () => void;
    onClinicianSelect: () => void;
    updateMapViewCoords: () => void;
    updateShowOnlyFavourites: () => void;
    receivePostcodeSearch: () => void;
    location: SearchLocation;
    }

export const MapViewContext = createContext<MapViewContextType | undefined>(undefined); 

export function useMapViewContext() {
  const context = useContext(MapViewContext);

  if (!context) {
    throw new Error("useMapViewContext must be used within a MapViewProvider");
  }

  return context;
}