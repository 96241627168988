import React from 'react';
import UserUpdateRequest from '../UpdateUserDetails/UserUpdateRequest';
import ReferralsContactsDisplay from '../ReferralContacts/ReferralContactsDisplay';
import useReferralContacts from '@hooks/clinicians/useReferralContacts';
import { useSelector } from 'react-redux';

function ProfileInfoUpdate() {
	const { referralContacts, requiredEmails, updateAppointmentContacts } =
		useReferralContacts();

	const isDelegate = useSelector((state) => state.profile.isDelegate);

	return (
		<>
			<UserUpdateRequest />
			{!isDelegate ? (
				<ReferralsContactsDisplay
					contacts={referralContacts}
					updateAppointmentContacts={updateAppointmentContacts}
					requiredEmails={requiredEmails}
				/>
			) : (
				<div className="medr-rounded bg-medr-gray bluegreen mt-4">
					<h4 className="bluegreen text-center">Referral Contacts</h4>
					<p>Only the clinician can update Referral contacts</p>
				</div>
			)}
		</>
	);
}

export default ProfileInfoUpdate;
