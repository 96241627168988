import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useUserService } from '@actions';

const useReferralNotifications = (isAuthenticated) => {
	const userService = useUserService();

	const referralNotifications = useSelector(
		(state) => state.profile.referralNotifications
	);
	const [nReferrals, setNReferrals] = useState(null);

	const updateNotifications = () => {
		if (!referralNotifications || !referralNotifications.nReferrals) {
			setNReferrals(0);
			return;
		}
		setNReferrals(referralNotifications.nReferrals);
	};
	useEffect(() => {
		if (!isAuthenticated) return;
		async function fetchData() {
			await userService.getReferralNotifications();
		}
		fetchData();
	}, []);
	useEffect(() => updateNotifications(), [referralNotifications]);

	return {
		nReferrals,
	};
};

export default useReferralNotifications;
