import React, { useEffect } from 'react';
import { Row, Col, Button, Card } from 'react-bootstrap';
import { getNameString } from '@/actions/general';
import AppUpdatesPanel from '@components/app/AppUpdatesPanel';
import useUserProfile from '@hooks/user/useUserProfile';
import { useAdminService } from '@actions';

function DashboardStaff() {
	const adminService = useAdminService();
	useEffect(() => {
		const handleGetPlatformStats = async () =>
			await adminService.getPlatformStats();
		handleGetPlatformStats();
	}, []);

	const { userProfile } = useUserProfile();
	const handleNavigationUsersList = () => adminService.goToUserListPage();
	const handleNavigationCliniciansMap = () =>
		adminService.goToCliniciansMapPage();

	const loginRows =
		adminService.statsData.last_logged_in &&
		Object.entries(adminService.statsData.last_logged_in).map(
			([group, times]) =>
				Object.entries(times).map(([period, nn]) => (
					<li className="text-muted" key={`${group}-${period}`}>
						<div className="row">
							<div
								// className="col"
								style={{ width: '30px' }}
							>
								{nn}
							</div>
							<div
								// className="col"
								style={{ width: '100px' }}
							>
								{group}
							</div>
							<div>in the last {period} days.</div>
						</div>
					</li>
				))
		);
	const rxRows =
		adminService.statsData.last_logged_in &&
		Object.entries(adminService.statsData.referrals).map(([rxType, times]) =>
			Object.entries(times).map(([period, nn]) => (
				<li className="text-muted" key={`${rxType}-${period}`}>
					<div className="row">
						<div
							// className="col"
							style={{ width: '40px' }}
						>
							{nn}
						</div>
						<div
							// className="col"
							style={{ width: '100px' }}
						>
							{rxType}
						</div>
						<div>referrals in the last {period} days.</div>
					</div>
				</li>
			))
		);

	function statsBoxDisplay(title, list) {
		return (
			<Card className="my-3 p-3 rounded d-flex flex-fill">
				<Card.Title>{title}</Card.Title>
				<Card.Body>
					<ul style={{ listStyle: 'none', textAlign: 'left' }}>{list}</ul>
				</Card.Body>
			</Card>
		);
	}

	return (
		<>
			<Row className="medr-layout text-center">
				<Col>
					<h4>Welcome {userProfile && getNameString(userProfile)}</h4>
				</Col>
			</Row>
			<Row className="medr-layout text-center">
				<Col>
					<Button
						type="button"
						onClick={handleNavigationUsersList}
						className="sel-button w100"
					>
						Verify Users
					</Button>
				</Col>
				<Col>
					<Button
						type="button"
						onClick={handleNavigationCliniciansMap}
						className="sel-button w100"
					>
						See clinicians
					</Button>
				</Col>
			</Row>

			<Row className="medr-layout text-center">
				<Col xs={12} md={9}>
					<p className="medr-text">MedR administration account</p>
					<div className="d-flex justify-content-start flex-wrap">
						{statsBoxDisplay('Login statistics', loginRows)}
						{statsBoxDisplay('Referral statistics', rxRows)}
					</div>
				</Col>
				<Col xs={12} md={3}>
					<AppUpdatesPanel />
				</Col>
			</Row>
		</>
	);
}

export default DashboardStaff;
