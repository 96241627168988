import { useEffect } from 'react';
import { Dispatch } from 'redux';
import { NavigateFunction } from 'react-router-dom';
import { getAnyApiClient, getAuthApiClient } from './apiActions';
import * as CLINCONSTS from '@/constants/clinicianConstants';
import { checkIsAuthenticated, redirectIfInvalidToken } from './authService';
import { InviteClinicianResponse, InviteClinicianRequest, FavouriteClinicianDeleteResponse, FavouriteClinicianCreateResponse, ClinicianInsuranceUpdateResponse, ClinicianInsuranceUpdateRequest, ClinicianProfileDeactivateResponse, RemoveClinicianProfileRequest, RemoveClinicianProfileResponse, ClinicianProfileCreateRequest, ClinicianProfileCreateResponse, ListCliniciansRequest, ListCliniciansResponse, ListClinicsResponse, ClinicianInfoByIdResponse, ClinicCenter, ClinicianProfileUpdateResponse } from '@client';

type ApiClient = ReturnType<typeof getAnyApiClient>;
type ApiAuthClient = ReturnType<typeof getAuthApiClient>;


export class ClinicianService {
    private dispatch: Dispatch;
    private navigate: NavigateFunction;
    private isAuthenticated: boolean;
    private appAnyClient: ApiClient;
    private appAuthClient: ApiAuthClient;

    constructor ( dispatch: Dispatch, navigate: NavigateFunction ) {
        this.dispatch = dispatch;
        this.navigate = navigate;
        this.isAuthenticated = checkIsAuthenticated();
        this.appAnyClient = getAnyApiClient();
        this.appAuthClient = getAuthApiClient();
    }

    async getCliniciansList( inputData: ListCliniciansRequest ): Promise<boolean> {
        this.dispatch( { type: CLINCONSTS.CLINICIAN_LIST_ACTION.REQUEST } );

        let data: ListCliniciansResponse;

        try {
            if ( !this.isAuthenticated ) {
                data = await this.appAnyClient.clinicianList.clinicianListCreate( inputData );
            } else {
                data = await this.appAuthClient.clinicianList.clinicianListCreate( inputData );
            }
            this.dispatch( { type: CLINCONSTS.CLINICIAN_LIST_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: CLINCONSTS.CLINICIAN_LIST_ACTION.FAIL, payload: 'Not authenticated' } );
            return false
        }
    }

    async getClinicsList(): Promise<boolean> {
        this.dispatch( { type: CLINCONSTS.CLINICS_LIST_ACTION.REQUEST } );

        try {
            const data: ListClinicsResponse = await this.appAnyClient.clinics.clinicsRetrieve();
            this.dispatch( { type: CLINCONSTS.CLINICS_LIST_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            this.dispatch( { type: CLINCONSTS.CLINICS_LIST_ACTION.FAIL } );
            return false
        }
    }

    async getClinicianById( crpId: number ): Promise<boolean> {
        this.dispatch( { type: CLINCONSTS.CLINICIAN_BY_ID_ACTION.REQUEST } );

        let data: ClinicianInfoByIdResponse;
        try {
            if ( !this.isAuthenticated ) {
                data = await this.appAnyClient.clinicianInformation.clinicianInformationRetrieve( crpId );
            } else {
                data = await this.appAuthClient.clinicianInformation.clinicianInformationRetrieve( crpId );
            }
            this.dispatch( { type: CLINCONSTS.CLINICIAN_BY_ID_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: CLINCONSTS.CLINICIAN_BY_ID_ACTION.FAIL } );
            return false;
        }
    }

    async getClinicianDataById( crpId: number ): Promise<ClinicianInfoByIdResponse> {
        this.dispatch( { type: CLINCONSTS.CLINICIAN_BY_ID_ACTION.REQUEST } );

        let data: ClinicianInfoByIdResponse;
        try {
            if ( !this.isAuthenticated ) {
                data = await this.appAnyClient.clinicianInformation.clinicianInformationRetrieve( crpId );
            } else {
                data = await this.appAuthClient.clinicianInformation.clinicianInformationRetrieve( crpId );
            }
            this.dispatch( { type: CLINCONSTS.CLINICIAN_BY_ID_ACTION.SUCCESS, payload: data } );
            return data;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: CLINCONSTS.CLINICIAN_BY_ID_ACTION.FAIL } );
            return null;
        }
    }

    async getClinicianByUserId( userId: number ): Promise<boolean> {
        this.dispatch( { type: CLINCONSTS.USER_CRPS_GET_ACTION.REQUEST } );

        let data: ClinicianInfoByIdResponse;
        try {
            if ( !this.isAuthenticated ) {
                data = await this.appAnyClient.userCrpInformation.userCrpInformationRetrieve( userId );
            } else {
                data = await this.appAuthClient.userCrpInformation.userCrpInformationRetrieve( userId );
            }
            this.dispatch( { type: CLINCONSTS.USER_CRPS_GET_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: CLINCONSTS.USER_CRPS_GET_ACTION.FAIL } );
            return false;
        }
    }

    async postCreateCrp( inputData: ClinicianProfileCreateRequest ): Promise<boolean> {
        this.dispatch( { type: CLINCONSTS.CLINICIAN_CREATE_ACTION.REQUEST } );

        try {
            const data: ClinicianProfileCreateResponse = await this.appAuthClient.user.userClinicianInformationCreateCreate( inputData );
            this.dispatch( { type: CLINCONSTS.CLINICIAN_CREATE_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: CLINCONSTS.CLINICIAN_CREATE_ACTION.FAIL } );
            return false;
        }
    }

    async postRequestRemoveCrp( inputData: RemoveClinicianProfileRequest ): Promise<boolean> {
        this.dispatch( { type: CLINCONSTS.CLINICIAN_ROLE_REMOVE_REQ_ACTION.REQUEST } );

        try {
            const data: RemoveClinicianProfileResponse = await this.appAuthClient.user.userClinicianInformationRemoveClinicCreate( inputData );
            this.dispatch( { type: CLINCONSTS.CLINICIAN_ROLE_REMOVE_REQ_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: CLINCONSTS.CLINICIAN_ROLE_REMOVE_REQ_ACTION.FAIL } );
            return false;
        }
    }

    async editCrp( clinic: ClinicCenter ): Promise<boolean> {
        this.dispatch( { type: CLINCONSTS.CLINICIAN_UPDATE_ACTION.REQUEST } );

        const payload = {
            clinicName: clinic.clinicName,
            centreName: clinic.centreName,
            addressType: clinic.address.addressType,
            firstLine: clinic.address.firstLine,
            city: clinic.address.city,
            postcode: clinic.address.postcode,
        }

        try {
            const data: ClinicianProfileUpdateResponse = await this.appAuthClient.user.userClinicianInformationUpdateUpdate( clinic.id, payload );
            this.dispatch( { type: CLINCONSTS.CLINICIAN_UPDATE_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: CLINCONSTS.CLINICIAN_UPDATE_ACTION.FAIL } );
            return false;
        }
    }

    async postDeactivateCrp( id: number ): Promise<boolean> {
        this.dispatch( { type: CLINCONSTS.CLINICIAN_ROLE_DEACTIVATE_ACTION.REQUEST } );

        try {
            const data: ClinicianProfileDeactivateResponse = await this.appAuthClient.user.userClinicianInformationDeactivateUpdate( id );
            this.dispatch( { type: CLINCONSTS.CLINICIAN_ROLE_DEACTIVATE_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: CLINCONSTS.CLINICIAN_ROLE_DEACTIVATE_ACTION.FAIL } );
            return false;
        }
    }

    async updateClinicianInsuranceAgreements( inputData: ClinicianInsuranceUpdateRequest ): Promise<boolean> {
        this.dispatch( { type: CLINCONSTS.CLINICIAN_INSURANCE_UPDATE_ACTION.REQUEST } );

        try {
            const data: ClinicianInsuranceUpdateResponse = await this.appAuthClient.user.userClinicianInformationUpdateInsuranceAgreementsUpdate( inputData );
            this.dispatch( { type: CLINCONSTS.CLINICIAN_INSURANCE_UPDATE_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: CLINCONSTS.CLINICIAN_INSURANCE_UPDATE_ACTION.FAIL } );
            return false;
        }
    }

    async addClinicianToFavourites( id: number ): Promise<boolean> {
        this.dispatch( { type: CLINCONSTS.CLINICIAN_ADD_FAVOURITE_ACTION.REQUEST } );

        try {
            const data: FavouriteClinicianCreateResponse = await this.appAuthClient.user.userFavouriteClinicianAddCreate( id );
            this.dispatch( { type: CLINCONSTS.CLINICIAN_ADD_FAVOURITE_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: CLINCONSTS.CLINICIAN_ADD_FAVOURITE_ACTION.FAIL } );
            return false;
        }
    }

    async removeClinicianFromFavourites( id: number ): Promise<boolean> {
        this.dispatch( { type: CLINCONSTS.CLINICIAN_RM_FAVOURITE_ACTION.REQUEST } );

        try {
            const data: FavouriteClinicianDeleteResponse = await this.appAuthClient.user.userFavouriteClinicianRemoveDestroy( id );
            this.dispatch( { type: CLINCONSTS.CLINICIAN_RM_FAVOURITE_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: CLINCONSTS.CLINICIAN_RM_FAVOURITE_ACTION.FAIL } );
            return false;
        }
    }

    async inviteClinicianToMedr( inputData: InviteClinicianRequest ): Promise<boolean> {
        this.dispatch( { type: CLINCONSTS.INVITE_CLINICIAN_ACTION.REQUEST } );

        try {
            const data: InviteClinicianResponse = await this.appAuthClient.user.userInviteClinicianCreate( inputData );
            this.dispatch( { type: CLINCONSTS.INVITE_CLINICIAN_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: CLINCONSTS.INVITE_CLINICIAN_ACTION.FAIL } );
            return false;
        }
    }

    goToReferToClinician( clinicianId: number ): void {
        this.navigate( `/referral/direct-to-clinician/${ clinicianId }/add` );
    }
}