/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PatientByIdResponse } from '../models/PatientByIdResponse';
import type { PatientFormCreateRequest } from '../models/PatientFormCreateRequest';
import type { PatientFormCreateResponse } from '../models/PatientFormCreateResponse';
import type { PatientsListForUserResponse } from '../models/PatientsListForUserResponse';
import type { PatientUpdateByIdResponse } from '../models/PatientUpdateByIdResponse';
import type { ReferralsListForPatientResponse } from '../models/ReferralsListForPatientResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PatientService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Returns patient information using uid
     * @param pxId
     * @returns PatientByIdResponse
     * @throws ApiError
     */
    public patientRetrieve(
        pxId: number,
    ): CancelablePromise<PatientByIdResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/patient/{px_id}/',
            path: {
                'px_id': pxId,
            },
        });
    }
    /**
     * Creates a new patient using form data to populate fields. A patient details image is not required
     * @param formData
     * @returns PatientFormCreateResponse
     * @throws ApiError
     */
    public patientCreateCreate(
        formData: PatientFormCreateRequest,
    ): CancelablePromise<PatientFormCreateResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/patient/create/',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * Deletes patient using uid
     * @param pxId
     * @returns PatientsListForUserResponse
     * @throws ApiError
     */
    public patientDeleteDestroy(
        pxId: number,
    ): CancelablePromise<PatientsListForUserResponse> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/patient/delete/{px_id}/',
            path: {
                'px_id': pxId,
            },
        });
    }
    /**
     * Returns patients linked to the logged in user.
     * @returns PatientsListForUserResponse
     * @throws ApiError
     */
    public patientListRetrieve(): CancelablePromise<PatientsListForUserResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/patient/list/',
        });
    }
    /**
     * Returns referrals linked to patient using the patients uid.
     * @param pxId
     * @returns ReferralsListForPatientResponse
     * @throws ApiError
     */
    public patientReferralsRetrieve(
        pxId: number,
    ): CancelablePromise<ReferralsListForPatientResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/patient/referrals/{px_id}/',
            path: {
                'px_id': pxId,
            },
        });
    }
    /**
     * Updates patient information using uid.
     * @param pxId
     * @param formData
     * @returns PatientUpdateByIdResponse
     * @throws ApiError
     */
    public patientUpdateUpdate(
        pxId: number,
        formData: PatientFormCreateRequest,
    ): CancelablePromise<PatientUpdateByIdResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/patient/update/{px_id}/',
            path: {
                'px_id': pxId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
}
