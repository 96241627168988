import * as CONSTS from '@constants/patientConstants';
import * as INIT from '@constants/initDataConstants';
import { LOGOUT_ACTION } from '@constants/authConstants';

const initialState = {
	loading: false,
	currentPatient: INIT.initPatient,
	currentPatientReferrals: {
		activeReferrals: [],
		inactiveReferrals: [],
	},
	addedPatients: [],
	referredPatients: [],
	pendingPatients: [],
	acceptedPatients: [],
	completedPatients: [],
};

// eslint-disable-next-line default-param-last
export default function patients(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case CONSTS.CURRENT_PATIENT_ACTION.REQUEST:
		case CONSTS.CREATE_PATIENT_ACTION.REQUEST:
		case CONSTS.CLINICIAN_PATIENTS_ACTION.REQUEST:
		case CONSTS.PATIENT_REFERRALS_ACTION.REQUEST:
		case CONSTS.PATIENT_DELETE_ACTION.REQUEST:
		case CONSTS.GPS_COORDS_ACTION.REQUEST:
			return {
				...state,
				loading: true,
			};
		case CONSTS.CLINICIAN_PATIENTS_ACTION.SUCCESS:
		case CONSTS.PATIENT_DELETE_ACTION.SUCCESS:
		case CONSTS.CURRENT_PATIENT_ACTION.SUCCESS:
		case CONSTS.CREATE_PATIENT_ACTION.SUCCESS:
		case CONSTS.PATIENT_REFERRALS_ACTION.SUCCESS:
			return {
				...state,
				...payload,
				loading: false,
			};

		case CONSTS.GPS_COORDS_ACTION.SUCCESS:
			return {
				...state,
				loading: false,
				currentPatient: payload,
			};
		case CONSTS.GPS_COORDS_ACTION.FAIL:
			return {
				...state,
				loading: false,
				current_patient: INIT.initPatient,
			};
		case CONSTS.CURRENT_PATIENT_ACTION.FAIL:
		case CONSTS.CURRENT_PATIENT_ACTION.RESET:
			return {
				...state,
				loading: false,
				currentPatient: INIT.initPatient,
			};
		case CONSTS.CLINICIAN_PATIENTS_ACTION.FAIL:
		case CONSTS.PATIENT_REFERRALS_ACTION.FAIL:
		case CONSTS.CREATE_PATIENT_ACTION.FAIL:
		case CONSTS.PATIENT_DELETE_ACTION.FAIL:
			return {
				...state,
				error: payload,
				loading: false,
			};
		case LOGOUT_ACTION.SUCCESS:
			return { ...initialState };
		default:
			return state;
	}
}
