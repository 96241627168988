import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { MESSAGE_RESET } from '@/constants/messageConstants';

interface MessageProps {
  variant?: 'success' | 'info' | 'danger' | null;
  message?: string | null;
}

interface RootState {
  message: {
    variant: MessageProps[ 'variant' ];
    message: MessageProps[ 'message' ];
  };
}

const Message: React.FC<MessageProps> = ( { variant = null, message = null } ) => {
  const dispatch = useDispatch();
  const [ showModal, setModal ] = useState( false );

  const checkValidVariant = (): boolean => {
    return variant === "success" || variant === "info" || variant === "danger";
  }

  const checkValidMessage = (): boolean => {
    return message !== null && message !== undefined && variant !== null && variant !== undefined;
  }

  useEffect( () => {
    setModal( checkValidVariant() && checkValidMessage() );
  }, [ variant, message ] );

  const handleClose = () => {
    dispatch( { type: MESSAGE_RESET } );
    setModal( false );
  }

  return (
    <Modal show= { showModal } onHide = { handleClose } style = {{ paddingBottom: '10px' }
}>
  <Modal.Header>
  <Modal.Title className='medr-inv' id = {`msg${ variant }`}> { message } </Modal.Title>
    </Modal.Header>
    < Modal.Footer >
    <Button variant="info" onClick = { handleClose } >
      Hide
      </Button>
      </Modal.Footer>
      </Modal>
  );
}

const mapStateToProps = ( state: RootState ): MessageProps => ( {
  variant: state.message.variant,
  message: state.message.message,
} );

export default connect( mapStateToProps )( Message );
