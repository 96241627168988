import { createActionTypes, ActionStatus } from "./requestConstant";

export const DEFAULT_LAT: number = 51.501162;
export const DEFAULT_LNG: number = -0.141745;

export const SET_PATIENT_ACTION: ActionStatus = createActionTypes( 'SET_PATIENT' );
export const SET_PATIENT_NULL_ACTION: ActionStatus = createActionTypes( 'SET_PATIENT_NULL' );
export const SET_CRP_ACTION: ActionStatus = createActionTypes( 'SET_CRP' );
export const SET_CRP_NULL_ACTION: ActionStatus = createActionTypes( 'SET_CRP_NULL' );
export const SET_JOB_ROLE_NULL_ACTION: ActionStatus = createActionTypes( 'SET_JOB_ROLE_NULL' );
export const SET_POSTCODE_ACTION: ActionStatus = createActionTypes( 'SET_POSTCODE' );
export const SET_GPS_ACTION: ActionStatus = createActionTypes( 'SET_GPS' );
export const SET_CLINICIAN_ACTION: ActionStatus = createActionTypes( 'SET_CLINICIAN' );
export const SET_CLINICIAN_NULL_ACTION: ActionStatus = createActionTypes( 'SET_CLINICIAN_NULL' );


// export const JOB_ROLE_SET_ID_ACTION: ActionStatus = createActionTypes( 'JOB_ROLE_SET_ID' );


// export const JOB_ROLE_SET_ID_REQUEST: string = 'JOB_ROLE_SET_ID_REQUEST';
// export const JOB_ROLE_SET_ID_SUCCESS: string = 'JOB_ROLE_SET_ID_SUCCESS';
// export const JOB_ROLE_SET_ID_FAIL: string = 'JOB_ROLE_SET_ID_FAIL';

// export const SET_JOB_ROLE_ACTION: ActionStatus = createActionTypes( 'SET_JOB_ROLE' );


// export const SET_JOB_ROLE_REQUEST: string = 'SET_JOB_ROLE_REQUEST';
// export const SET_JOB_ROLE_SUCCESS: string = 'SET_JOB_ROLE_SUCCESS';
// export const SET_JOB_ROLE_FAIL: string = 'SET_JOB_ROLE_FAIL';