import React, { useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import InsuranceSelectorGrid from '@components/user/InsuranceSelectorGrid/InsuranceSelectorGrid';
import InsuranceProviderForm from '../../clinicians/InsuranceProviderDisplay/InsuranceProviderForm';
import { useRegistrationContext } from '@contexts/RegistrationContext';
// import { InsuranceAgreement } from '@/client';

// interface RegistrationContextType {
// 	registrationData: {
// 		existingProviders: InsuranceAgreement[];
// 	};
// 	updateNewProviders: ( providers: any ) => void;
// 	updateExisitingProviders: ( providers: any ) => void;
// 	setNextStep: () => void;
// }

// const RegisterInsuranceDetails: React.FC = (): JSX.Element => {
function RegisterInsuranceDetails() {
	useEffect(() => {
		window.scroll(0, 0);
	}, []);

	const {
		registrationData,
		updateNewProviders,
		updateExisitingProviders,
		setNextStep,
	} = useRegistrationContext(); //  as RegistrationContextType;

	return (
		<div className="insurance-page">
			<Row className="my-3">
				<Col className="text-center">
					<h4> Add accepted insurances </h4>
				</Col>
			</Row>

			<InsuranceSelectorGrid
				currentProviders={registrationData.existingProviders}
				updateCurrentProviders={updateExisitingProviders}
			/>
			<InsuranceProviderForm updateNewProviders={updateNewProviders} />
			<Row className="my-3">
				<Col className="text-center">
					<Button
						className="sel-button w80"
						variant="primary"
						onClick={setNextStep}
					>
						Next
					</Button>
				</Col>
			</Row>
		</div>
	);
}

export default RegisterInsuranceDetails;
