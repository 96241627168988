import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { notificationSettingsProptype } from '@constants/propTypes';
import { useUserService } from '@actions';
import useScreenInfo from '@/hooks/app/useScreenInfo';

function NotificaitonToggleBar({
	isNarrow,
	field,
	label,
	value = false,
	onChange,
}) {
	const [checked, setChecked] = useState(value);
	const sendNewValue = (newValue) => onChange({ field, value: newValue });

	const textSize = isNarrow ? '0.7rem' : '1em';
	useEffect(() => {
		setChecked(value);
	}, [value]);

	let textBoxWidth = '100%';

	if (window.innerWidth < 768) {
		textBoxWidth = '70%';
	}
	if (window.innerWidth < 450) {
		textBoxWidth = '60%';
	}
	if (window.innerWidth < 385) {
		textBoxWidth = '50%';
	}

	return (
		<div className="medr-rounded-inv notif-setting-bar">
			<div className="notif-setting-left">
				<Button
					variant={checked ? 'success' : 'outline-success'}
					className="medr-text notif-button"
					onClick={() => sendNewValue(true)}
				>
					Enabled
				</Button>
				<Button
					variant={!checked ? 'danger' : 'outline-danger'}
					className="medr-text notif-button"
					onClick={() => sendNewValue(false)}
				>
					Disabled
				</Button>
			</div>
			<div
				className="notif-setting-right medr-text"
				style={{
					fontSize: textSize,
					overflowWrap: 'break-word',
					wordBreak: 'break-word',
					hyphens: 'auto',
					maxWidth: textBoxWidth,
				}}
			>
				{label}
			</div>
		</div>
	);
}

NotificaitonToggleBar.propTypes = {
	field: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	value: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
};

function ProfileChangeNotifications({ notificationSettings }) {
	const userService = useUserService();
	const [isNarrow] = useScreenInfo(768);

	const handleLoadUser = async () => await userService.loadUserProfile();

	useEffect(() => {
		handleLoadUser();
		return () => {};
	}, []);

	if (!notificationSettings) {
		return null;
	}

	const {
		id,
		userProfile,
		userCreatedReferral,
		userReceivedReferral,
		targetHasToggledReferral,
		userHasToggledReferral,
		newOpenReferral,
	} = notificationSettings;

	const handleUpdateNotificationSettings = async (newSettings) =>
		await userService.updateUserNotificationsSettings({
			userProfileId: userProfile,
			...newSettings,
		});

	const onChange = (newSettings) => {
		handleUpdateNotificationSettings(newSettings);
	};

	return (
		<>
			<h6 className="text-left">Receive notifications when:</h6>
			<NotificaitonToggleBar
				field="userCreatedReferral"
				isNarrow={isNarrow}
				notificaitonId={id}
				label="Referral sent"
				value={userCreatedReferral}
				onChange={onChange}
			/>
			<NotificaitonToggleBar
				field="userReceivedReferral"
				isNarrow={isNarrow}
				notificaitonId={id}
				label="Referral received"
				value={userReceivedReferral}
				onChange={onChange}
			/>
			<NotificaitonToggleBar
				field="targetHasToggledReferral"
				isNarrow={isNarrow}
				notificaitonId={id}
				label="Clinician accepted / rejected referral"
				value={targetHasToggledReferral}
				onChange={onChange}
			/>
			<NotificaitonToggleBar
				field="userHasToggledReferral"
				isNarrow={isNarrow}
				notificaitonId={id}
				label="Accept/reject a referral"
				value={userHasToggledReferral}
				onChange={onChange}
			/>
			<NotificaitonToggleBar
				field="newOpenReferral"
				isNarrow={isNarrow}
				notificaitonId={id}
				label="A new open referral is available"
				value={newOpenReferral}
				onChange={onChange}
			/>
		</>
	);
}

ProfileChangeNotifications.propTypes = {
	notificationSettings: notificationSettingsProptype.isRequired,
};

const mapStateToProps = (state) => ({
	notificationSettings: state.profile.notificationSettings,
});

export default connect(mapStateToProps, {})(ProfileChangeNotifications);
