import { useEffect } from 'react';
import { Dispatch } from 'redux';
import { useSelector } from 'react-redux';
import { NavigateFunction } from 'react-router-dom';
import { getAuthApiClient } from '@/actions/apiActions';
import { redirectIfInvalidToken } from './authService';
import { Patient, PatientsListForUserResponse, PatientFormCreateResponse, PatientByIdResponse, PatientFormCreateRequest, PatientUpdateByIdResponse, ReferralsListForPatientResponse } from '@client';
import * as CONSTS from '@/constants/patientConstants';

type ApiClient = ReturnType<typeof getAuthApiClient>;

type PatientRequestReturnedType = {
    success: boolean,
    patient: Patient,
}


export class PatientService {
    private dispatch: Dispatch;
    private navigate: NavigateFunction;
    private appAuthClient: ApiClient;
    public currentPatient: Patient;


    constructor ( dispatch: Dispatch, navigate: NavigateFunction ) {
        this.dispatch = dispatch;
        this.navigate = navigate;
        this.appAuthClient = getAuthApiClient();
        this.currentPatient = useSelector( state => state.patients.currentPatient );
    }

    async getClinicianPatientList(): Promise<boolean> {
        this.dispatch( { type: CONSTS.CLINICIAN_PATIENTS_ACTION.REQUEST } );

        try {
            const data: PatientsListForUserResponse = await this.appAuthClient.patient.patientListRetrieve();
            this.dispatch( { type: CONSTS.CLINICIAN_PATIENTS_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: CONSTS.CLINICIAN_PATIENTS_ACTION.FAIL, payload: error } );
            return false;
        }
    }

    async getPxDataById( pxId: number ): Promise<PatientByIdResponse> {
        const validPxId = this.checkValidPatientId( pxId );
        if ( !validPxId ) return null;

        this.dispatch( { type: CONSTS.CURRENT_PATIENT_ACTION.REQUEST } );
        try {
            const data: PatientByIdResponse = await this.appAuthClient.patient.patientRetrieve( pxId );
            this.dispatch( { type: CONSTS.CURRENT_PATIENT_ACTION.SUCCESS, payload: data } );
            return data;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: CONSTS.CURRENT_PATIENT_ACTION.FAIL, payload: error } );
            return null;
        }
    }

    async getPatientById( pxId: number ): Promise<boolean> {
        const validPxId = this.checkValidPatientId( pxId );
        if ( !validPxId ) return null;
        this.dispatch( { type: CONSTS.CURRENT_PATIENT_ACTION.REQUEST } );

        try {
            const data: PatientByIdResponse = await this.appAuthClient.patient.patientRetrieve( pxId );
            this.dispatch( { type: CONSTS.CURRENT_PATIENT_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: CONSTS.CURRENT_PATIENT_ACTION.FAIL, payload: error } );
            return false;
        }
    }

    async getPatientReferralsById( pxId: number ): Promise<boolean> {
        const validPxId = this.checkValidPatientId( pxId );
        this.dispatch( { type: CONSTS.PATIENT_REFERRALS_ACTION.REQUEST } );

        try {
            const data: ReferralsListForPatientResponse = await this.appAuthClient.patient.patientReferralsRetrieve( pxId );
            this.dispatch( { type: CONSTS.PATIENT_REFERRALS_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: CONSTS.PATIENT_REFERRALS_ACTION.FAIL, payload: error } );
            return false;
        }
    }

    async postCreatePatient( inputData: FormData ): Promise<PatientRequestReturnedType> {
        this.dispatch( { type: CONSTS.CREATE_PATIENT_ACTION.REQUEST } );
        let formData = {}
        for ( const pair of inputData.entries() ) {
            formData = { ...formData, [ pair[ 0 ] ]: pair[ 1 ] };
        }

        try {
            const data: PatientFormCreateResponse = await this.appAuthClient.patient.patientCreateCreate( formData );
            this.dispatch( { type: CONSTS.CREATE_PATIENT_ACTION.SUCCESS, payload: data } );
            return { success: true, patient: data.currentPatient };
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: CONSTS.CREATE_PATIENT_ACTION.FAIL, payload: error } );
            return { success: false, patient: null };
        }
    }

    async updatePatientById( pxId: number, inputData: PatientFormCreateRequest ): Promise<PatientRequestReturnedType> {
        const validPxId = this.checkValidPatientId( pxId );
        this.dispatch( { type: CONSTS.PATIENT_UPDATE_ACTION.REQUEST } );

        let formData = {}
        for ( const pair of inputData.entries() ) {
            formData = { ...formData, [ pair[ 0 ] ]: pair[ 1 ] };
        }

        try {
            const data: PatientUpdateByIdResponse = await this.appAuthClient.patient.patientUpdateUpdate( pxId, formData );
            this.dispatch( { type: CONSTS.PATIENT_UPDATE_ACTION.SUCCESS, payload: data } );
            return { success: true, patient: data.patient };
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: CONSTS.PATIENT_UPDATE_ACTION.FAIL, payload: error } );
            return { success: false, patient: null };
        }
    }

    async deletePatientById( pxId: number ): Promise<boolean> {
        const validPxId = this.checkValidPatientId( pxId );
        this.dispatch( { type: CONSTS.PATIENT_DELETE_ACTION.REQUEST } );

        try {
            const data: PatientsListForUserResponse = await this.appAuthClient.patient.patientDeleteDestroy( pxId );
            this.dispatch( { type: CONSTS.PATIENT_DELETE_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: CONSTS.PATIENT_DELETE_ACTION.FAIL, payload: error } );
            return false;
        }
    }

    checkValidPatientId( pxId: number ): boolean {
        if ( !pxId || pxId === undefined || pxId === 0 ) {
            return false;
        }
        return true;
    };

    postCreatePatientByImage(): void { }

    resetCurrentPatient(): void {
        this.dispatch( { type: CONSTS.CURRENT_PATIENT_ACTION.RESET } );
    }

    goToPatientsList(): void {
        this.navigate( '/patients' );
    }

    goToEditPatient( patientId: number ): void {
        this.navigate( `/patients/${ patientId }/edit` );
    };

    goToReferPatient( patientId: number ): void {
        this.navigate( `/referral/patient-selected/${ patientId }/add` );
    };
}