import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import RegisterPersonalDetailsForm from '@components/user/Registration/RegisterUserDetailsForm';
import RegisterClinicianDetialsForm from '@components/user/Registration/RegisterClinicianDetailsForm';
import RegisterInsuranceDetails from '@components/user/Registration/RegisterInsuranceDetails';
import RegisterReview from '@components/user/Registration/RegisterReview';
import useUserRegistration from '@hooks/user/useUserRegistration';
import { RegistrationContext } from '@contexts/RegistrationContext';

function SignupScreen() {
	const registrationContextValues = useUserRegistration();
	const { activeStep, setPreviousStep } = registrationContextValues;

	return (
		<RegistrationContext.Provider value={registrationContextValues}>
			<div
				className="medr-layout text-center register-page"
				style={{ maxWidth: '90%', marginLeft: 'auto', marginRight: 'auto' }}
			>
				<h1>Registration</h1>
				<Row>
					<Col className="py-0">
						{activeStep === 0 && <RegisterPersonalDetailsForm />}
						{activeStep === 1 && <RegisterClinicianDetialsForm />}
						{activeStep === 2 && <RegisterInsuranceDetails />}
						{activeStep === 3 && <RegisterReview />}
					</Col>
				</Row>
				{activeStep !== 0 && (
					<Row>
						<Col className="text-center">
							<Button
								className="sel-button w80"
								variant="dark"
								onClick={setPreviousStep}
							>
								Previous
							</Button>
						</Col>
					</Row>
				)}
			</div>
		</RegistrationContext.Provider>
	);
}

export default SignupScreen;
