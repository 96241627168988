import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { referralsPropType } from '@constants/propTypes';
import PaginationBar from '../../app/PaginationBar';
import ReferralBar from '../ReferralBar';
import useFilterReferralsList from '@hooks/referrals/useFilterReferralsList';
import { useSelector } from 'react-redux';
import Loader from '@components/app/Loader';

function ReferralCardsDisplay({ referralsList }) {
	const nReferralsWindow = 25;
	const start = 0;
	const end = nReferralsWindow;
	const isLoading = useSelector((state) => state.referrals.loading);

	const [referrals, updateReferralsSlice, referralsLength] =
		useFilterReferralsList(referralsList, start, end);

	const headers = (
		<div className="py-1" style={{ marginLeft: '12px' }}>
			<div className="align-items-center p-0 referral-bar referral-bar-header d-none d-md-flex">
				<div className="flex-fill text-start referral-bar-item lines-max-1 referral-bar-item-1">
					<h4 className="m-0">Patient</h4>
				</div>
				<div className="flex-fill text-left referral-bar-item lines-max-1 referral-bar-item-2">
					<h4 className="m-0">DOB</h4>
				</div>
				<div className="flex-fill text-left referral-bar-item lines-max-1 referral-bar-item-3">
					<h4 className="m-0">Sex</h4>
				</div>
				<div className="flex-fill text-left referral-bar-item lines-max-1 referral-bar-item-4">
					<h4 className="m-0">Clinician</h4>
				</div>
				<div className="flex-fill text-left referral-bar-item lines-max-1 referral-bar-item-5">
					<h4 className="m-0">&nbsp;</h4>
				</div>
			</div>
		</div>
	);

	const noReferralsDisplay = (
		<div className="center-box-parent">
			<div className="center-box-child">
				<h4 className="medr-text-inv">No referrals to display at the moment</h4>
			</div>
		</div>
	);

	return (
		<>
			{isLoading && (
				<>
					<div className="gap mt-5" />
					<Loader text="Loading referrals..." />
				</>
			)}
			{!isLoading && referralsLength === 0 && noReferralsDisplay}
			{!isLoading && referralsLength > 0 && headers}
			{!isLoading &&
				referrals.map((referral) => (
					<div className="p-1" key={uuidv4()}>
						<ReferralBar key={uuidv4()} referral={referral} />
					</div>
				))}
			{!isLoading && referralsLength > nReferralsWindow && (
				<>
					<hr className="medr" />
					<PaginationBar
						arrayLength={referralsLength}
						setSliceValues={updateReferralsSlice}
						windowSize={nReferralsWindow}
					/>
				</>
			)}
		</>
	);
}

ReferralCardsDisplay.propTypes = {
	referralsList: referralsPropType.isRequired,
};

export default ReferralCardsDisplay;
