import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, Row, Col, Modal, Tabs, Tab } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { getNameString } from '@/actions/general';
import FavouriteClinicianStar from '../app/parts/FavouriteStar/FavouriteClinicianStar';
import { clinicianProfilesPropType } from '@constants/propTypes';
import { useUserService } from '@actions';
import Loader from '@components/app/Loader';

function SeeAllCliniciansModal({
	show,
	handleClose,
	favouriteClinicians,
	topReferralsFrom,
	topReferredTo,
}) {
	const favouriteClinsList = (
		<Row className="dashboard-analytics-list-p">
			{favouriteClinicians.map((favClin) => (
				<Col key={`tc${uuidv4()}`} xs={12} md={6}>
					<div className="d-flex align-items-center mb-2">
						<span className="mr-2 font-weight-bold medr-text-inv">
							<FavouriteClinicianStar
								clinician={{
									isFavourite: true,
									userProfile: favClin.favouriteUserProfile,
								}}
							/>
						</span>
						<a
							className="medr-text-inv"
							href={`/clinicians/user/${favClin.favouriteUserProfile.id}`}
						>
							{getNameString(favClin.favouriteUserProfile)}
						</a>
					</div>
				</Col>
			))}
		</Row>
	);

	function getReferralClinicianList(referralClinicians) {
		let rxClin = referralClinicians;

		if (window.innerWidth > 768) {
			const sortedItems = [...rxClin].sort((a, b) => a.rank - b.rank);
			const midpoint = Math.ceil(sortedItems.length / 2);
			const firstHalf = sortedItems.slice(0, midpoint);
			const secondHalf = sortedItems.slice(midpoint);
			const reorderedItems = [];
			for (let i = 0; i < midpoint; i++) {
				if (firstHalf[i]) reorderedItems.push(firstHalf[i]);
				if (secondHalf[i]) reorderedItems.push(secondHalf[i]);
			}
			rxClin = reorderedItems;
		}
		return (
			<Row className="dashboard-analytics-list-p">
				{rxClin.map((clinicianInfo, index) => (
					<Col key={`tc${uuidv4()}`} xs={12} md={6}>
						<div className="d-flex align-items-center mb-2">
							<span className="mr-2 font-weight-bold medr-text-inv">
								{clinicianInfo.rank}.
							</span>
							<a
								className="medr-text-inv"
								href={`/clinicians/user/${clinicianInfo.clinician.id}`}
							>
								{getNameString(clinicianInfo.clinician)} ({clinicianInfo.count})
							</a>
						</div>
					</Col>
				))}
			</Row>
		);
	}

	const referralsReceived = getReferralClinicianList(topReferralsFrom);
	const referralsSent = getReferralClinicianList(topReferredTo);

	return (
		<Modal show={show} onHide={handleClose} size="lg">
			<Modal.Header
				className="clinician-interaction-popup"
				style={{ borderTopLeftRadius: '20px', borderTopRightRadius: '20px' }}
				closeButton
			>
				<Modal.Title className="medr-text-inv">
					Clinician Interaction Analytics
				</Modal.Title>
			</Modal.Header>
			<Modal.Body
				className="clinician-interaction-popup"
				style={{
					borderBottomLeftRadius: '20px',
					borderBottomRightRadius: '20px',
				}}
			>
				<Tabs
					defaultActiveKey="favouriteClinicians"
					id="clinicianInteractionTabs"
					className="mb-4 custom-tabs"
				>
					<Tab
						className="custom-tabs"
						eventKey="favouriteClinicians"
						title="Favourite Clinicians"
					>
						{favouriteClinsList}
					</Tab>
					<Tab
						className="custom-tabs"
						eventKey="topReferralsFrom"
						title="Top Referrals Received"
					>
						{referralsReceived}
					</Tab>
					<Tab
						className="custom-tabs"
						eventKey="topReferredTo"
						title="Top Referrals Sent"
					>
						{referralsSent}
					</Tab>
				</Tabs>
			</Modal.Body>
		</Modal>
	);
}

SeeAllCliniciansModal.propTypes = {
	show: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	favouriteClinicians: clinicianProfilesPropType.isRequired,
	topReferralsFrom: clinicianProfilesPropType.isRequired,
	topReferredTo: clinicianProfilesPropType.isRequired,
};

function UserAnalyticsSummary() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const userService = useUserService();

	const isLoading = useSelector((state) => state.profile.loadingAnalytics);

	const lg = 6;
	const md = 6;
	const sm = 12;

	const analyticsNo = 5;

	const [showSeeAllCliniciansModal, setShowSeeAllCliniciansModal] =
		useState(false);

	const refreshAnalytics = async () =>
		await userService.getUserSummaryAnalytics(analyticsNo);
	useEffect(() => {
		refreshAnalytics();
	}, [dispatch]);

	const userProfile = useSelector((state) => state.profile.userProfile);
	const analytics = useSelector((state) => state.profile.analytics);

	if (!userProfile) {
		return <div id="user_analytics_missing_up">Loading...</div>;
	}

	if (!analytics) {
		return <div id="user_analytics_missing_up">Loading...</div>;
	}

	const {
		number,
		noPatientsMade,
		noReferralsMade,
		noReferralsReceived,
		topReferredTo,
		topReferralsFrom,
	} = analytics;

	const { favouriteClinicians } = analytics;

	function handleValue(value) {
		if (value === undefined || value === null) {
			return 'NaN';
		}
		return value;
	}
	const loaderView = (
		<Loader inverse={false} text="Loading referral stats..." />
	);

	function statsCard() {
		return (
			<Card
				className="my-3 py-3 rounded d-flex flex-fill medr-card"
				id="analyticsStatsCard"
			>
				<Card.Title>Platform Statistics</Card.Title>
				{isLoading && loaderView}
				{!isLoading && (
					<Card.Body className="d-flex flex-column text-left">
						<ul className="dashboard-analytics-list-p">
							<li key={uuidv4()}>
								<span className="dashboard-analytics-sp">Patients Added:</span>
								<span>{handleValue(noPatientsMade)}</span>
							</li>
							<li key={uuidv4()}>
								<span className="dashboard-analytics-sp">Referrals Sent:</span>
								<span>{handleValue(noReferralsMade)}</span>
							</li>
							<li key={uuidv4()}>
								<span className="dashboard-analytics-sp">
									Referrals Received:
								</span>
								<span>{handleValue(noReferralsReceived)}</span>
							</li>
						</ul>
						<div className="mt-auto"> </div>
					</Card.Body>
				)}
			</Card>
		);
	}

	function topReferrersCard(referrers, title, keyId) {
		if (
			referrers === undefined ||
			referrers === null ||
			Object.keys(referrers).length === 0
		) {
			return (
				<Card
					className="my-3 p-3 rounded d-flex flex-fill medr-card"
					id={keyId}
				>
					<Card.Title>{title}</Card.Title>
					{isLoading && loaderView}
					{!isLoading && (
						<Card.Body className="d-flex flex-column text-left">
							<p className="dashoard-ana-text text-center">
								Top referrers data not available
							</p>
						</Card.Body>
					)}
				</Card>
			);
		}
		const referrersLim = 5;

		return (
			<Card className="my-3 p-3 rounded d-flex flex-fill medr-card" id={keyId}>
				<Card.Title>{title}</Card.Title>
				{isLoading && loaderView}
				{!isLoading && (
					<Card.Body className="d-flex flex-column text-left">
						<ol className="dashboard-analytics-list-p">
							{referrers.slice(0, referrersLim).map((clinicianInfo) => (
								<li key={`tc${uuidv4()}`}>
									<div className="row" key={uuidv4()}>
										<div className="col-1">
											<FavouriteClinicianStar
												clinician={{
													isFavourite: clinicianInfo.isFavourite,
													userProfile: clinicianInfo.clinician,
												}}
												requestMade={refreshAnalytics}
											/>
										</div>
										<div className="col clinician-name">
											<a
												className="medr-text-inv"
												href={`/clinicians/user/${clinicianInfo.clinician.id}`}
											>
												{getNameString(clinicianInfo.clinician)} (
												{clinicianInfo.count})
											</a>
										</div>
									</div>
								</li>
							))}
						</ol>
						{referrers.length > referrersLim && (
							<p>
								<button
									type="button"
									onKeyDown={() => setShowSeeAllCliniciansModal(true)}
									onClick={() => setShowSeeAllCliniciansModal(true)}
									className="btn-link see-all-clinicians"
								>
									See all clinicians
								</button>
							</p>
						)}
						<div className="mt-auto" />
					</Card.Body>
				)}
			</Card>
		);
	}

	function getFavouriteClinicinasCard() {
		if (
			favouriteClinicians === undefined ||
			favouriteClinicians === null ||
			favouriteClinicians.length === 0 ||
			Object.keys(favouriteClinicians).length === 0
		) {
			return (
				<Card
					className="my-3 p-3 rounded d-flex flex-fill medr-card"
					id="favouriteCliniciansCard"
				>
					<Card.Title>Favourite Clinicians</Card.Title>
					{isLoading && loaderView}
					{!isLoading && (
						<Card.Body className="d-flex flex-column text-left">
							<p className="text-center">
								Favourite clinicians have not been selected
							</p>
							<div className="mt-auto" />
						</Card.Body>
					)}
				</Card>
			);
		}
		const favCliniciansLim = 3;

		return (
			<Card
				className="my-3 p-3 rounded d-flex flex-fill medr-card"
				id="favouriteCliniciansCard"
			>
				<Card.Title>Favourite Clinicians</Card.Title>
				{isLoading && loaderView}
				{!isLoading && (
					<Card.Body className="d-flex flex-column text-left">
						<ul style={{ listStyleType: 'none', padding: '0px' }}>
							{favouriteClinicians.slice(0, favCliniciansLim).map((favClin) => (
								<li key={uuidv4()}>
									<div className="row" key={uuidv4()}>
										<div className="col-1">
											<FavouriteClinicianStar
												clinician={{
													isFavourite: true,
													userProfile: favClin.favouriteUserProfile,
												}}
												requestMade={refreshAnalytics}
											/>
										</div>
										<div className="col clinician-name">
											<a
												className="medr-text-inv"
												href={`/clinicians/user/${favClin.favouriteUserProfile.id}`}
											>
												{getNameString(favClin.favouriteUserProfile)}
											</a>
										</div>
									</div>
								</li>
							))}
						</ul>
						{favouriteClinicians.length > favCliniciansLim && (
							<p>
								<button
									type="button"
									onKeyDown={() => setShowSeeAllCliniciansModal(true)}
									onClick={() => setShowSeeAllCliniciansModal(true)}
									className="btn-link see-all-clinicians"
								>
									See all clinicians
								</button>
							</p>
						)}
						<div className="mt-auto" />
					</Card.Body>
				)}
			</Card>
		);
	}

	return (
		<div
			className="medr-layout text-center"
			style={{ marginLeft: 'auto', marginRight: 'auto' }}
		>
			<SeeAllCliniciansModal
				show={showSeeAllCliniciansModal}
				handleClose={() => setShowSeeAllCliniciansModal(false)}
				favouriteClinicians={favouriteClinicians}
				topReferralsFrom={topReferralsFrom}
				topReferredTo={topReferredTo}
			/>
			<Row>
				<Col className="medr-card-col" lg={lg} md={md} sm={sm}>
					{statsCard()}
				</Col>
				<Col className="medr-card-col" lg={lg} md={md} sm={sm}>
					{getFavouriteClinicinasCard()}
				</Col>
			</Row>
			<Row>
				<Col className="medr-card-col" lg={lg} md={md} sm={sm}>
					{topReferrersCard(
						topReferralsFrom,
						'Top Referrals Received',
						'topReferrersFromCard'
					)}
				</Col>
				<Col className="medr-card-col" lg={lg} md={md} sm={sm}>
					{topReferrersCard(
						topReferredTo,
						'Top Referrals Sent',
						'topReferrersToCard'
					)}
				</Col>
			</Row>
		</div>
	);
}

export default UserAnalyticsSummary;
