import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { AdminService } from './adminService';
import { AuthService } from './authService';
import { ClinicianService } from './clinicianService';
import { DelegateService } from "./delegateService";
import { JobRoleService } from "./jobRoleService";
import { InsuranceAgreementService } from "./insuranceAgreementService";
import { PatientService } from "./patientService";
import { ReferralService } from "./referralService";
import { SearchService } from "./searchService";
import { UserService } from "./userService";

export { unauthResponseClear, checkIsAuthenticated } from "./authService";


function getServiceInput() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    return [ dispatch, navigate ] as const;
}


export function useAuthService() {
    return new AuthService( ...getServiceInput() );
}

export function useAdminService() {
    return new AdminService( ...getServiceInput() );
}

export function useClinicianService() {
    return new ClinicianService( ...getServiceInput() );
}

export function useDelegateService() {
    return new DelegateService( ...getServiceInput() );
}

export function useJobRoleService() {
    return new JobRoleService( ...getServiceInput() );
}

export function useInsuranceAgreementService() {
    return new InsuranceAgreementService( ...getServiceInput() );
}

export function usePatientService() {
    return new PatientService( ...getServiceInput() );
}

export function useReferralService() {
    return new ReferralService( ...getServiceInput() );
}

export function useSearchService() {
    return new SearchService( ...getServiceInput() );
}

export function useUserService() {
    return new UserService( ...getServiceInput() );
}
