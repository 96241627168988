import * as CONSTS from '@constants/referralConstants';
import * as INIT from '@constants/initDataConstants';
import { LOGOUT_ACTION } from '@constants/authConstants';

const initialState = {
	loading: false,
	referralList: null,
	referral: INIT.initReferral,
	referralsMade: [],
	referralsReceived: { accepted: [], waiting: [] },
	referralsOpen: [],
};

// eslint-disable-next-line default-param-last
export default function referrals(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case CONSTS.CLINICIAN_REFERRALS_ACTION.REQUEST:
		case CONSTS.REFERRAL_TOGGLE_ACCEPT_ACTION.REQUEST:
		case CONSTS.REFERRAL_TOGGLE_COMPLETE_ACTION.REQUEST:
		case CONSTS.CREATE_REFERRAL_ACTION.REQUEST:
		case CONSTS.REFERRAL_LIST_REQUEST:
		case CONSTS.REFERRAL_REQUEST:
		case CONSTS.REFERRAL_CANCEL_ACTION.REQUEST:
			return {
				...state,
				loading: true,
			};
		case CONSTS.REFERRAL_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				referralList: payload,
			};
		case CONSTS.REFERRAL_LIST_FAIL:
			return {
				...state,
				loading: false,
				referralList: null,
			};
		case CONSTS.CREATE_REFERRAL_ACTION.SUCCESS:
			return {
				...state,
				loading: false,
				referral: payload.referral,
			};
		case CONSTS.CREATE_REFERRAL_ACTION.FAIL:
			return {
				...state,
				loading: false,
				referral: INIT.initReferral,
			};

		case CONSTS.REFERRAL_SUCCESS:
			return {
				...state,
				loading: false,
				referral: payload.referral,
			};
		case CONSTS.REFERRAL_FAIL:
			return {
				...state,
				loading: false,
				referral: {},
			};
		case CONSTS.CLINICIAN_REFERRALS_ACTION.SUCCESS:
		case CONSTS.REFERRAL_TOGGLE_ACCEPT_ACTION.SUCCESS:
		case CONSTS.REFERRAL_TOGGLE_COMPLETE_ACTION.SUCCESS:
		case CONSTS.REFERRAL_CANCEL_ACTION.SUCCESS:
			return {
				...state,
				loading: false,
				referralsMade: payload.referralsMade,
				referralsReceived: payload.referralsReceived,
				referralsOpen: payload.referralsOpen,
			};
		case CONSTS.CLINICIAN_REFERRALS_ACTION.FAIL:
			return {
				...state,
				loading: false,
				referralsMade: [],
				referralsReceived: [],
				referralsOpen: [],
			};
		case CONSTS.REFERRAL_TOGGLE_ACCEPT_ACTION.FAIL:
		case CONSTS.REFERRAL_TOGGLE_COMPLETE_ACTION.FAIL:
		case CONSTS.REFERRAL_CANCEL_ACTION.FAIL:
			return {
				...state,
				loading: false,
			};
		case LOGOUT_ACTION.SUCCESS:
			return { ...initialState };
		default:
			return state;
	}
}
