import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import { clinicianProfilesPropType } from '@constants/propTypes';
import ProfileInfoUpdate from '@components/user/Profile/ProfileInfoUpdate';
import ProfileChangePassword from '@components/user/Profile/ProfileChangePassword';
import ProfileAddClinic from '@components/user/Profile/ProfileAddClinic';
import ProfileChangeInsurance from '@components/user/profile/ProfileChangeInsurance';
import ProfileChangeNotifications from '@components/user/Profile/ProfileChangeNotifications';
import UserClinicianProfiles from '@components/clinicians/UserClinicianProfiles';
import Loader from '@components/app/Loader';
import ProfileTabsDropDown from '@/components/user/ProfileTabsDropDown/ProfileTabsDropDown';
import useScreenInfo from '@/hooks/app/useScreenInfo';
import ProfileFullDeactivatePopup from '@/components/user/Profile/ProfileFullDeactivatePopup';

function ProfileScreen({ clinicalProfiles }) {
	const isDelegate = useSelector((state) => state.profile.isDelegate);
	const [view, setView] = useState('profile');
	const [title, setTitle] = useState('Profile');
	const [isNarrow] = useScreenInfo(768);
	const [showDeactivatePopup, setShowDeactivatePopup] = useState(false);
	const colSizes = { sm: 12, md: 3 };

	useEffect(() => {
		const titles = {
			profile: 'Profile',
			info: 'Update Information',
			password: 'Change password',
			clinic: 'Add new clinic',
			insurance: 'Update insurance agreements',
			notifications: 'Update notification settings',
		};
		setTitle(titles[view]);
	}, [view]);

	if (!clinicalProfiles || clinicalProfiles.length === 0) {
		return (
			<div className="medr-layout text-center profile-page">
				<h1>Profile</h1>
				<Loader text="Loading user data" />
			</div>
		);
	}

	const views = [
		'profile',
		'info',
		'password',
		'clinic',
		'insurance',
		'notifications',
	];
	const viewsKey = {
		profile: 'Profile',
		info: 'Update Information',
		password: 'Change password',
		clinic: 'Manage clinics',
		insurance: 'Update insurance agreements',
		notifications: 'Notification Settings',
	};
	const viewOptions = [
		{ value: 'profile', label: 'Profile' },
		{ value: 'info', label: 'Update Information' },
		{ value: 'password', label: 'Change password' },
		{ value: 'clinic', label: 'Manage clinics' },
		{ value: 'insurance', label: 'Update insurance agreements' },
		{ value: 'notifications', label: 'Notification Settings' },
	];

	const userProfileId = useSelector((state) => state.auth.user.id);
	const buttons = (
		<>
			{views.map((v) => {
				if (isDelegate && v === 'notifications') return null;
				return (
					<Row key={v}>
						<Col>
							<Button
								className={`sel-button w100 mb-4 ${view === v ? 'active' : ''}`}
								onClick={() => setView(v)}
							>
								{viewsKey[v]}
							</Button>
						</Col>
					</Row>
				);
			})}
			<Row key="rm-accout">
				<Col>
					<Button
						className={`sel-button w100 mb-4`}
						variant={'danger'}
						onClick={() => setShowDeactivatePopup(true)}
					>
						Deactivate account
					</Button>
				</Col>
			</Row>
		</>
	);
	const dropdown = (
		<ProfileTabsDropDown
			viewName={title}
			viewOptions={viewOptions}
			updateView={setView}
		/>
	);
	return (
		<div
			className="medr-layout text-center profile-page"
			style={{ marginLeft: 'auto', marginRight: 'auto' }}
		>
			<h1>{title}</h1>
			<ProfileFullDeactivatePopup
				userProfileId={userProfileId}
				showModal={showDeactivatePopup}
				handleClose={() => setShowDeactivatePopup(false)}
			/>
			<Row>
				<Col className="py-1" sm={colSizes.sm} md={colSizes.md}>
					{isNarrow ? dropdown : buttons}
				</Col>
				<Col>
					{view === 'profile' && (
						<UserClinicianProfiles crps={clinicalProfiles} isUser />
					)}
					{view === 'info' && <ProfileInfoUpdate />}
					{view === 'password' && <ProfileChangePassword />}
					{view === 'clinic' && <ProfileAddClinic />}
					{view === 'insurance' && <ProfileChangeInsurance />}
					{!isDelegate && view === 'notifications' && (
						<ProfileChangeNotifications />
					)}
				</Col>
			</Row>
		</div>
	);
}

ProfileScreen.propTypes = {
	clinicalProfiles: clinicianProfilesPropType.isRequired,
};

const mapStateToProps = (state) => ({
	clinicalProfiles: state.profile.clinicianProfiles,
});

export default connect(mapStateToProps, {})(ProfileScreen);
