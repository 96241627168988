import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { usePatientService } from '@actions';
import { getPatientNameString, reformatDate } from '@/actions/general';

const usePatientSearchBar = (currentPatientId, sendPxId) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const patientService = usePatientService();

	const handleGetPatients = async () =>
		await patientService.getClinicianPatientList();
	const handleGetPatientById = async (pxId) =>
		await patientService.getPatientById(pxId);

	useEffect(() => {
		handleGetPatients();
		return () => {};
	}, []);

	const addedPatients = useSelector((state) => state.patients.addedPatients);
	const referredPatients = useSelector(
		(state) => state.patients.referredPatients
	);
	const acceptedPatients = useSelector(
		(state) => state.patients.acceptedPatients
	);
	const completedPatients = useSelector(
		(state) => state.patients.completedPatients
	);

	const currentPatient = useSelector((state) => state.patients.currentPatient);
	const [patients, setPatients] = useState([]); // [activePatients, pastPatients, acceptedPatients]
	const [patientsOptions, setPatientsOptions] = useState([]);
	const [selectedPatient, setSelectedPatient] = useState(null);
	const [inputPxId, setInputPxId] = useState(null);

	const getPxOption = (px) => ({
		label: (
			<>
				<strong style={{ fontWeight: 'bold' }}>
					{getPatientNameString(px)}
				</strong>{' '}
				- DOB:{reformatDate(px.dob)} - {px.address.postcode}
			</>
		),
		value: `${getPatientNameString(px)} - DOB:${reformatDate(px.dob)} - ${
			px.address.postcode
		}`,
		pid: px.id,
	});

	useEffect(() => {
		if (
			!addedPatients &&
			!referredPatients &&
			!acceptedPatients &&
			!completedPatients
		)
			return;
		const pxList = [
			...addedPatients,
			...referredPatients,
			...acceptedPatients,
			...completedPatients,
		];
		const optsList = pxList.map((px) => getPxOption(px));
		setPatients(pxList);
		setPatientsOptions(optsList);
	}, [addedPatients, referredPatients, acceptedPatients, completedPatients]);

	const selectPatientByOption = (opt) => {
		const px = patients.find((patient) => patient.id === opt.pid);
		if (px === null || px === undefined) return;
		setSelectedPatient(opt);
		if (currentPatient && currentPatient.id === px.id) return;
		handleGetPatientById(px.id);
		if (sendPxId) {
			sendPxId(px.id);
		}
		// setCurrentPatient(px);
	};

	const selectPatientByInputId = () => {
		if (!inputPxId || inputPxId === undefined) return;
		if (inputPxId === 0) {
			setSelectedPatient(null);
			return;
		}
		if (currentPatient && currentPatient.id === inputPxId) return;
		handleGetPatientById(currentPatient.id);
	};

	useEffect(() => {
		if (
			!currentPatientId ||
			currentPatientId === undefined ||
			currentPatientId === 0
		) {
			patientService.resetCurrentPatient();
		}
		setInputPxId(currentPatientId);
	}, [currentPatientId]);
	useEffect(() => {
		selectPatientByInputId();
	}, [inputPxId]);

	useEffect(() => {
		if (!currentPatient || currentPatient === undefined) return;
		if (!currentPatient.id || currentPatient.id === 0) {
			setSelectedPatient(null);
			return;
		}

		setSelectedPatient(getPxOption(currentPatient));
	}, [currentPatient]);

	return {
		patients,
		patientsOptions,
		selectPatientByOption,
		selectedPatient,
		currentPatient,
	};
};

export default usePatientSearchBar;
