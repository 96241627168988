import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import CrpsDisplayBox from '@components/clinicians/CrpsDisplayBox';
import { clinicianProfilesPropType } from '@constants/propTypes';
import NewCrpPopup from '@components/clinicians/NewCrpPopup';
import ClinicFormDoctor from '@components/clinicians/ClinicFormDoctor';
import ClinicFormHcp from '@components/clinicians/ClinicFormHcp';
import ProfileRemoveClinic, {
	MoreProfilesRequired,
} from './ProfileRemoveClinicPopup';
import { useClinicianService } from '@actions';

function ProfileAddClinic({ clinicianProfiles }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const clinicianService = useClinicianService();

	const [showNewCrpPopup, setShowNewCrpPopup] = useState(false);
	const [currentClinic, setCurrentClinic] = useState(null); // [clinic, setClinic
	const [newCrpPopup, setNewCrpPopup] = useState(null);
	const [isDoctor, setIsDoctor] = useState(false);

	useEffect(() => {
		if (clinicianProfiles.length === 0) return;
		const firstCrp = clinicianProfiles[0];
		const drJobrRoles = firstCrp.userProfile.jobRole.filter(
			(item) => item.jobRole === 'Doctor'
		);
		setIsDoctor(drJobrRoles.length > 0);
	}, []);

	const closePopup = () => {
		setShowNewCrpPopup(false);
	};

	const handleCreateCrp = async (clinic) => {
		if (!clinic.isNewClinic) {
			const isCurrentProfile =
				clinicianProfiles.filter((item) => item.clinic.id === clinic.id)
					.length > 0;
			if (isCurrentProfile) {
				closePopup();
				return;
			}
		}
		const formData = {
			clinicName: clinic.clinicName,
			centreName: clinic.centreName,
			address: clinic.address,
		};

		const createSuccess = await clinicianService.postCreateCrp(formData);
		if (!createSuccess) return;
		closePopup();
	};

	function createNewCrpPopup(clinic) {
		return (
			<NewCrpPopup
				clinic={clinic}
				showModal={showNewCrpPopup}
				sendClinic={() => handleCreateCrp(clinic)}
				handleClose={closePopup}
			/>
		);
	}

	const setPopupHandler = (clinic) => {
		setCurrentClinic(clinic);
		setShowNewCrpPopup(true);
	};

	useEffect(() => {
		if (showNewCrpPopup === true) {
			setNewCrpPopup(createNewCrpPopup(currentClinic));
		} else {
			setNewCrpPopup(null);
		}
	}, [showNewCrpPopup]);

	const handleAddNewClinic = (clinic) => {
		setPopupHandler(clinic);
	};

	const handleEditClinic = async (clinic) => {
		await clinicianService.editCrp(clinic);
	};

	const [showRemoveClinic, setShowRemoveClinic] = useState(false);
	const [rmClinic, setRmClinic] = useState(null);

	const handleRemoveClinic = (clinic) => {
		setRmClinic(clinic.id);
	};
	const closeRemoveClinicPopup = () => {
		setShowRemoveClinic(false);
		setRmClinic(null);
	};

	useEffect(() => {
		if (rmClinic !== null) {
			setShowRemoveClinic(true);
		}
	}, [rmClinic]);

	return (
		<>
			{showRemoveClinic && clinicianProfiles.length > 1 && (
				<ProfileRemoveClinic
					userProfileId={clinicianProfiles[0].userProfile.id}
					clinicId={rmClinic}
					showPopup={showRemoveClinic}
					handleClose={closeRemoveClinicPopup}
				/>
			)}
			{showRemoveClinic && clinicianProfiles.length === 1 && (
				<MoreProfilesRequired
					showPopup={showRemoveClinic}
					handleClose={closeRemoveClinicPopup}
				/>
			)}
			<Row>
				<Col>
					<CrpsDisplayBox
						editable
						editClinic={handleEditClinic}
						removeClinic={handleRemoveClinic}
					/>
				</Col>
			</Row>
			{newCrpPopup}
			<Row className="mt-4">
				<Col className="text-left">
					<h6>Assign a new clinic</h6>
				</Col>
			</Row>

			{isDoctor ? (
				<ClinicFormDoctor handleAddClinic={handleAddNewClinic} />
			) : (
				<ClinicFormHcp handleAddClinic={handleAddNewClinic} />
			)}
		</>
	);
}

ProfileAddClinic.propTypes = {
	clinicianProfiles: clinicianProfilesPropType.isRequired,
};

const mapStateToProps = (state) => ({
	clinicianProfiles: state.profile.clinicianProfiles,
});

export default connect(mapStateToProps, {})(ProfileAddClinic);
