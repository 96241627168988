import React, { useEffect, useState } from 'react';
import { Table, Tabs, Tab } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
	clinicianProfilesPropType,
	userProfilesPropType,
} from '@constants/propTypes';
import PaginationBar from '../app/PaginationBar';
import { useAdminService } from '@actions';
import Loader from '@components/app/Loader';
import { getNameString, getAddressStr } from '@/actions/general';

function AdminCliniciansTable({
	staffList,
	cliniciansList,
	unverifiedList,
	deactivatedList,
	delegatesList,
}) {
	const adminService = useAdminService();
	const nCliniciansWindow = 18;
	const start = 0;
	const end = nCliniciansWindow;

	const [staff, setStaff] = useState(null);
	const [staffLength, setStaffLength] = useState(0);
	const [staffSlice, setStaffSlice] = useState({ start, end });

	const [clinicians, setClinicians] = useState(null);
	const [cliniciansLength, setCliniciansLength] = useState(0);
	const [cliniciansSlice, setCliniciansSlice] = useState({ start, end });

	const [unverified, setUnverified] = useState(null);
	const [unverifiedLength, setUnverifiedLength] = useState(0);
	const [unverifiedSlice, setUnverifiedSlice] = useState({ start, end });

	const [deactivated, setDeactivated] = useState(null);
	const [deactivatedLength, setDeactivatedLength] = useState(0);
	const [deactivatedSlice, setDeactivatedSlice] = useState({ start, end });

	const [delegates, setDelegates] = useState(null);
	const [delegatesLength, setDelegatesLength] = useState(0);
	const [delegatesSlice, setDelegatesSlice] = useState({ start, end });

	const refreshcliniciansLists = async () =>
		await adminService.getAdminClinicansList();
	useEffect(() => {
		refreshcliniciansLists();
	}, []);

	const updateListSlice = (list, slice, setList, setLength) => {
		if (!list || list.length === 0) {
			setList([]);
			return;
		}
		const sl = list.slice(slice.start, slice.end);
		setList(sl);
		setLength(list.length);
	};

	const updateStaffSliceIndices = (startInd, endInd) =>
		setStaffSlice({ start: startInd, end: endInd });
	const updateCliniciansSliceIndices = (startInd, endInd) =>
		setCliniciansSlice({ start: startInd, end: endInd });
	const updateUnverifiedSliceIndices = (startInd, endInd) =>
		setUnverifiedSlice({ start: startInd, end: endInd });
	const updateDeactivatedSliceIndices = (startInd, endInd) =>
		setDeactivatedSlice({ start: startInd, end: endInd });
	const updateDelegatesSliceIndices = (startInd, endInd) =>
		setDelegatesSlice({ start: startInd, end: endInd });

	useEffect(() => {
		updateListSlice(staffList, staffSlice, setStaff, setStaffLength);
	}, [staffList, staffSlice]);

	useEffect(() => {
		updateListSlice(
			cliniciansList,
			cliniciansSlice,
			setClinicians,
			setCliniciansLength
		);
	}, [cliniciansList, cliniciansSlice]);

	useEffect(() => {
		updateListSlice(
			unverifiedList,
			unverifiedSlice,
			setUnverified,
			setUnverifiedLength
		);
	}, [unverifiedList, unverifiedSlice]);

	useEffect(() => {
		updateListSlice(
			deactivatedList,
			deactivatedSlice,
			setDeactivated,
			setDeactivatedLength
		);
	}, [deactivatedList, deactivatedSlice]);

	useEffect(() => {
		updateListSlice(
			delegatesList,
			delegatesSlice,
			setDelegates,
			setDelegatesLength
		);
	}, [delegatesList, delegatesSlice]);

	const cliniciansHeadersRow = (
		<tr>
			<th>Row</th>
			<th>Name</th>
			<th>email</th>
			<th>Role</th>
			<th>Centre</th>
			<th>Registration</th>
		</tr>
	);

	const cliniciansTableContent =
		clinicians &&
		clinicians.map((clinician, index) => (
			<tr key={clinician.id}>
				<td>{index + 1}</td>
				<td>{getNameString(clinician.userProfile)}</td>
				<td>{clinician.userProfile.user.email}</td>
				<td>{clinician.userProfile.jobRole[0].jobTitle}</td>
				<td>
					{clinician.clinic.centreName},{' '}
					{getAddressStr(clinician.clinic.address)}
				</td>
				<td>
					{clinician.userProfile.jobRole.registrationBody}:{' '}
					{clinician.userProfile.registrationNumber}
				</td>
			</tr>
		));

	const deactivatedHeadersRow = (
		<tr>
			<th>Row</th>
			<th>Name</th>
			<th>email</th>
			<th>Role</th>
			<th>Centre</th>
			<th>Registration</th>
		</tr>
	);

	const deactivatedTableContent =
		deactivated &&
		deactivated.map((clinician, index) => (
			<tr key={clinician.id}>
				<td>{index + 1}</td>
				<td>{getNameString(clinician.userProfile)}</td>
				<td>{clinician.userProfile.user.email}</td>
				<td>{clinician.userProfile.jobRole[0].jobTitle}</td>
				<td>
					{clinician.clinic.centreName},{' '}
					{getAddressStr(clinician.clinic.address)}
				</td>
				<td>
					{clinician.userProfile.jobRole.registrationBody}:{' '}
					{clinician.userProfile.registrationNumber}
				</td>
			</tr>
		));

	const unverifiedHeadersRow = (
		<tr>
			<th>Row</th>
			<th>Name</th>
			<th>email</th>
			<th>Role</th>
			<th>Centre</th>
			<th>Registration</th>
		</tr>
	);

	const unverifiedTableContent =
		unverified &&
		unverified.map((clinician, index) => (
			<tr key={clinician.id}>
				<td>{index + 1}</td>
				<td>{getNameString(clinician.userProfile)}</td>
				<td>{clinician.userProfile.user.email}</td>
				<td>{clinician.userProfile.jobRole[0].jobTitle}</td>
				<td>
					{clinician.clinic.centreName},{' '}
					{getAddressStr(clinician.clinic.address)}
				</td>
				<td>
					{clinician.userProfile.jobRole.registrationBody}:{' '}
					{clinician.userProfile.registrationNumber}
				</td>
			</tr>
		));

	const staffHeadersRow = (
		<tr>
			<th>Name</th>
			<th>Site Admin</th>
		</tr>
	);

	const staffTableContent =
		staff &&
		staff.map((user) => (
			<tr key={user.id}>
				<td>{getNameString(user.userProfile)}</td>
				<td>{user.userProfile.user.is_staff ? 'Yes' : 'No'}</td>
			</tr>
		));

	const delegatesHeadersRow = (
		<tr>
			<th>Row</th>
			<th>Name</th>
			<th>email</th>
		</tr>
	);

	const delegatesTableContent =
		delegates &&
		delegates.map((user, index) => (
			<tr key={user.id}>
				<td>{index + 1}</td>
				<td>{getNameString(user)}</td>
				<td>{user.user.email}</td>
			</tr>
		));

	const noUsersDisplay = (
		<div className="center-box-parent">
			<div className="center-box-child">
				<h4 className="medr-text-inv">No users to display at the moment</h4>
			</div>
		</div>
	);

	const loadingView = (
		<>
			<div className="gap mt-5" />
			<Loader text="Loading admin clinicians list..." />
		</>
	);

	const isLoading = adminService.requestLoading;

	function cliniciansTab({
		eventKey,
		title,
		listLength,
		content,
		tableHeaders,
		updateSlice,
	}) {
		return (
			<Tab eventKey={eventKey} title={title} className="custom-tabs">
				{isLoading && loadingView}
				{!isLoading && listLength === 0 && noUsersDisplay}
				{!isLoading && listLength > 0 && (
					<Table striped bordered hover responsive className="table-sm">
						<thead>{tableHeaders}</thead>
						<tbody>{content}</tbody>
					</Table>
				)}
				{!isLoading && listLength > nCliniciansWindow && (
					<div className="mb-3">
						<PaginationBar
							arrayLength={listLength}
							setSliceValues={updateSlice}
							windowSize={nCliniciansWindow}
						/>
					</div>
				)}
			</Tab>
		);
	}

	const staffInput = {
		eventKey: 'staff',
		title: 'Staff',
		listLength: staffLength,
		content: staffTableContent,
		tableHeaders: staffHeadersRow,
		updateSlice: updateStaffSliceIndices,
	};

	const cliniciansInput = {
		eventKey: 'clinicians',
		title: 'Clinicians',
		listLength: cliniciansLength,
		content: cliniciansTableContent,
		tableHeaders: cliniciansHeadersRow,
		updateSlice: updateCliniciansSliceIndices,
	};

	const unverifiedInput = {
		eventKey: 'unverified',
		title: 'Unverified',
		listLength: unverifiedLength,
		content: unverifiedTableContent,
		tableHeaders: unverifiedHeadersRow,
		updateSlice: updateUnverifiedSliceIndices,
	};

	const deactivatedInput = {
		eventKey: 'deactivated',
		title: 'Deactivated',
		listLength: deactivatedLength,
		content: deactivatedTableContent,
		tableHeaders: deactivatedHeadersRow,
		updateSlice: updateDeactivatedSliceIndices,
	};

	const delegatesInput = {
		eventKey: 'delegates',
		title: 'Delegates',
		listLength: delegatesLength,
		content: delegatesTableContent,
		tableHeaders: delegatesHeadersRow,
		updateSlice: updateDelegatesSliceIndices,
	};

	return (
		<Tabs
			defaultActiveKey="clinicians"
			id="custom-tabs"
			className="custom-tabs mb-3"
		>
			{cliniciansTab(staffInput)}
			{cliniciansTab(cliniciansInput)}
			{cliniciansTab(unverifiedInput)}
			{cliniciansTab(deactivatedInput)}
			{cliniciansTab(delegatesInput)}
		</Tabs>
	);
}

AdminCliniciansTable.propTypes = {
	staffList: clinicianProfilesPropType.isRequired,
	cliniciansList: clinicianProfilesPropType.isRequired,
	unverifiedList: clinicianProfilesPropType.isRequired,
	deactivatedList: clinicianProfilesPropType.isRequired,
	delegatesList: userProfilesPropType.isRequired,
};

const mapStateToProps = (state) => ({
	staffList: state.admin.clinicians.staff,
	cliniciansList: state.admin.clinicians.clinicians,
	unverifiedList: state.admin.clinicians.unverified,
	deactivatedList: state.admin.clinicians.deactivated,
	delegatesList: state.admin.clinicians.delegates,
});

export default connect(mapStateToProps, {})(AdminCliniciansTable);
