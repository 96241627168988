import { createActionTypes, ActionStatus } from "./requestConstant";

export const CLINICIAN_LIST_ACTION: ActionStatus = createActionTypes( 'CLINICIAN_LIST' );
export const CLINICS_LIST_ACTION: ActionStatus = createActionTypes( 'CLINICS_LIST' );
export const CLINICIAN_BY_ID_ACTION: ActionStatus = createActionTypes( 'CLINICIAN_BY_ID' );
export const CLINICIAN_CREATE_ACTION: ActionStatus = createActionTypes( 'CLINICIAN_CREATE' );
export const CLINICIAN_UPDATE_ACTION: ActionStatus = createActionTypes( 'CLINICIAN_UPDATE' );
export const CLINICIAN_INSURANCE_UPDATE_ACTION: ActionStatus = createActionTypes( 'CLINICIAN_INSURANCE_UPDATE' );
export const CLINICIAN_ROLE_REMOVE_REQ_ACTION: ActionStatus = createActionTypes( 'CLINICIAN_ROLE_REMOVE_REQ' );
export const CLINICIAN_ROLE_DEACTIVATE_ACTION: ActionStatus = createActionTypes( 'CLINICIAN_ROLE_DEACTIVATE' );
export const JOB_ROLE_LIST_ACTION: ActionStatus = createActionTypes( 'JOB_ROLE_LIST' );
export const JOB_ROLE_BY_ID_ACTION: ActionStatus = createActionTypes( 'JOB_ROLE_BY_ID' );
export const INSURANCE_AGREEMENT_LIST_ACTION: ActionStatus = createActionTypes( 'INSURANCE_AGREEMENT_LIST' );
export const USER_CRPS_GET_ACTION: ActionStatus = createActionTypes( 'USER_CRPS_GET' );
export const CLINICIAN_ADD_FAVOURITE_ACTION: ActionStatus = createActionTypes( 'CLINICIAN_ADD_FAVOURITE' );
export const CLINICIAN_RM_FAVOURITE_ACTION: ActionStatus = createActionTypes( 'CLINICIAN_RM_FAVOURITE' );
export const INVITE_CLINICIAN_ACTION: ActionStatus = createActionTypes( 'INVITE_CLINICIAN' );


// export const INSURANCE_AGREEMENT_SET_ID_ACTION: ActionStatus = createActionTypes( 'INSURANCE_AGREEMENT_SET_ID' );

// export const INSURANCE_AGREEMENT_SET_ID_REQUEST: string =
// 	'INSURANCE_AGREEMENT_SET_ID_REQUEST';
// export const INSURANCE_AGREEMENT_SET_ID_SUCCESS: string =
// 	'INSURANCE_AGREEMENT_SET_ID_SUCCESS';
// export const INSURANCE_AGREEMENT_SET_ID_FAIL: string =
// 	'INSURANCE_AGREEMENT_SET_ID_FAIL';

// export const CLINICIAN_GET_FAVOURITE_ACTION: ActionStatus = createActionTypes( 'CLINICIAN_GET_FAVOURITE' );


// export const CLINICIAN_GET_FAVOURITE_REQUEST: string =
// 	'CLINICIAN_GET_FAVOURITE_REQUEST';
// export const CLINICIAN_GET_FAVOURITE_SUCCESS: string =
// 	'CLINICIAN_GET_FAVOURITE_SUCCESS';
// export const CLINICIAN_GET_FAVOURITE_FAIL: string = 'CLINICIAN_GET_FAVOURITE_FAIL';