import { Dispatch } from 'redux';
import { NavigateFunction } from 'react-router-dom';
import * as CONSTS from '@/constants/authConstants';
import { getAnyApiClient, getAuthApiClient } from './apiActions';
import { RegisterDelegateUserRequest, VerifyDelegateUserRequest, UpdateDelegatePermissionRequest, DeactivateDelegateRequest, DelegatesListResponse } from '../client';
import { redirectIfInvalidToken } from './authService';

type ApiClient = ReturnType<typeof getAnyApiClient>;
type ApiAuthClient = ReturnType<typeof getAuthApiClient>;


export class DelegateService {
    private dispatch: Dispatch;
    private navigate: NavigateFunction;
    private appAnyClient: ApiClient;
    private appAuthClient: ApiAuthClient;

    constructor ( dispatch: Dispatch, navigate: NavigateFunction ) {
        this.dispatch = dispatch;
        this.navigate = navigate;
        this.appAnyClient = getAnyApiClient();
        this.appAuthClient = getAuthApiClient();
    }

    async registerDelegateUser( data: RegisterDelegateUserRequest ): Promise<boolean> {
        this.dispatch( { type: CONSTS.REGISTER_DELEGATE_ACTION.REQUEST } );

        try {
            await this.appAuthClient.registerDelegate.registerDelegateCreate( data );
            this.dispatch( { type: CONSTS.REGISTER_DELEGATE_ACTION.SUCCESS } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: CONSTS.REGISTER_DELEGATE_ACTION.FAIL } );
            return false;
        }
    }

    async getVerifyDelegateUser( code: string, time: string, user: string ): Promise<boolean> {
        this.dispatch( { type: CONSTS.REGISTER_DELEGATE_INFO_ACTION.REQUEST } );

        try {
            const data = await this.appAnyClient.getVerifyDelegate.getVerifyDelegateUserTimeCodeRetrieve( code, time, user );
            this.dispatch( { type: CONSTS.REGISTER_DELEGATE_INFO_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            this.dispatch( { type: CONSTS.REGISTER_DELEGATE_INFO_ACTION.FAIL } );
            return false;
        }
    }

    async verifyDelegateUser( data: VerifyDelegateUserRequest ): Promise<boolean> {
        this.dispatch( { type: CONSTS.VERIFY_DELEGATE_ACTION.REQUEST } );

        try {
            await this.appAuthClient.verifyDelegate.verifyDelegateUpdate( data );
            this.dispatch( { type: CONSTS.VERIFY_DELEGATE_ACTION.SUCCESS } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: CONSTS.VERIFY_DELEGATE_ACTION.FAIL } );
            return false;
        }
    }

    async getUserDelegateList(): Promise<boolean> {
        this.dispatch( { type: CONSTS.USER_DELEGATES_ACTION.REQUEST } );

        try {
            const data: DelegatesListResponse = await this.appAuthClient.user.userDelegatesRetrieve();
            this.dispatch( { type: CONSTS.USER_DELEGATES_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: CONSTS.USER_DELEGATES_ACTION.FAIL } );
            return false;
        }
    }

    async updateDelegateUserPermission( inputData: UpdateDelegatePermissionRequest ): Promise<boolean> {
        this.dispatch( { type: CONSTS.UPDATE_DELEGATE_PERMISSION_ACTION.REQUEST } );

        try {
            const data: DelegatesListResponse = await this.appAuthClient.user.userDelegateUpdatePermisisonCreate( inputData );
            this.dispatch( { type: CONSTS.UPDATE_DELEGATE_PERMISSION_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: CONSTS.UPDATE_DELEGATE_PERMISSION_ACTION.FAIL } );
            return false;
        }
    }

    async deactivateDelegateUser( delegate: any ): Promise<boolean> {
        this.dispatch( { type: CONSTS.DEACTIVATE_DELEGATE_ACTION.REQUEST } );
        const payload: DeactivateDelegateRequest = { delegate }
        try {
            const data: DelegatesListResponse = await this.appAuthClient.user.userDelegateDeactivateCreate( payload );
            this.dispatch( { type: CONSTS.DEACTIVATE_DELEGATE_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: CONSTS.DEACTIVATE_DELEGATE_ACTION.FAIL } );
            return false;
        }
    }

    async restoreDelegateUser( delegate: any ): Promise<boolean> {
        this.dispatch( { type: CONSTS.RESTORE_DELEGATE_ACTION.REQUEST } );
        const payload: DeactivateDelegateRequest = { delegate }
        try {
            const data: DelegatesListResponse = await this.appAuthClient.user.userDelegateRestoreCreate( payload );
            this.dispatch( { type: CONSTS.RESTORE_DELEGATE_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: CONSTS.RESTORE_DELEGATE_ACTION.FAIL } );
            return false;
        }
    }

}