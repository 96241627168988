import { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useAuthService } from '@actions';
import PasswordField from '@components/app/parts/PasswordField';
import { set } from 'date-fns';

function ProfileChangePassword() {
	const authService = useAuthService();
	const [wrongPassword, setWrongPassword] = useState(false);

	const initData = {
		currentPassword: '',
		password: '',
		rePassword: '',
	};

	const [values, setValues] = useState(initData);
	const [passwordsMatch, setPasswordsMatch] = useState(false);
	const [correctPassword, setCorrectPassword] = useState(true);

	const updateChanges = (newData) => {
		setValues({ ...values, ...newData });
	};
	const { currentPassword, password, rePassword } = values;

	useEffect(() => {
		setPasswordsMatch(password === rePassword);
	}, [password, rePassword]);

	const username = useSelector((state) => state.auth.user.user.username);

	const onSubmit = async (e) => {
		e.preventDefault();
		const formData = { currentPassword, newPassword: password };

		const changeSuccess = await authService.sendResetPassword(formData);
		if (!changeSuccess) {
			setWrongPassword(true);
			return;
		}

		await authService.login(username, password);
		authService.goToDashboardPage();
	};

	return (
		<div className="d-flex justify-content-center">
			<Form
				onSubmit={onSubmit}
				className="text-left"
				style={{ maxWidth: '500px', flexGrow: 1 }}
			>
				<PasswordField
					controlId="password"
					label="Current Password"
					password={currentPassword}
					keyLabel="currentPassword"
					changeValues={updateChanges}
					autoComplete="password"
					isInvalid={wrongPassword}
					invalidString="Incorrect password!"
				/>
				<PasswordField
					controlId="newPassword"
					label="Password"
					password={password}
					keyLabel="password"
					changeValues={updateChanges}
				/>
				<PasswordField
					controlId="reNewPassword"
					label="Confirm Password"
					password={rePassword}
					keyLabel="rePassword"
					changeValues={updateChanges}
					isInvalid={!passwordsMatch}
					invalidString="Passwords do not match!"
				/>
				<Row className="text-center mt-4">
					<Col>
						<Button
							className="sel-button w80"
							type="submit"
							variant="primary"
							disabled={!passwordsMatch}
						>
							Set password
						</Button>
					</Col>
				</Row>
			</Form>
		</div>
	);
}

export default ProfileChangePassword;
