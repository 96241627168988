import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDelegateService } from '@actions';

const useDelegateVerificationForm = () => {
	const params = useParams();
	const navigate = useNavigate();
	const delegateService = useDelegateService();

	useEffect(() => {
		const getDelegateInfo = async () => {
			const { userHash, timeHash, verificationCode } = params;
			const success = await delegateService.getVerifyDelegateUser(
				verificationCode,
				timeHash,
				userHash
			);
			if (!success) navigate('/register-delegate/verify-email-error');
		};
		window.scrollTo(0, 0);
		getDelegateInfo();
	}, []);

	const initData = {
		title: '',
		firstName: '',
		lastName: '',
		email: '',
		password: '',
		rePassword: '',
		clinician: {
			title: '',
			firstName: '',
			lastName: '',
		},
	};
	const delegateRegistration = useSelector(
		(state) => state.auth.delegateRegistration
	);
	const [userData, setUserData] = useState(initData);
	const [passwordsMatch, setPasswordsMatch] = useState(false);
	const [termsAccepted, setTermsAccepted] = useState(false);
	const [showTerms, setShowTerms] = useState(false);
	const [showSuccess, setShowSuccess] = useState(false);

	useEffect(() => {
		if (
			delegateRegistration &&
			delegateRegistration.clinician &&
			delegateRegistration.delegate &&
			delegateRegistration.delegate.user
		) {
			setUserData((prevState) => ({
				...prevState,
				title: delegateRegistration.delegate.title,
				firstName: delegateRegistration.delegate.firstName,
				lastName: delegateRegistration.delegate.lastName,
				email: delegateRegistration.delegate.user.email,
				clinician: delegateRegistration.clinician,
			}));
		}
	}, [delegateRegistration]);

	useEffect(() => {
		const match = userData.password === userData.rePassword;
		setPasswordsMatch(match);
		if (!match && termsAccepted) {
			setTermsAccepted(false);
		}
	}, [userData.password, userData.rePassword]);

	const updateUserData = (data) =>
		setUserData((prevState) => ({ ...prevState, ...data }));

	const updateTermsAccepted = () => {
		const ready = passwordsMatch;
		if (!ready) {
			setTermsAccepted(false);
			return;
		}
		setTermsAccepted((prevState) => !prevState);
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		const success = await delegateService.verifyDelegateUser(userData);
		setShowSuccess(success);
	};

	return {
		userData,
		updateUserData,
		termsAccepted,
		setTermsAccepted,
		showTerms,
		setShowTerms,
		passwordsMatch,
		updateTermsAccepted,
		onSubmit,
		showSuccess,
	};
};

export default useDelegateVerificationForm;
