import { useState, useEffect } from 'react';
import { useAuthService } from '@actions';
import { useDelegateService } from '@actions';

const useRegisterDelegate = (delegatingClinicianUserProfileId) => {
	const initData = {
		title: '',
		firstName: '',
		lastName: '',
		email: '',
		delegatingClinicianUserProfileId,
		permissionViewReferrals: true,
		permisisonCreateReferrals: true,
		permisisonManageReferrals: true,
	};
	const initFormChecks = {
		title: true,
		firstName: true,
		lastName: true,
		email: true,
	};
	const authService = useAuthService();
	const delegateService = useDelegateService();

	const [registerDelegateData, setRegisterDelegateData] = useState(initData);
	const [formChecks, setFormChecks] = useState(initFormChecks);
	const [showSuccessfulRegistration, setShowSuccessfulRegistration] =
		useState(false);
	const [dupEmail, setDupEmail] = useState(false);

	const updateRegisterDelegateData = (data) =>
		setRegisterDelegateData((prevState) => ({ ...prevState, ...data }));
	const updateRegisterEmail = (e) => {
		updateRegisterDelegateData({ email: e.email });
	};

	const handleEmailCheck = async () => {
		const emailValid = await authService.registerCheckEmail({
			email: registerDelegateData.email,
		});
		setDupEmail(!emailValid);
	};

	useEffect(() => {
		handleEmailCheck();
	}, [registerDelegateData.email]);

	const checkForm = () => {
		const titleCheck = registerDelegateData.title.length > 0;
		const firstNameCheck = registerDelegateData.firstName.length > 0;
		const lastNameCheck = registerDelegateData.lastName.length > 0;
		const emailCheck = (registerDelegateData.email.length > 0) & !dupEmail;

		setFormChecks({
			title: titleCheck,
			firstName: firstNameCheck,
			lastName: lastNameCheck,
			email: emailCheck,
		});

		return firstNameCheck && lastNameCheck;
	};

	const onSumbit = async (e) => {
		e.preventDefault();
		const ready = checkForm();
		if (!ready) return;
		const success = await delegateService.registerDelegateUser(
			registerDelegateData
		);
		setShowSuccessfulRegistration(success);
	};

	return {
		registerDelegateData,
		updateRegisterDelegateData,
		updateRegisterEmail,
		formChecks,
		checkForm,
		onSumbit,
		showSuccessfulRegistration,
		dupEmail,
	};
};

export default useRegisterDelegate;
