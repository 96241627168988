import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import PatientEntryForm from '@components/patient/PatientEntryForm';
import { usePatientService } from '@actions';

function PatientEditScreen() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const patientService = usePatientService();

	const { patientId } = useParams();
	const [isInitialised, setIsInitialised] = useState(false);

	const initialise = async () => {
		if (!patientId) {
			throw new Error('Patient ID is required');
		}
		const pxId = parseInt(patientId, 10);
		await patientService.getPatientById(pxId);
		setIsInitialised(true);
	};

	useEffect(() => {
		initialise();
		return () => {};
	}, []);

	const successfullRequest = (results) => {
		if (!results.success) return;
		navigate('/patients');
	};

	return (
		<div
			className="medr-layout text-center newpatient-page"
			style={{ maxWidth: '700px', marginLeft: 'auto', marginRight: 'auto' }}
		>
			<Row className="medr-layout text-center">
				<Col>
					<h4>Enter Patient Information</h4>
				</Col>
			</Row>

			{isInitialised && (
				<PatientEntryForm
					inputPatientId={patientId}
					isSuccessful={successfullRequest}
				/>
			)}
		</div>
	);
}

export default PatientEditScreen;
