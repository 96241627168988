import { createContext, useContext } from "react";
import { Patient, JobRole, ClinicianRoleProfile, InsuranceAgreement } from '@client';


interface ReferralEntryContextType {
    referralData: any;
    activeStep: number;
    patientId: number;
    patientSelected: boolean;
    patient: Patient;
    sendPxId: (id: number) => void;
    addNewPatientSuccess: () => void;
    patientHistory: string;
    mainDescription: string;
    updateRxInfo: () => void;
    handleNext: () => void;
    handlePrevious: () => void;
    jobRole: JobRole;
    targetClinician: ClinicianRoleProfile;
    specifiedClinician: boolean
    insuranceProvider: InsuranceAgreement;
    setJobRole: (e: any) => void;
    setTargetClinicianOpen: () => void;
    setTargetClinician: () => void; 
    refreshClinicians: () => void; 
    changeGPS: () => void;
    validateClinicianProfiles: () => void;
    validateReferralData: () => void;
    onSubmit: () => void;
    referralMade: boolean;
    exitReferral: () => void;
    }


export const ReferralEntryContext = createContext<ReferralEntryContextType | undefined>(undefined);

export function useReferralEntryContext() {
  const context = useContext(ReferralEntryContext);

  if (!context) {
    throw new Error("useReferralEntryContext must be used within a ReferralEntryProvider");
  }

  return context;
}