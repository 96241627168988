import React from 'react';

export const defaultMapLat: number = 51.5072;
export const defaultMapLng: number = -0.1276;

export const jobRoles: string[] = [
	'Consultant Spinal Surgeon',
	'Spinal Surgeon',
	'GP',
	'Physiotherapist',
];
const titles: string[] = [ 'Mr', 'Mrs', 'Ms', 'Dr', 'Prof' ];

export default titles;
