import { useClinicianService, useUserService } from '@actions';

const useProfileChangeInsurance = (clinicalProfiles) => {
	const clinicianService = useClinicianService();
	const userService = useUserService();

	const existingProviders = clinicalProfiles[0].insuranceAgreements;
	const updateExistingProviders = async (providers) => {
		if (providers === existingProviders) return;

		const iaSuccess = await clinicianService.updateClinicianInsuranceAgreements(
			{ existingAgreements: providers }
		);
		if (!iaSuccess) return;

		const loadSuccess = await userService.loadUserProfile();
		if (!loadSuccess) return;
	};

	const updateNewProviders = (providers) => {
		if (!providers || !providers.length === 0) return;
		console.log('send request to update', providers); // eslint-disable-line no-console
	};
	return {
		existingProviders,
		updateExistingProviders,
		updateNewProviders,
	};
};

export default useProfileChangeInsurance;
