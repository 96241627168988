import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import  { insuranceAgreementsPropType, funcPropType } from '@constants/propTypes';
import useInsuranceSelectorGrid from './useInsuranceSelectorGrid';
import Loader from '@components/app/Loader';

function InsuranceSelectorGrid({ insuranceAgreements, currentProviders, updateCurrentProviders }){
    const { 
        providers,
        selectAllProviders,
        toggleProviderSelected
    } = useInsuranceSelectorGrid(insuranceAgreements, currentProviders, updateCurrentProviders)
    
    const loadingView = (
        <>
            <div className="gap mt-5" />
            <Loader text="Loading insurance providers..." />
        </>
    );

    if (!providers.length) {
        // TODO: Add a message to display when there are no insurance providers
        return loadingView
    }

    return (
            <>
                <Row>
                    <Col className='text-center'>
                        <Button className='sel-button w80' variant='primary' onClick={selectAllProviders}>Select all insurers</Button>
                    </Col>
                </Row>
                <Row className='my-1 insurance-list'>
                    {providers.map((insurance) => (
                        <Col xs={12} md={4} lg={3} key={`insProv${insurance.id}col`}>
                            <Button key={`insProv${insurance.id}`} className='insurance-check-btn' variant={insurance.selected ? 'info' : 'primary'} onClick={() => toggleProviderSelected(insurance.id)} >
                                {insurance.provider}
                            </Button>
                        </Col>
                    ))}
                </Row>
            </>
    );
};

InsuranceSelectorGrid.propTypes = {
    insuranceAgreements: insuranceAgreementsPropType.isRequired,
    currentProviders: insuranceAgreementsPropType.isRequired,
    updateCurrentProviders: funcPropType.isRequired,
}

const mapStateToProps = state => ({
    insuranceAgreements: state.clinicians.insuranceAgreements
})

export default connect(mapStateToProps, {})(InsuranceSelectorGrid);