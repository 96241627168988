import React  from 'react';
import { Row, Col } from 'react-bootstrap';
import Select, { StylesConfig } from 'react-select';


interface ViewOption {
    label: string;
    value: string;
}
  
interface ProfileTabsDropDownProps {
    viewName: string;
    viewOptions: ViewOption[];
    updateView: (value: string) => void;
}


const ProfileTabsDropDown: React.FC<ProfileTabsDropDownProps> = ({ viewName, viewOptions, updateView }) => {
    const customStyles: StylesConfig<ViewOption, false> = {
      singleValue: (provided) => ({
        ...provided,
        color: '#247986',
      }),
    };
  
    return (
      <Row className="mb-2 custom-dropdown">
        <Col>
          <Select<ViewOption>
            options={viewOptions}
            onChange={(opt) => opt && updateView(opt.value)}
            placeholder={viewName}
            styles={customStyles}
            className="custom-dropdown-select"
          />
        </Col>
      </Row>
    );
  };
  
  export default ProfileTabsDropDown;
