export const createActionTypes = ( base: string ) => ( {
    REQUEST: `${ base }_REQUEST`,
    SUCCESS: `${ base }_SUCCESS`,
    FAIL: `${ base }_FAIL`,
    RESET: `${ base }_RESET`,
} );

export type ActionStatus = {
    REQUEST: string;
    SUCCESS: string;
    FAIL: string;
    RESET: string;
};