import { useState, useEffect } from 'react';
import { useInsuranceAgreementService } from '@actions';

const useInsuranceSelectorGrid = (
	insuranceAgreements,
	currentAgreements,
	updateExisitingProviders
) => {
	const insuranceAgreementService = useInsuranceAgreementService();

	const handleGetInsuranceAgreementsList = async () => {
		await insuranceAgreementService.getInsuranceAgreements();
	};

	useEffect(() => {
		handleGetInsuranceAgreementsList();
	}, []);

	const [providers, setProviders] = useState([]);

	const initToggledList = () => {
		if (!insuranceAgreements || insuranceAgreements.length === 0) return;

		const newList = insuranceAgreements.map((provider) => {
			if (
				currentAgreements.filter((agreement) => agreement.id === provider.id)
					.length > 0
			) {
				return { ...provider, selected: true };
			}
			return { ...provider, selected: false };
		});
		setProviders(newList);
	};
	useEffect(() => initToggledList(), [insuranceAgreements]);

	const toggleProviderSelected = (id) => {
		if (!id) return;

		const updatedProviders = providers.map((provider) => {
			if (provider.id === id) {
				return { ...provider, selected: !provider.selected }; // Toggle selected and return a new object
			}
			return provider; // Return unmodified provider for those not matching the id
		});
		const updatedSelectedProviders = updatedProviders.filter(
			(provider) => provider.selected
		);
		setProviders(updatedProviders);
		updateExisitingProviders(updatedSelectedProviders);
	};

	const selectAllProviders = () => {
		const updatedProviders = providers.map((provider) => ({
			...provider,
			selected: true,
		}));
		setProviders(updatedProviders);
		updateExisitingProviders(updatedProviders);
	};

	return {
		providers,
		selectAllProviders,
		toggleProviderSelected,
	};
};

export default useInsuranceSelectorGrid;
