import React, { useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useAdminService } from '@actions';

const initFormData = {
	email: '',
	password: '',
	title: '',
	firstName: '',
	lastName: '',
};

function AddAminUserScreen() {
	const adminService = useAdminService();
	const isFullAdmin = useSelector((state) => state.auth.user.user.is_staff);

	const [formData, setFormData] = useState(initFormData);
	const updateFormData = (data) => setFormData({ ...formData, ...data });
	const { email, password, title, firstName, lastName } = formData;

	if (!isFullAdmin) {
		adminService.goToDashboardPage();
	}

	const onSubmit = async (e) => {
		e.preventDefault();
		const success = await adminService.addNewAdminUser({
			email,
			password,
			title,
			firstName,
			lastName,
		});
		if (success) adminService.goToDashboardPage();
	};

	return (
		<>
			<h1>Add new admin user</h1>
			<Form className="text-left" onSubmit={onSubmit}>
				<Form.Group controlId="title">
					<Form.Label className="my-0">Title *</Form.Label>
					<Form.Control
						required
						type="name"
						placeholder="Enter title"
						name="title"
						value={title}
						onChange={(e) =>
							updateFormData({ [e.target.name]: e.target.value })
						}
					/>
				</Form.Group>
				<Form.Group controlId="firstName">
					<Form.Label className="my-0">First name *</Form.Label>
					<Form.Control
						required
						type="name"
						placeholder="Enter first name"
						name="firstName"
						value={firstName}
						onChange={(e) =>
							updateFormData({ [e.target.name]: e.target.value })
						}
					/>
				</Form.Group>
				<Form.Group controlId="lastName">
					<Form.Label className="my-0">Last name *</Form.Label>
					<Form.Control
						required
						type="name"
						placeholder="Enter last name"
						name="lastName"
						value={lastName}
						onChange={(e) =>
							updateFormData({ [e.target.name]: e.target.value })
						}
					/>
				</Form.Group>
				<Form.Group controlId="email">
					<Form.Label className="my-0">Email *</Form.Label>
					<Form.Control
						required
						type="name"
						placeholder="Enter email"
						name="email"
						value={email}
						onChange={(e) =>
							updateFormData({ [e.target.name]: e.target.value })
						}
					/>
				</Form.Group>
				<Form.Group controlId="password">
					<Form.Label className="my-0">Password *</Form.Label>
					<Form.Control
						required
						type="name"
						placeholder="Enter pasword"
						name="password"
						value={password}
						onChange={(e) =>
							updateFormData({ [e.target.name]: e.target.value })
						}
					/>
				</Form.Group>
				<Row className="text-center mt-4">
					<Col>
						<Button className="sel-button w80" variant="primary" type="submit">
							Create new admin account
						</Button>
					</Col>
				</Row>
			</Form>
		</>
	);
}

export default AddAminUserScreen;
