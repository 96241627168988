import * as CONSTS from '@constants/authConstants';

const initialState = {
	loading: false,
	error: '',
	isAuthenticated: false,
	isAdmin: false,
	user: {
		id: null,
		profile_picture: '',
		title: '',
		firstName: '',
		lastName: '',
		jobRole: [],
	},
	token: '',
	delegateRegistration: {
		delegate: {},
		clinician: {},
	},
	version: '',
};

// eslint-disable-next-line default-param-last
export default function auth(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case CONSTS.APP_VERISON_ACTION.REQUEST:
		case CONSTS.REGISTER_ACTION.REQUEST:
		case CONSTS.VERIFY_EMAIL_ACTION.REQUEST:
		case CONSTS.LOGIN_ACTION.REQUEST:
		case CONSTS.CHECK_AUTH_ACTION.REQUEST:
		case CONSTS.LOGOUT_ACTION.REQUEST:
		case CONSTS.RESET_PASSWORD_ACTION.REQUEST:
		case CONSTS.REGISTER_DELEGATE_INFO_ACTION.REQUEST:
		case CONSTS.RESET_PASSWORD_SET_NEW_ACTION.REQUEST:
			return {
				...state,
				loading: true,
			};
		case CONSTS.APP_VERISON_ACTION.SUCCESS:
		case CONSTS.REGISTER_ACTION.SUCCESS:
		case CONSTS.VERIFY_EMAIL_ACTION.SUCCESS:
		case CONSTS.RESET_PASSWORD_ACTION.SUCCESS:
		case CONSTS.RESET_PASSWORD_SET_NEW_ACTION.SUCCESS:
		case CONSTS.LOGIN_ACTION.SUCCESS:
		case CONSTS.CHECK_AUTH_ACTION.SUCCESS:
		// case CONSTS.AUTH_FROM_STORAGE:
		case CONSTS.REGISTER_DELEGATE_INFO_ACTION.SUCCESS:
			return {
				...state,
				...payload,
				loading: false,
			};
		case CONSTS.APP_VERISON_ACTION.FAIL:
		case CONSTS.REGISTER_DELEGATE_INFO_ACTION.FAIL:
		case CONSTS.REGISTER_ACTION.FAIL:
		case CONSTS.VERIFY_EMAIL_ACTION.FAIL:
		case CONSTS.LOGIN_ACTION.FAIL:
		case CONSTS.CHECK_AUTH_ACTION.FAIL:
		case CONSTS.LOGOUT_ACTION.FAIL:
		case CONSTS.RESET_PASSWORD_ACTION.FAIL:
		case CONSTS.RESET_PASSWORD_SET_NEW_ACTION.FAIL:
			return {
				...state,
				error: payload,
			};
		case CONSTS.LOGOUT_ACTION.SUCCESS:
			return { ...initialState };
		default:
			return state;
	}
}
