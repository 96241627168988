import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import {
	boolPropType,
	funcPropType,
	numberPropType,
} from '@constants/propTypes';
import { useClinicianService } from '@actions';

function ProfileRemoveClinic({
	userProfileId,
	clinicId,
	showPopup,
	handleClose,
}) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const clinicianService = useClinicianService();

	const handleRequestRemoveCrp = async () => {
		const reqSuccess = await clinicianService.postRequestRemoveCrp({
			clinicId,
			userProfileId,
		});
		if (!reqSuccess) return;
		handleClose();
	};

	const onClick = () => handleRequestRemoveCrp();

	return (
		<Modal show={showPopup} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title className="medr-inv">Request to remove clinic</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>Are you sure you want to remove this clinic?</p>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="danger" onClick={onClick}>
					Remove Clinic
				</Button>
				<Button variant="info" onClick={handleClose}>
					Cancel
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

ProfileRemoveClinic.propTypes = {
	userProfileId: numberPropType.isRequired,
	clinicId: numberPropType.isRequired,
	showPopup: boolPropType.isRequired,
	handleClose: funcPropType.isRequired,
};

export default ProfileRemoveClinic;

export function MoreProfilesRequired({ showPopup, handleClose }) {
	return (
		<Modal show={showPopup} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title className="medr-inv">Request to remove clinic</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>
					You must be associated with at least one clinic. Please add your
					current clinic before removing this one.
				</p>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="info" onClick={handleClose}>
					Cancel
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

MoreProfilesRequired.propTypes = {
	showPopup: boolPropType.isRequired,
	handleClose: funcPropType.isRequired,
};
