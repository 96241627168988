/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ListCliniciansRequest } from '../models/ListCliniciansRequest';
import type { ListCliniciansResponse } from '../models/ListCliniciansResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ClinicianListService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Returns a list of clinicians, distance of each clinician is calculated using the serach GPS coordinates.
     * @param requestBody
     * @returns ListCliniciansResponse
     * @throws ApiError
     */
    public clinicianListCreate(
        requestBody: ListCliniciansRequest,
    ): CancelablePromise<ListCliniciansResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/clinician-list/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
