/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GpsFromPostcodeRequest } from '../models/GpsFromPostcodeRequest';
import type { GpsFromPostcodeResponse } from '../models/GpsFromPostcodeResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class GetGpsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get GPS coordinates from a given postcode.
     *
     * This endpoint takes a POST request with JSON data containing a 'postCode' field.
     * It uses the provided postcode to fetch GPS coordinates using a utility class.
     *
     * Parameters:
     * - `postCode` (str): The postcode for which GPS coordinates are requested.
     *
     * Example POST data:
     * ```
     * {
         * "postCode": "12345"
         * }
         * ```
         *
         * Responses:
         * - 200 OK: Returns the GPS coordinates in the response data.
         * - 400 Bad Request: Indicates a validation error if the 'postCode' field is missing.
         * - 500 Internal Server Error: Indicates an error during GPS coordinate retrieval.
         *
         * Returns:
         * A JSON response containing the GPS coordinates.
         *
         * Example response data:
         * ```
         * {
             * "latitude": 123.456,
             * "longitude": -78.901
             * }
             * ```
             *
             * Note:
             * - This endpoint is accessible to any user (no authentication required).
             * - Ensure the utility class `GPSFromAddress` is properly implemented and available.
             * @param requestBody
             * @returns GpsFromPostcodeResponse
             * @throws ApiError
             */
            public getGpsCreate(
                requestBody: GpsFromPostcodeRequest,
            ): CancelablePromise<GpsFromPostcodeResponse> {
                return this.httpRequest.request({
                    method: 'POST',
                    url: '/api/v1/get-gps/',
                    body: requestBody,
                    mediaType: 'application/json',
                });
            }
        }
