import React, { useEffect } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import InsuranceAgreementSearchBar from '../clinicians/InsuranceSearchBox/InsuranceAgreementSearchBar';
import GenderSearchBar from '../user/GenderSearchBar';
import TitleDropdown from '../user/TitleDropdown/TitleDropdown';
import { EmailFormField, PhoneFormField } from '../app/parts/FormFields';
import usePatientEntryForm from '@hooks/patients/usePatientEntryForm';
import { usePatientService } from '@actions';

const RequiredAsterisk = <span className="medr-required-asterisk">*</span>;

function PatientEntryForm({
	inputPatientId = null,
	isSuccessful = null,
	sendPatientId = null,
}) {
	const patientService = usePatientService();

	const handleGetPatientById = async (pxId) => {
		if (pxId === null) {
			patientService.resetCurrentPatient();
		} else {
			await patientService.getPatientById(pxId);
		}
	};

	useEffect(() => {
		handleGetPatientById(inputPatientId);
		return () => {};
	}, [inputPatientId]);

	const {
		patientFormData,
		isNewPatient,
		setIsNewPatient,
		resetFormData,
		loadPatientData,
		updatePatientAddress,
		updatePatientFormData,
		updatePatientConsent,
		setDOB,
		gpsError,
		isDOBValid,
		formChecks,
		formValid,
		getRequestData,
	} = usePatientEntryForm();

	useEffect(() => {
		loadPatientData(patientService.currentPatient);
		if (
			!patientService.currentPatient ||
			!patientService.currentPatient.id ||
			patientService.currentPatient.id === 0
		) {
			setIsNewPatient(true);
			return;
		}
		setIsNewPatient(false);
	}, [patientService.currentPatient]);

	const {
		title,
		firstName,
		lastName,
		patientReference,
		dob,
		sex,
		primaryEmail,
		primaryPhoneNumber,
		address,
		consent,
		insuranceProviderId,
	} = patientFormData;

	const { firstLine, city, postcode } = address;

	const updateAddress = (e) =>
		updatePatientAddress({ [e.target.name]: e.target.value });
	const handleConsentChange = () => updatePatientConsent(!consent);

	const handleCreatePatient = async (formData) => {
		const result = await patientService.postCreatePatient(formData);
		isSuccessful(result);
	};

	const handleUpdatePatient = async (pxId, formData) => {
		const result = await patientService.updatePatientById(pxId, formData);
		isSuccessful(result);
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		if (!consent || !formValid) return;

		const requestFormData = getRequestData();
		if (isNewPatient) {
			handleCreatePatient(requestFormData);
			resetFormData();
		} else {
			const pxId = parseInt(inputPatientId, 10);
			handleUpdatePatient(pxId, requestFormData);
		}
	};

	return (
		<Form className="text-left">
			<small className="medr-text">
				Required: * indicates mandatory fields.
			</small>
			<div className="row">
				<div className="col-md-4 py-1">
					<Form.Group controlId="title">
						<Form.Label className="my-0">Title {RequiredAsterisk}</Form.Label>
						<TitleDropdown title={title} sendTitle={updatePatientFormData} />
						{!formChecks.title ? (
							<p className="medr-invalid-text">Please select a title</p>
						) : null}
					</Form.Group>
				</div>
				<div className="col-md-8 py-1">
					<Form.Group controlId="firstName">
						<Form.Label className="my-0">
							First name {RequiredAsterisk}
						</Form.Label>
						<Form.Control
							required
							type="name"
							placeholder="Enter first name"
							name="firstName"
							value={firstName}
							onChange={(e) =>
								updatePatientFormData({ [e.target.name]: e.target.value })
							}
							isInvalid={!formChecks.firstName}
						/>
						<Form.Control.Feedback type="invalid">
							Please enter a first name.
						</Form.Control.Feedback>
					</Form.Group>
				</div>
			</div>
			<div className="row">
				<div className="col-md-4 py-1">
					<Form.Group controlId="gender">
						<Form.Label className="my-0">Sex {RequiredAsterisk}</Form.Label>
						<GenderSearchBar gender={sex} changeValue={updatePatientFormData} />
					</Form.Group>
					{!formChecks.sex ? (
						<p className="medr-invalid-text">Please select a sex</p>
					) : null}
				</div>
				<div className="col-md-8 py-1">
					<Form.Group controlId="lastName" className="mb-3">
						<Form.Label className="my-0">
							Last name {RequiredAsterisk}
						</Form.Label>
						<Form.Control
							required
							type="name"
							placeholder="Enter last name"
							name="lastName"
							value={lastName}
							onChange={(e) =>
								updatePatientFormData({ [e.target.name]: e.target.value })
							}
							isInvalid={!formChecks.lastName}
						/>
						<Form.Control.Feedback type="invalid">
							Please enter a last name.
						</Form.Control.Feedback>
					</Form.Group>
				</div>
			</div>
			<div className="row">
				<div
					className="col-md-4 py-1"
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'flex-end',
					}}
				>
					<Form.Group controlId="dob" className="mb-3">
						<Form.Label className="my-0">DOB {RequiredAsterisk}</Form.Label>
						<Form.Control
							required
							type="date"
							name="dob"
							value={dob}
							onChange={(e) => setDOB(e)}
							isInvalid={!isDOBValid || !formChecks.dob}
						/>
						<Form.Control.Feedback type="invalid">
							{!isDOBValid
								? 'Check patient is over 18 years old.'
								: 'Please enter a date of birth.'}
						</Form.Control.Feedback>
					</Form.Group>
				</div>
				<div className="col-md-8 py-1">
					<Form.Group controlId="patientReference" className="mb-3">
						<Form.Label className="my-0">Patient Reference</Form.Label>
						<Form.Control
							type="name"
							placeholder="Enter patient reference"
							name="patientReference"
							value={patientReference}
							onChange={(e) =>
								updatePatientFormData({ [e.target.name]: e.target.value })
							}
						/>
					</Form.Group>
				</div>
			</div>

			<div className="mb-3">
				<EmailFormField
					email={primaryEmail}
					emailLabel={`Patient Email *`}
					emailKey="primaryEmail"
					changeValues={updatePatientFormData}
				/>
				{!formChecks.email ? (
					<p className="medr-invalid-text">Please enter email</p>
				) : null}
			</div>
			<div className="mb-3">
				<PhoneFormField
					currentPhoneNo={primaryPhoneNumber}
					nameKey="primaryPhoneNumber"
					nameLabel={`Phone number *`}
					changeValues={updatePatientFormData}
				/>
				{!formChecks.phone ? (
					<p className="medr-invalid-text">Please enter phone number</p>
				) : null}
			</div>

			<Form.Group controlId="firstLine" className="mb-3">
				<Form.Label className="my-0">Address First Line</Form.Label>
				<Form.Control
					type="name"
					placeholder="Enter first line of address"
					name="firstLine"
					value={firstLine}
					onChange={updateAddress}
				/>
			</Form.Group>

			<Form.Group controlId="city" className="mb-3">
				<Form.Label className="my-0">Address City</Form.Label>
				<Form.Control
					type="name"
					placeholder="Enter city/town"
					name="city"
					value={city}
					onChange={updateAddress}
				/>
			</Form.Group>

			<div className="row mb-3">
				<div className="col-md-6">
					<Form.Group controlId="postcode">
						<Form.Label className="my-0">
							Address Postcode {RequiredAsterisk}
						</Form.Label>
						<Form.Control
							required
							type="name"
							placeholder="Enter postcode"
							name="postcode"
							value={postcode}
							onChange={updateAddress}
							isInvalid={!formChecks.postcode || gpsError}
						/>
						<Form.Control.Feedback type="invalid">
							{gpsError ? 'Address not found' : 'Please enter a postcode.'}
						</Form.Control.Feedback>
					</Form.Group>
				</div>
				<div className="col-md-6">
					<Form.Group controlId="insurance_provider">
						<Form.Label className="my-0">
							Insurance provider {RequiredAsterisk}
						</Form.Label>
						<InsuranceAgreementSearchBar
							agreement={insuranceProviderId}
							changeValues={updatePatientFormData}
						/>
					</Form.Group>
					{!formChecks.insuranceProviderId ? (
						<p className="medr-invalid-text">Please enter insurance status</p>
					) : null}
				</div>
			</div>

			<Row className="text-left mt-4">
				<Col className="my-0">
					<Form.Group controlId="consent" className="mb-3">
						<Form.Label className="my-0 custom-checkbox w100 text-left">
							<Form.Check className="custom-hidden-checkbox">
								<input
									type="checkbox"
									data-testid="consent-checkbox"
									id="consent"
									checked={consent}
									onChange={handleConsentChange}
								/>
								<div className="d-flex align-items-center">
									<i className="fa-regular fa-square-check big-checkbox custom-unchecked" />
									<i className="fa-solid fa-square-check big-checkbox custom-checked" />
									Please confirm patient has given consent
								</div>
							</Form.Check>
						</Form.Label>
					</Form.Group>
				</Col>
			</Row>
			{!formChecks.consent ? (
				<p className="medr-invalid-text">
					Please confirm patient has given consent
				</p>
			) : null}

			<Row className="text-center mt-4">
				<Col>
					<Button
						className="sel-button w80"
						variant="primary"
						onClick={onSubmit}
						disabled={!consent}
					>
						{isNewPatient ? 'Add Patient' : 'Update Patient'}
					</Button>
				</Col>
			</Row>
		</Form>
	);
}

PatientEntryForm.propTypes = {
	isSuccessful: PropTypes.func,
	inputPatientId: PropTypes.number,
};

export default PatientEntryForm;
