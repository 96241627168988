import { Dispatch } from 'redux';
import { NavigateFunction } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as CONSTS from '../constants/clinicianConstants';
import { checkIsAuthenticated, redirectIfInvalidToken } from './authService';
import { getAnyApiClient, getAuthApiClient } from './apiActions';
import { JobRoleListResponse, JobRoleByIdResponse } from '../client';


type ApiClient = ReturnType<typeof getAnyApiClient>;
type ApiAuthClient = ReturnType<typeof getAuthApiClient>;

interface JobRole {
    id: number;
    specialty: string;
    subSpecialty: string;
    jobRole: string;
    registrationBody: string;
    jobTitle: string;
    isVerified: boolean;
    isDoctor: boolean;
}

export class JobRoleService {
    private dispatch: Dispatch;
    private navigate: NavigateFunction;
    private appAnyClient: ApiClient;
    private appAuthClient: ApiAuthClient;
    private isAuth: boolean;
    public jobRoles: JobRole[];

    constructor ( dispatch: Dispatch, navigate: NavigateFunction ) {
        this.dispatch = dispatch;
        this.navigate = navigate;

        this.appAnyClient = getAnyApiClient();
        this.isAuth = checkIsAuthenticated();
        if ( this.isAuth ) this.appAuthClient = getAuthApiClient();
        this.jobRoles = useSelector( ( state ) => state.clinicians.jobRoles );
    }

    async getJobRoles(): Promise<boolean> {
        this.dispatch( { type: CONSTS.JOB_ROLE_LIST_ACTION.REQUEST } );
        let data: JobRoleListResponse;
        try {
            if ( !this.isAuth ) {
                data = await this.appAnyClient.jobRoles.jobRolesList();
            } else {
                data = await this.appAuthClient.jobRoles.jobRolesList();
            }
            this.dispatch( { type: CONSTS.JOB_ROLE_LIST_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: CONSTS.JOB_ROLE_LIST_ACTION.FAIL } );
            return false;
        }
    }

    async getFilteredJobRoles(): Promise<boolean> {
        this.dispatch( { type: CONSTS.JOB_ROLE_LIST_ACTION.REQUEST } );
        let data: JobRoleListResponse;
        try {
            if ( !this.isAuth ) {
                data = await this.appAnyClient.jobRoles.jobRolesFilteredRetrieve();
            } else {
                data = await this.appAuthClient.jobRoles.jobRolesFilteredRetrieve();
            }
            this.dispatch( { type: CONSTS.JOB_ROLE_LIST_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: CONSTS.JOB_ROLE_LIST_ACTION.FAIL } );
            return false;
        }
    }

    async getJobRoleById( id: number ): Promise<boolean> {
        this.dispatch( { type: CONSTS.JOB_ROLE_BY_ID_ACTION.REQUEST } );
        let data: JobRoleByIdResponse;
        try {
            if ( !this.isAuth ) {
                data = await this.appAnyClient.jobRoles.jobRolesRetrieve( id );
            } else {
                data = await this.appAuthClient.jobRoles.jobRolesRetrieve( id );
            }
            this.dispatch( { type: CONSTS.JOB_ROLE_BY_ID_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: CONSTS.JOB_ROLE_BY_ID_ACTION.FAIL } );
            return false;
        }
    }

}