import React, { useState, useEffect } from 'react';
import { Form, Button, Row } from 'react-bootstrap';
import { useNavigate, Navigate, useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { isAuthenticatedProptype } from '@constants/propTypes';
import PasswordField from '@components/app/parts/PasswordField';
import { useAuthService } from '@actions';

function SendResetPassswordScreen({ isAuthenticated }) {
	if (isAuthenticated) return <Navigate to="/dashboard" />;

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const authService = useAuthService();

	const { userHash, timeHash, verificationCode } = useParams();

	const [formData, setFormData] = useState({
		userHash,
		timeHash,
		verificationCode,
		password: '',
		rePassword: '',
	});

	const [passwordsMatch, setPasswordsMatch] = useState(false);
	const { password, rePassword } = formData;

	useEffect(() => {
		setPasswordsMatch(formData.password === formData.rePassword);
	}, [formData.password, formData.rePassword]);

	const updateChanges = (newData) => {
		setFormData({ ...formData, ...newData });
	};

	const isSuccess = (value) => (value === true ? navigate('/login') : null);

	const onSubmit = async (e) => {
		e.preventDefault();
		if (!passwordsMatch) return;
		const success = await authService.resetPasswordSetNew(formData);
		isSuccess(success);
	};

	return (
		<div
			className="medr-layout text-center dashboard-page"
			style={{ maxWidth: '380px', marginLeft: 'auto', marginRight: 'auto' }}
		>
			<h1>Set new password</h1>
			<Form className="text-left" onSubmit={(e) => onSubmit(e)}>
				<PasswordField
					controlId="password"
					label="Enter Password"
					password={password}
					keyLabel="password"
					changeValues={updateChanges}
				/>
				<PasswordField
					controlId="rePassword"
					label="Confirm Password"
					password={rePassword}
					keyLabel="rePassword"
					changeValues={updateChanges}
					isInvalid={!passwordsMatch}
					invalidString="Passwords do not match!"
				/>
				{/* <Form.Group className="py-2 text-left" controlId="password">
					<Form.Label>Password</Form.Label>
					<Form.Control
						type="password"
						placeholder="Enter Password"
						name="password"
						value={password}
						onChange={(e) => onChange(e)}
					/>
				</Form.Group> */}
				{/* <Form.Group className="py-2 text-left" controlId="rePassword">
					<Form.Label>Re-enter Password</Form.Label>
					<Form.Control
						type="password"
						placeholder="Enter Password"
						name="rePassword"
						value={rePassword}
						isInvalid={!passwordsMatch}
						onChange={(e) => onChange(e)}
					/>
					<Form.Control.Feedback type="invalid">
						Passwords do not match!
					</Form.Control.Feedback>
				</Form.Group> */}
				<Row className="mt-4 text-center">
					<div className="col-12">
						<Button type="submit" variant="primary" className="sel-button w80">
							Set password
						</Button>
					</div>
				</Row>
			</Form>
		</div>
	);
}

SendResetPassswordScreen.propTypes = {
	isAuthenticated: isAuthenticatedProptype.isRequired,
};

const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {})(SendResetPassswordScreen);
