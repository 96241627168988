import React, { useEffect, useState } from 'react';
import { Table, Tabs, Tab } from 'react-bootstrap';
import { connect } from 'react-redux';
import { referralsPropType } from '@constants/propTypes';
import { getNameString, reformatDateTime } from '@/actions/general';
import PaginationBar from '../app/PaginationBar';
import { useAdminService } from '@actions';
import Loader from '@components/app/Loader';

function AdminReferralTable({ open, pending, accepted, completed }) {
	const adminService = useAdminService();
	const nReferralsWindow = 18;
	const start = 0;
	const end = nReferralsWindow;

	const handelGetAdminReferrals = async () =>
		await adminService.getAdminReferralsList();
	useEffect(() => {
		handelGetAdminReferrals();
	}, []);

	const [openReferrals, setOpenReferrals] = useState([]);
	const [openReferralsLength, setOpenReferralsLength] = useState(0);
	const [openReferralsSlice, setOpenReferralsSlice] = useState({ start, end });

	const [pendingReferrals, setPendingReferrals] = useState([]);
	const [pendingReferralsLength, setPendingReferralsLength] = useState(0);
	const [pendingReferralsSlice, setPendingReferralsSlice] = useState({
		start,
		end,
	});

	const [acceptedReferrals, setAcceptedReferrals] = useState([]);
	const [acceptedReferralsLength, setAcceptedReferralsLength] = useState(0);
	const [acceptedReferralsSlice, setAcceptedReferralsSlice] = useState({
		start,
		end,
	});

	const [completedReferrals, setCompletedReferrals] = useState([]);
	const [completedReferralsLength, setCompletedReferralsLength] = useState(0);
	const [completedReferralsSlice, setCompletedReferralsSlice] = useState({
		start,
		end,
	});

	const updateOpenReferralsSlice = (startInd, endInd) =>
		setOpenReferralsSlice({ start: startInd, end: endInd });
	const updatePendingReferralsSlice = (startInd, endInd) =>
		setPendingReferralsSlice({ start: startInd, end: endInd });
	const updateAcceptedReferralsSlice = (startInd, endInd) =>
		setAcceptedReferralsSlice({ start: startInd, end: endInd });
	const updateCompletedReferralsSlice = (startInd, endInd) =>
		setCompletedReferralsSlice({ start: startInd, end: endInd });

	const updateOpenReferrals = () => {
		const openRx = adminService.getReferralsSlice(
			open,
			openReferralsSlice.start,
			openReferralsSlice.end
		);
		setOpenReferrals(openRx);
		setOpenReferralsLength(open.length);
	};

	const updatePendingReferrals = () => {
		const pendingRx = adminService.getReferralsSlice(
			pending,
			pendingReferralsSlice.start,
			pendingReferralsSlice.end
		);
		setPendingReferrals(pendingRx);
		setPendingReferralsLength(pending.length);
	};

	const updateAcceptedReferrals = () => {
		const acceptedRx = adminService.getReferralsSlice(
			accepted,
			acceptedReferralsSlice.start,
			acceptedReferralsSlice.end
		);
		setAcceptedReferrals(acceptedRx);
		setAcceptedReferralsLength(accepted.length);
	};

	const updateCompletedReferrals = () => {
		const completedRx = adminService.getReferralsSlice(
			completed,
			completedReferralsSlice.start,
			completedReferralsSlice.end
		);
		setCompletedReferrals(completedRx);
		setCompletedReferralsLength(completed.length);
	};

	useEffect(() => updateOpenReferrals(), [open, openReferralsSlice]);
	useEffect(() => updatePendingReferrals(), [pending, pendingReferralsSlice]);
	useEffect(
		() => updateAcceptedReferrals(),
		[accepted, acceptedReferralsSlice]
	);
	useEffect(
		() => updateCompletedReferrals(),
		[completed, completedReferralsSlice]
	);

	const noReferralsDisplay = (
		<div className="center-box-parent">
			<div className="center-box-child">
				<h4 className="medr-text-inv">No referrals to display at the moment</h4>
			</div>
		</div>
	);

	const openHeadersRow = (
		<tr>
			<th>Row</th>
			<th>Created On</th>
			<th>Px ID</th>
			<th>Referrer</th>
			<th>Description</th>
		</tr>
	);

	const openReferralsRows = openReferrals.map((referral, index) => (
		<tr key={referral.id}>
			<td>{index + 1}</td>
			<td>{reformatDateTime(referral.createdOn)}</td>
			<td>{referral.patient.id}</td>
			<td>
				{getNameString(referral.referrer.userProfile)} (
				{referral.referrer.userProfile.jobRole[0].jobTitle})
			</td>
			<td>{referral.mainDescription}</td>
		</tr>
	));
	const pendingHeadersRow = (
		<tr>
			<th>Row</th>
			<th>Created On</th>
			<th>Px ID</th>
			<th>Referrer</th>
			<th>Target Clinician</th>
		</tr>
	);

	const pendingReferralsRows = pendingReferrals.map((referral, index) => (
		<tr key={referral.id}>
			<td>{index + 1}</td>
			<td>{reformatDateTime(referral.createdOn)}</td>
			<td>{referral.patient.id}</td>
			<td>
				{getNameString(referral.referrer.userProfile)} (
				{referral.referrer.userProfile.jobRole[0].jobTitle})
			</td>
			<td>
				{getNameString(referral.targetClinician.userProfile)} (
				{referral.targetClinician.userProfile.jobRole[0].jobTitle})
			</td>
		</tr>
	));
	const acceptedHeadersRow = (
		<tr>
			<th>Row</th>
			<th>Created On</th>
			<th>Px ID</th>
			<th>Referrer</th>
			<th>Target Clinician</th>
		</tr>
	);
	const acceptedReferralsRows = acceptedReferrals.map((referral, index) => (
		<tr key={referral.id}>
			<td>{index + 1}</td>
			<td>{reformatDateTime(referral.createdOn)}</td>
			<td>{referral.patient.id}</td>
			<td>
				{getNameString(referral.referrer.userProfile)} (
				{referral.referrer.userProfile.jobRole[0].jobTitle})
			</td>
			<td>
				{getNameString(referral.targetClinician.userProfile)} (
				{referral.targetClinician.userProfile.jobRole[0].jobTitle})
			</td>
		</tr>
	));

	const completedHeadersRow = (
		<tr>
			<th>Row</th>
			<th>Created On</th>
			<th>Px ID</th>
			<th>Referrer</th>
			<th>Target Clinician</th>
		</tr>
	);
	const completedReferralsRows = completedReferrals.map((referral, index) => (
		<tr key={referral.id}>
			<td>{index + 1}</td>
			<td>{reformatDateTime(referral.createdOn)}</td>
			<td>{referral.patient.id}</td>
			<td>
				{getNameString(referral.referrer.userProfile)} (
				{referral.referrer.userProfile.jobRole[0].jobTitle})
			</td>
			<td>
				{getNameString(referral.targetClinician.userProfile)} (
				{referral.targetClinician.userProfile.jobRole[0].jobTitle})
			</td>
		</tr>
	));
	const loadingView = (
		<>
			<div className="gap mt-5" />
			<Loader text="Loading admin referrals list..." />
		</>
	);

	const isLoading = adminService.requestLoading;

	return (
		<Tabs
			defaultActiveKey="openReferrals"
			id="custom-tabs"
			className="custom-tabs mb-3"
		>
			<Tab
				eventKey="openReferrals"
				title="Open Referrals"
				className="custom-tabs"
			>
				{isLoading && loadingView}
				{!isLoading && openReferralsLength === 0 && noReferralsDisplay}
				{!isLoading && openReferralsLength > 0 && (
					<Table striped bordered hover responsive className="table-sm">
						<thead>{openHeadersRow}</thead>
						<tbody>{openReferralsRows}</tbody>
					</Table>
				)}
				{!isLoading && openReferralsLength > nReferralsWindow && (
					<div className="mb-3">
						<PaginationBar
							arrayLength={openReferralsLength}
							setSliceValues={updateOpenReferralsSlice}
							windowSize={nReferralsWindow}
						/>
					</div>
				)}
			</Tab>
			<Tab
				eventKey="pendingReferrals"
				title="Pending Referrals"
				className="custom-tabs"
			>
				{isLoading && loadingView}
				{!isLoading && pendingReferralsLength > 0 && (
					<Table striped bordered hover responsive className="table-sm">
						<thead>{pendingHeadersRow}</thead>
						<tbody>{pendingReferralsRows}</tbody>
					</Table>
				)}
				{!isLoading && pendingReferralsLength > nReferralsWindow && (
					<div className="mb-3">
						<PaginationBar
							arrayLength={pendingReferralsLength}
							setSliceValues={updatePendingReferralsSlice}
							windowSize={nReferralsWindow}
						/>
					</div>
				)}
			</Tab>
			<Tab
				eventKey="acceptedReferrals"
				title="Accepted Referrals"
				className="custom-tabs"
			>
				{isLoading && loadingView}
				{!isLoading && acceptedReferralsLength === 0 && noReferralsDisplay}
				{!isLoading && acceptedReferralsLength > 0 && (
					<Table striped bordered hover responsive className="table-sm">
						<thead>{acceptedHeadersRow}</thead>
						<tbody>{acceptedReferralsRows}</tbody>
					</Table>
				)}
				{!isLoading && acceptedReferralsLength > nReferralsWindow && (
					<div className="mb-3">
						<PaginationBar
							arrayLength={acceptedReferralsLength}
							setSliceValues={updateAcceptedReferralsSlice}
							windowSize={nReferralsWindow}
						/>
					</div>
				)}
			</Tab>
			<Tab
				eventKey="completedReferrals"
				title="Completed Referrals"
				className="custom-tabs"
			>
				{isLoading && loadingView}
				{!isLoading && completedReferralsLength === 0 && noReferralsDisplay}
				{!isLoading && completedReferralsLength > 0 && (
					<Table striped bordered hover responsive className="table-sm">
						<thead>{completedHeadersRow}</thead>
						<tbody>{completedReferralsRows}</tbody>
					</Table>
				)}
				{!isLoading && completedReferralsLength > nReferralsWindow && (
					<div className="mb-3">
						<PaginationBar
							arrayLength={completedReferralsLength}
							setSliceValues={updateCompletedReferralsSlice}
							windowSize={nReferralsWindow}
						/>
					</div>
				)}
			</Tab>
		</Tabs>
	);
}

AdminReferralTable.propTypes = {
	open: referralsPropType.isRequired,
	pending: referralsPropType.isRequired,
	accepted: referralsPropType.isRequired,
	completed: referralsPropType.isRequired,
};

const mapStateToProps = (state) => ({
	open: state.admin.referrals.open,
	pending: state.admin.referrals.pending,
	accepted: state.admin.referrals.accepted,
	completed: state.admin.referrals.completed,
});

export default connect(mapStateToProps, {})(AdminReferralTable);
