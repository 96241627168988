import React from 'react';
import PropTypes from 'prop-types';
import { Marker } from 'react-leaflet';
import './map.css';
import 'leaflet/dist/leaflet.css';
import useTargetMarker from './useTargetMarker';
import { defaultMapLat, defaultMapLng } from '@constants/variables';

function TargetMarker({ mapCenter }) {
	if (
		(mapCenter[0] === 0 && mapCenter[1] === 0) ||
		(mapCenter[0] === defaultMapLat && mapCenter[1] === defaultMapLng)
	) {
		return null;
	}

	const { geocode, customIcon } = useTargetMarker(mapCenter);

	return <Marker position={geocode} icon={customIcon} />;
}

TargetMarker.propTypes = {
	mapCenter: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default TargetMarker;
