import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAuthService } from '@actions';

function AdminRoutes() {
	const authService = useAuthService();

	if (!authService.hasAuthToken()) {
		return <Navigate to="/login" />;
	}
	const userGroup = useSelector((state) => state.auth.userGroup);
	const isAdmin = userGroup === 'Staff' || userGroup === 'Full Admin';
	if (!isAdmin) {
		console.log('Not an admin');
		return <Navigate to="/dashboard" />;
	}
	return <Outlet />;
}

export default AdminRoutes;
