import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { patientsPropType } from '@constants/propTypes';
import { getNameString, reformatDateTime } from '@/actions/general';
import PaginationBar from '../app/PaginationBar';
import { useAdminService } from '@actions';
import Loader from '@components/app/Loader';

function AdminPatientTable({ patientsList }) {
	const adminService = useAdminService();
	const nPatientsWindow = 18;
	const start = 0;
	const end = nPatientsWindow;
	const [sliceIndices, setSliceIndices] = useState({ start, end });
	const [patients, setPatients] = useState(null);
	const [patientsLength, setPatientsLength] = useState(0);

	const handleGetAdminPatients = async () =>
		await adminService.getAdminPatientsList();
	useEffect(() => {
		handleGetAdminPatients();
	}, []);

	const updateSliceIndices = (startInd, endInd) =>
		setSliceIndices({ start: startInd, end: endInd });
	const updatePatientsSlice = () => {
		if (!patientsList || patientsList.length === 0) {
			setPatients([]);
			return;
		}
		const slice = patientsList.slice(sliceIndices.start, sliceIndices.end);
		setPatients(slice);
		setPatientsLength(patientsList.length);
	};

	useEffect(() => updatePatientsSlice(), [patientsList, sliceIndices]);

	const noPatientsDisplay = (
		<div className="center-box-parent">
			<div className="center-box-child">
				<h4 className="medr-text-inv">No patients to display at the moment</h4>
			</div>
		</div>
	);

	const headersRow = (
		<tr>
			<th>Row</th>
			<th>Px Ref</th>
			<th>Created By</th>
			<th>Created At</th>
		</tr>
	);

	const patientsRows =
		patients &&
		patients.map((patient, index) => (
			<tr key={patient.id}>
				<td>{index + 1}</td>
				<td>{patient.patientReference}</td>
				<td>{getNameString(patient.createdBy)}</td>
				<td>{reformatDateTime(patient.createdOn)}</td>
			</tr>
		));

	if (adminService.requestLoading) {
		return (
			<>
				<div className="gap mt-5" />
				<Loader text="Loading admin patients list..." />
			</>
		);
	}

	return (
		<>
			{patientsLength === 0 && noPatientsDisplay}
			{patientsLength > 0 && (
				<Table striped bordered hover responsive className="table-sm">
					<thead>{headersRow}</thead>
					<tbody>{patientsRows}</tbody>
				</Table>
			)}
			{patientsLength > nPatientsWindow && (
				<PaginationBar
					arrayLength={patientsLength}
					setSliceValues={updateSliceIndices}
					windowSize={nPatientsWindow}
				/>
			)}
		</>
	);
}

AdminPatientTable.propTypes = {
	patientsList: patientsPropType.isRequired,
};

const mapStateToProps = (state) => ({
	patientsList: state.admin.patientsList,
});

export default connect(mapStateToProps, {})(AdminPatientTable);
