import { useState, useEffect } from 'react';
import { defaultMapLat, defaultMapLng } from '@/constants/variables';

const favouritesTabRef = 'favouriteClinicians';


type CurrentLocationResult = {
	coords: { latitude: number, longitude: number };
}

type LocationSearchResult = {
	loaded: boolean;
	coordinates: { lat: number, lng: number };
	mapCenter: [ number, number ];
	error?: any;
}

type LocationSearchResponse = {
	postcode: string;
	searchLatitude: number;
	searchLongitude: number;
}

const getLocationDataStructure = ( loaded: boolean, lat: number, lng: number ): LocationSearchResult => ( {
	loaded,
	coordinates: { lat, lng },
	mapCenter: [ lat, lng ],
	error: null,
} );


const errorLocation: LocationSearchResult = {
	loaded: true,
	coordinates: { lat: defaultMapLat, lng: defaultMapLng },
	mapCenter: [ defaultMapLat, defaultMapLng ],
	error: null,
};


export const useMapViewReferralEntry = ( patientLat: number, patientLng: number, handleShowFavourites: ( value: boolean ) => void ) => {
	const [ location, setLocation ] = useState( getLocationDataStructure( true, patientLat, patientLng ) );

	const updateShowOnlyFavourites = ( tabRef: string ) => {
		handleShowFavourites( tabRef === favouritesTabRef );
	};

	const receivePostcodeSearch = ( e: LocationSearchResponse ) => {
		setLocation( getLocationDataStructure( true, e.searchLatitude, e.searchLongitude ) );
	}

	return {
		updateShowOnlyFavourites,
		receivePostcodeSearch,
		location,
	};
};


export const useMapViewClinicianList = ( handleShowFavourites: ( value: boolean ) => void ): any => {
	const [ userLocation, setUserLocation ] = useState( getLocationDataStructure( false, 0, 0 ) );
	const [ searchedLocation, setSearchedLocation ] = useState( getLocationDataStructure( false, 0, 0 ) );
	const [ location, setLocation ] = useState( getLocationDataStructure( false, 0, 0 ) );

	const getCurrentUserLoactionPermissionSetting = async () => {
		return await navigator.permissions.query( { name: "geolocation" } ).then( ( result ) => {
			return result.state;
		} );
	}

	function requestGeolocation() {
		navigator.geolocation.getCurrentPosition(
			( position ) => {
				// Success callback
				onUserLocationSuccess( position );
			},
			( error ) => {
				// Error callback
				onUserLocationError( error );
			}
		);
	}

	const onUserLocationSuccess = ( currentLocation: CurrentLocationResult ) => {
		setUserLocation( getLocationDataStructure( true, currentLocation.coords.latitude, currentLocation.coords.longitude ) );
	};

	const onUserLocationError = ( error: any ) => {
		console.log( "Error getting user location", error );
		setUserLocation( errorLocation );
	}

	function handleDeniedPermission() {
		// Display a message to the user
		alert( "Enabling location access will provide a better user experience on medr.co.uk. Please go to your browser settings and enable location services to enjoy personalized features and services." );
		onUserLocationError( {} );
	}

	const handleCheckPermission = async () => {
		const refreshPermissions = [ "prompt", "granted" ];
		const permission = await getCurrentUserLoactionPermissionSetting();
		if ( refreshPermissions.includes( permission ) ) {
			requestGeolocation();
		} else if ( permission === "denied" ) {
			handleDeniedPermission();
		} else {
			console.log( "Permission not granted yet" );
			handleDeniedPermission();
		}
	}

	useEffect( () => {
		handleCheckPermission();
	}, [] );


	const updateShowOnlyFavourites = ( tabRef: string ) => {
		handleShowFavourites( tabRef === favouritesTabRef );
	};

	const receivePostcodeSearch = ( e: LocationSearchResponse ) => {
		setSearchedLocation( getLocationDataStructure( true, e.searchLatitude, e.searchLongitude ) );
	}

	const onSearchLocationChange = () => {
		if ( !userLocation.loaded || userLocation.error ) return;
		if ( searchedLocation.mapCenter[ 0 ] === location.mapCenter[ 0 ] && searchedLocation.mapCenter[ 1 ] === location.mapCenter[ 1 ] ) return;
		setLocation( searchedLocation );
	}

	const onUserLocationChange = () => {
		if ( !userLocation.loaded || userLocation.error ) return;
		if ( userLocation.mapCenter[ 0 ] === location.mapCenter[ 0 ] && userLocation.mapCenter[ 1 ] === userLocation.mapCenter[ 1 ] ) return;
		if ( searchedLocation.loaded && searchedLocation.mapCenter[ 0 ] === location.mapCenter[ 0 ] && searchedLocation.mapCenter[ 1 ] === location.mapCenter[ 1 ] ) return;
		setLocation( userLocation );
	}

	useEffect( () => onSearchLocationChange(), [ searchedLocation ] );
	useEffect( () => onUserLocationChange(), [ userLocation ] );

	return {
		updateShowOnlyFavourites,
		receivePostcodeSearch,
		location,
	};
};

