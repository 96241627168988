import { Dispatch } from 'redux';
import { NavigateFunction } from 'react-router-dom';
import * as CONSTS from '../constants/clinicianConstants';
import { getAnyApiClient } from './apiActions';
import { InsuranceAgreementListResponse } from '../client';


type ApiClient = ReturnType<typeof getAnyApiClient>;


export class InsuranceAgreementService {
    private dispatch: Dispatch;
    private navigate: NavigateFunction;
    private appAnyClient: ApiClient;

    constructor ( dispatch: Dispatch, navigate: NavigateFunction ) {
        this.dispatch = dispatch;
        this.navigate = navigate;
        this.appAnyClient = getAnyApiClient();
    }

    async getInsuranceAgreements(): Promise<boolean> {
        this.dispatch( { type: CONSTS.INSURANCE_AGREEMENT_LIST_ACTION.REQUEST } );
        let data: InsuranceAgreementListResponse;

        try {
            data = await this.appAnyClient.insuranceAgreements.insuranceAgreementsList();
            this.dispatch( { type: CONSTS.INSURANCE_AGREEMENT_LIST_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            this.dispatch( { type: CONSTS.INSURANCE_AGREEMENT_LIST_ACTION.FAIL } );
            return false;
        }
    }

}