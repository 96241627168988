import { useEffect } from 'react';
import { Dispatch } from 'redux';
import { NavigateFunction } from 'react-router-dom';
import Cookies from 'js-cookie';
import * as CONSTS from '../constants/authConstants';
import * as CONSTSUSR from '@/constants/userConstants';
import { getAnyApiClient, getAuthApiClient } from './apiActions';
import { MedrAppVersion, DeactivateUserAccountResponse, LoginRequest, LoginUserResponse, CheckRegisteringEmailRequest, RegisterUserRequest, CheckRegisteringEmailResponse, CheckClinicAddressRequest, CheckClinicAddressResponse, ResetUserPasswordSetNewRequest, UpdateUserPasswordRequest } from '../client';
import { ApiError } from '../client/core/ApiError';



export function unauthResponseClear(): void {
    Cookies.remove( 'authToken' );
    localStorage.clear();
}

interface redirectIfInvalidTokenInterface {
    error: ApiError;
    navigate: NavigateFunction;
    dispatch: Dispatch;
}

let alertShown = false;

export function redirectIfInvalidToken( { error, navigate, dispatch }: redirectIfInvalidTokenInterface ): void {
    if ( error.status !== 403 ) return;
    if ( error.body.detail !== 'Invalid token.' ) return;
    unauthResponseClear();
    navigate( '/login' );
    if ( !alertShown ) {
        alertShown = true;
        alert( 'Your session has expired. Please log in again.' );

        // Reset the flag after a short delay
        setTimeout( () => {
            alertShown = false;
        }, 5000 ); // 5 seconds delay
    }
}


export function checkIsAuthenticated(): boolean {
    const authToken = Cookies.get( 'authToken' );
    return authToken !== null && authToken !== undefined;
}

type ApiClient = ReturnType<typeof getAnyApiClient>;
type ApiAuthClient = ReturnType<typeof getAuthApiClient>;


export class AuthService {
    private dispatch: Dispatch;
    private navigate: NavigateFunction;
    private appAnyClient: ApiClient;
    private appAuthClient: ApiAuthClient;
    private TOKEN_EXPIRY_DAYS = 7;

    constructor ( dispatch: Dispatch, navigate: NavigateFunction ) {
        this.dispatch = dispatch;
        this.navigate = navigate;
        this.appAnyClient = getAnyApiClient();
        this.appAuthClient = getAuthApiClient();
    }

    async getAppVersion(): Promise<string> {
        this.dispatch( { type: CONSTS.APP_VERISON_ACTION.REQUEST } );
        try {
            const data: MedrAppVersion = await this.appAnyClient.appVersion.appVersionRetrieve();
            const { version } = data;
            this.dispatch( { type: CONSTS.APP_VERISON_ACTION.SUCCESS, payload: data } );
            return version
        } catch ( error ) {
            this.dispatch( { type: CONSTS.APP_VERISON_ACTION.FAIL } );
            return ""
        }
    }

    async checkAuth(): Promise<boolean> {
        this.dispatch( { type: CONSTS.CHECK_AUTH_ACTION.REQUEST } );

        try {
            const data: LoginUserResponse = await this.appAuthClient.user.userCheckAuthRetrieve();
            this.dispatch( {
                type: CONSTS.CHECK_AUTH_ACTION.SUCCESS,
                payload: data
            } );
            this.setAuthData( data );
            return true;
        } catch ( error ) {
            this.dispatch( { type: CONSTS.CHECK_AUTH_ACTION.FAIL } );
            return false;
        }
    };

    async login( username: string, password: string ): Promise<boolean> {
        const payload: LoginRequest = { username, password };

        this.dispatch( { type: CONSTS.LOGIN_ACTION.REQUEST } );

        try {
            const data: LoginUserResponse = await this.appAnyClient.login.loginCreate( payload );
            this.dispatch( {
                type: CONSTS.LOGIN_ACTION.SUCCESS,
                payload: data
            } );
            this.setAuthData( data );

            return true;
        } catch ( error ) {
            this.dispatch( { type: CONSTS.LOGIN_ACTION.FAIL } );
            return false;
        }
    }

    logout(): Promise<void> {
        this.dispatch( { type: CONSTS.LOGOUT_ACTION.REQUEST } );
        try {
            this.appAuthClient.logout.logoutCreate();
            this.unauthResponseClear();
            this.dispatch( { type: CONSTS.LOGOUT_ACTION.SUCCESS } );
        } catch ( error ) {
            this.dispatch( { type: CONSTS.LOGOUT_ACTION.FAIL } );
        }
        return;
    }

    async register( data: RegisterUserRequest ): Promise<boolean> {
        this.dispatch( { type: CONSTS.REGISTER_ACTION.REQUEST } );
        try {
            await this.appAnyClient.register.registerCreate( data );
            this.dispatch( { type: CONSTS.REGISTER_ACTION.SUCCESS } );
            return true;
        } catch ( error ) {
            this.dispatch( { type: CONSTS.REGISTER_ACTION.FAIL } );
            return false;
        }
    }

    async registerCheckEmail( registerData: CheckRegisteringEmailRequest ): Promise<boolean> {
        if ( registerData.email === '' ) return true;

        this.dispatch( { type: CONSTS.REGISTER_CHECK_EMAIL_ACTION.REQUEST } );

        try {
            const data: CheckRegisteringEmailResponse = await this.appAnyClient.register.registerCheckEmailCreate( registerData );
            const { emailValid } = data;
            this.dispatch( { type: CONSTS.REGISTER_CHECK_EMAIL_ACTION.SUCCESS } )
            return emailValid;
        } catch ( error ) {
            this.dispatch( { type: CONSTS.REGISTER_CHECK_EMAIL_ACTION.FAIL } );
            return false;
        }
    }

    async registerCheckClinic( registerData: CheckClinicAddressRequest ): Promise<boolean> {
        if ( registerData.postcode === '' ) return false;

        this.dispatch( { type: CONSTS.REGISTER_CHECK_CLINIC_ACTION.REQUEST } );

        try {
            const data: CheckClinicAddressResponse = await this.appAnyClient.register.registerCheckClinicCreate( registerData )
            const { addressValid } = data;
            this.dispatch( { type: CONSTS.REGISTER_CHECK_CLINIC_ACTION.SUCCESS } )
            return addressValid;
        } catch ( error ) {
            this.dispatch( { type: CONSTS.REGISTER_CHECK_CLINIC_ACTION.FAIL } );
            return false;
        }
    }

    async verifyUserEmail( code: string, time: string, user: string ): Promise<boolean> {
        this.dispatch( { type: CONSTS.VERIFY_EMAIL_ACTION.REQUEST } );
        try {
            await this.appAnyClient.register.registerVerifyEmailUserTimeCodeCreate( code, time, user );
            this.dispatch( { type: CONSTS.VERIFY_EMAIL_ACTION.SUCCESS } )
            return true;
        } catch ( error ) {

            this.dispatch( { type: CONSTS.VERIFY_EMAIL_ACTION.FAIL } );
            return false;
        }
    }

    async resetPassword( email: string ): Promise<boolean> {
        this.dispatch( { type: CONSTS.RESET_PASSWORD_ACTION.REQUEST } );
        try {
            await this.appAnyClient.user.userPasswordResetCreate( { email } );
            this.dispatch( { type: CONSTS.RESET_PASSWORD_ACTION.SUCCESS } );
            return true;
        } catch ( error ) {
            this.dispatch( { type: CONSTS.RESET_PASSWORD_ACTION.FAIL } );
            return false;
        }
    }

    async resetPasswordSetNew( data: ResetUserPasswordSetNewRequest ): Promise<boolean> {
        this.dispatch( { type: CONSTS.RESET_PASSWORD_SET_NEW_ACTION.REQUEST } );
        try {
            await this.appAnyClient.user.userPasswordResetSetNewCreate( data );
            this.dispatch( { type: CONSTS.RESET_PASSWORD_SET_NEW_ACTION.SUCCESS } );
            return true;
        } catch ( error ) {
            this.dispatch( { type: CONSTS.RESET_PASSWORD_SET_NEW_ACTION.FAIL } );
            return false;
        }
    }

    async sendResetPassword( data: UpdateUserPasswordRequest ): Promise<boolean> {
        this.dispatch( { type: CONSTSUSR.USER_UPDATE_PASSWORD_ACTION.REQUEST } );
        try {
            await this.appAuthClient.user.userPasswordUpdateUpdate( data );
            this.dispatch( { type: CONSTSUSR.USER_UPDATE_PASSWORD_ACTION.SUCCESS } );
            return true;
        } catch ( error ) {
            this.dispatch( { type: CONSTSUSR.USER_UPDATE_PASSWORD_ACTION.FAIL } );
            return false;
        }
    }

    async updateUserPassword( data: UpdateUserPasswordRequest ): Promise<boolean> {
        this.dispatch( { type: CONSTSUSR.USER_UPDATE_PASSWORD_ACTION.REQUEST } );
        try {
            await this.appAuthClient.user.userPasswordUpdateUpdate( data );
            this.dispatch( { type: CONSTSUSR.USER_UPDATE_PASSWORD_ACTION.SUCCESS } );
            return true;
        } catch ( error ) {
            this.dispatch( { type: CONSTSUSR.USER_UPDATE_PASSWORD_ACTION.FAIL } );
            return false;
        }
    }

    RedirectIfAuthDetailsAvailable(): void {
        const isAuthenticated = this.hasAuthToken();
        useEffect( () => {
            if ( isAuthenticated ) {
                this.goToDashboardPage();
            }
        }, [ isAuthenticated, this.navigate ] );
    }

    hasAuthToken(): boolean {
        const authToken = Cookies.get( 'authToken' );
        if ( !authToken || authToken === undefined || authToken === '' ) {
            return false;
        }
        return true;
    }

    private setAuthData( data: LoginUserResponse ): void {
        Cookies.set( 'authToken', data.token, { expires: this.TOKEN_EXPIRY_DAYS } );
    }

    private unauthResponseClear(): void {
        unauthResponseClear();
    }

    async deactivateAccount( userProfileId: number ): Promise<boolean> {
        this.dispatch( { type: CONSTS.DEACTIVATE_FULL_ACCOUNT_ACTION.REQUEST } );
        try {
            const data: DeactivateUserAccountResponse = await this.appAuthClient.user.userDeactivateAccountDestroy( userProfileId );
            this.dispatch( {
                type: CONSTS.DEACTIVATE_FULL_ACCOUNT_ACTION.SUCCESS, payload: data
            } );
            this.unauthResponseClear();
            return true;
        } catch ( error ) {
            this.dispatch( { type: CONSTS.DEACTIVATE_FULL_ACCOUNT_ACTION.FAIL } );
            return false;
        }
    };

    async sendErrorNotification( errorDescription: string ): Promise<boolean> {
        this.dispatch( { type: CONSTS.SEND_ERROR_NOTIFICATION_ACTION.REQUEST } );
        try {
            const data: DeactivateUserAccountResponse = await this.appAuthClient.errorNotification.errorNotificationCreate( { errorDescription } );
            this.dispatch( {
                type: CONSTS.SEND_ERROR_NOTIFICATION_ACTION.SUCCESS
            } );
            return true;
        } catch ( error ) {
            this.dispatch( { type: CONSTS.SEND_ERROR_NOTIFICATION_ACTION.FAIL } );
            return false;
        }
    };

    goToLandingPage(): void {
        this.navigate( '/' );
    }
    goToDashboardPage(): void {
        this.navigate( '/dashboard' );
    }
    goToLoginPage(): void {
        this.navigate( '/login' );
    }
}