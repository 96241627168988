import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import JobRoleSearchBar from '@components/clinicians/JobRoleSearchBar';
import InsuranceAgreementSearchBar from '@components/clinicians/InsuranceSearchBox/InsuranceAgreementSearchBar';
import useFilteredClinicians from '@hooks/clinicians/useFilteredClinicians';
import useCliniciansList from '@hooks/clinicians/useCliniciansList';
import ClinicianFilterInput from '@components/clinicians/ClinicianFilterInput';
import MapView from '@features/map/MapView';
import { useClinicianService } from '@actions';
import { MapViewContext } from '@features/map/MapViewContext';
import { useMapViewClinicianList } from '@features/map/useMapView';

function CliniciansListScreen() {
	const navigate = useNavigate();
	const clinicianService = useClinicianService();

	const { filterFields, updateFilterFields } = useFilteredClinicians(0);
	const {
		clinicianList,
		clinicAddresses,
		setFilterString,
		toggleShowOnlyFavourites,
		hoveredClinicianUserProfileIds,
		hoveredAddressIds,
		updateHovered,
		updateMapViewCoords,
	} = useCliniciansList();

	const {
		jobRoleId,
		insuranceProviderId,
		searchLatitude,
		searchLongitude,
		jobRoleSelected,
		jobSpecialtySelected,
	} = filterFields;

	const handleRefreshCliniciansList = async () => {
		await clinicianService.getCliniciansList({
			roleId: jobRoleId,
			lat: searchLatitude,
			lng: searchLongitude,
			insuranceProviderId,
			jobRoleSelected,
			jobSpecialtySelected,
		});
	};

	useEffect(() => {
		handleRefreshCliniciansList();
	}, [
		jobRoleId,
		insuranceProviderId,
		searchLatitude,
		searchLongitude,
		jobRoleSelected,
		jobSpecialtySelected,
	]);

	const onClinicianSelect = (id) => {
		const clin = clinicianList.find((clinician) => clinician.id === id);
		if (!clin) return;
		const upId = clin.userProfile.id;
		navigate(`/clinicians/user/${upId}`);
	};

	const { updateShowOnlyFavourites, receivePostcodeSearch, location } =
		useMapViewClinicianList(toggleShowOnlyFavourites);

	const mapViewContextValues = {
		clinicians: clinicianList,
		refreshClinicians: handleRefreshCliniciansList,
		showReferralButton: true,
		hoveredClinicianUserProfileIds,
		updateHovered,
		hoveredAddressIds,
		clinics: clinicAddresses,
		handleShowFavourites: toggleShowOnlyFavourites,
		onClinicianSelect: onClinicianSelect,
		updateMapViewCoords: updateMapViewCoords,
		updateShowOnlyFavourites,
		receivePostcodeSearch,
		location,
	};

	return (
		<>
			<Row className="py-1">
				<Col className="text-center">
					<h1>Search our clinicians</h1>
				</Col>
			</Row>
			<div className="container-fluid">
				<Row className="mb-4 search-clinicians-page-row1">
					<Col sm={12} md={6}>
						<JobRoleSearchBar changeValues={updateFilterFields} filtered />
					</Col>
					<Col sm={12} md={6}>
						<InsuranceAgreementSearchBar changeValues={updateFilterFields} />
						<ClinicianFilterInput setFilterString={setFilterString} />
					</Col>
				</Row>
			</div>
			<MapViewContext.Provider value={mapViewContextValues}>
				<MapView />
			</MapViewContext.Provider>
		</>
	);
}

export default CliniciansListScreen;
