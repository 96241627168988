import React, { useState } from 'react';
import { Form, Button, Row, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { isAuthenticatedProptype } from '@constants/propTypes';
import { useAuthService } from '@actions';

function SendResetPassswordScreen({ isAuthenticated }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const authService = useAuthService();

	if (isAuthenticated) return navigate('/dashboard'); // <Navigate to="/dashboard" />;

	const [formData, setFormData] = useState({
		email: '',
	});

	const { email } = formData;

	const onChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const onSubmit = async (event) => {
		event.preventDefault();
		const resetSuccess = await authService.resetPassword(email);
		if (resetSuccess) navigate('/user/password-reset-success');
	};

	return (
		<div
			className="medr-layout text-center reset-password-page"
			style={{ maxWidth: '380px', marginLeft: 'auto', marginRight: 'auto' }}
		>
			<h1>Reset password</h1>
			<Form onSubmit={(e) => onSubmit(e)}>
				<Form.Group className="py-2 text-left" controlId="email">
					<Form.Label>Email address</Form.Label>
					<Form.Control
						type="email"
						placeholder="Enter Email"
						name="email"
						value={email}
						onChange={(e) => onChange(e)}
					/>
				</Form.Group>
				<Row className="mt-5">
					<div className="col-12">
						<Button type="submit" variant="primary" className="sel-button w80">
							Send password reset email
						</Button>
					</div>
				</Row>
			</Form>
		</div>
	);
}

SendResetPassswordScreen.propTypes = {
	isAuthenticated: isAuthenticatedProptype.isRequired,
};

const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {})(SendResetPassswordScreen);
