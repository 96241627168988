import React from 'react';
import AdminCliniciansTable from '../../components/admin/AdminCliniciansTable';

function AdminCliniciansListScreen() {
	return (
		<>
			<h1>Clinicians</h1>
			<AdminCliniciansTable />
		</>
	);
}

export default AdminCliniciansListScreen;
