import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useAuthService } from '@actions';

function AuthenticatedRoutes() {
	const authService = useAuthService();

	if (!authService.hasAuthToken()) {
		return <Navigate to="/login" />;
	}
	return <Outlet />;
}

export default AuthenticatedRoutes;
