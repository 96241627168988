import React from 'react';
import { Card } from 'react-bootstrap';

function MissingInfoBox({ title, subtitle = null }) {
	return (
		<Card className="mb-2 rounded d-flex flex-fill" style={{ padding: '1rem' }}>
			<Card.Title>{title}</Card.Title>
			{subtitle && (
				<Card.Subtitle className="text-muted">{subtitle}</Card.Subtitle>
			)}
		</Card>
	);
}

export default MissingInfoBox;
