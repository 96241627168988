import React, { useEffect } from "react";
import { Row, Col } from 'react-bootstrap';
import PatientSearchBar from "../../patient/PatientSearchBar";
import PatientEntryForm from "../../patient/PatientEntryForm";
import { useReferralEntryContext } from '@contexts/ReferralEntryContext';


function ReferralStepPatient(){
    useEffect(() => {window.scroll(0,0)}, []);

    const { patientId, patientSelected, sendPxId, addNewPatientSuccess } = useReferralEntryContext();

    return (
		<div className='medr-layout text-center newpatient-page' style={{maxWidth:'700px', marginLeft: 'auto', marginRight: 'auto'}}>
            <Row>
                <Col>
                    <h4>Select patient to refer</h4>
                </Col>
            </Row>
            
            <PatientSearchBar currentPatientId={patientId} sendPxId={sendPxId}/>

            <Row className='mt-5'>
                <Col>
                    <h4>{patientSelected===false ? "Or add new patient" : "Update selected patient"}</h4>
                </Col>
            </Row>
            <PatientEntryForm inputPatientId={patientId} isSuccessful={addNewPatientSuccess} />
        </div>

    )
};

export default ReferralStepPatient;
