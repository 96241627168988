import * as CONSTS from '@constants/profileConstants';
import * as AUTHCON from '@constants/authConstants';
import * as CLINCON from '@constants/clinicianConstants';
import { LOGOUT_ACTION } from '@constants/authConstants';

const initialState = {
	loading: false,
	loadingAnalytics: false,
	loadingReferralNotifications: false,
	userProfile: {
		id: null,
		title: '',
		firstName: '',
		lastName: '',
		jobRole: {
			jobRole: '',
		},
		appointmentsContact: null,
	},
	delegates: [],
	pendingDelegates: [],
	clinicianProfiles: [], /// update
	notificationSettings: {},
	analytics: {
		number: null,
		noPatientsMade: null,
		noPatientsReceived: null,
		noReferralsMade: null,
		noReferralsReceived: null,
		topReferredTo: [],
		topReferralsFrom: [],
		favouriteClinicians: [],
	},
	referralNotifications: { nReferrals: null, referrals: [] },
	referralPermissions: null,
};

// eslint-disable-next-line default-param-last
export default function profile(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case CONSTS.UPDATE_USER_NOTIFICATIONS_ACTION.REQUEST:
		case AUTHCON.USER_DELEGATES_ACTION.REQUEST:
		case CONSTS.LOAD_USER_PROFILE_ACTION.REQUEST:
		case CLINCON.CLINICIAN_CREATE_ACTION.REQUEST:
		case CLINCON.CLINICIAN_ROLE_REMOVE_REQ_ACTION.REQUEST:
		case CONSTS.UPDATE_USER_PROFILE_ACTION.REQUEST:
		case CLINCON.CLINICIAN_UPDATE_ACTION.REQUEST:
		case CONSTS.UPDATE_REFERRAL_CONTACTS_ACTION.REQUEST:
		case AUTHCON.DEACTIVATE_DELEGATE_ACTION.REQUEST:
		case AUTHCON.RESTORE_DELEGATE_ACTION.REQUEST:
		case AUTHCON.UPDATE_DELEGATE_PERMISSION_ACTION.REQUEST:
			return {
				...state,
				loading: true,
			};
		case CONSTS.LOAD_USER_PROFILE_ACTION.SUCCESS:
		case CONSTS.UPDATE_USER_PROFILE_ACTION.SUCCESS:
		case CONSTS.UPDATE_USER_NOTIFICATIONS_ACTION.SUCCESS:
		case AUTHCON.USER_DELEGATES_ACTION.SUCCESS:
		case CONSTS.UPDATE_REFERRAL_CONTACTS_ACTION.SUCCESS:
		case AUTHCON.DEACTIVATE_DELEGATE_ACTION.SUCCESS:
		case AUTHCON.RESTORE_DELEGATE_ACTION.SUCCESS:
		case AUTHCON.UPDATE_DELEGATE_PERMISSION_ACTION.SUCCESS:
			return {
				...state,
				...payload,
				loading: false,
			};
		case CONSTS.REFERRAL_NOTIFICATIONS_ACTION.REQUEST:
			return {
				...state,
				loadingReferralNotifications: true,
			};
		case CONSTS.USER_SUMMARY_ANALYTICS_ACTION.REQUEST:
			return {
				...state,
				loadingAnalytics: true,
			};
		case AUTHCON.USER_DELEGATES_ACTION.FAIL:
			return {
				...state,
				loading: false,
				delegates: [],
			};
		case CONSTS.USER_SUMMARY_ANALYTICS_ACTION.SUCCESS:
			return {
				...state,
				loadingAnalytics: false,
				analytics: payload,
			};
		case CONSTS.REFERRAL_NOTIFICATIONS_ACTION.SUCCESS:
			return {
				...state,
				loadingReferralNotifications: false,
				referralNotifications: payload,
			};
		case CLINCON.CLINICIAN_CREATE_ACTION.SUCCESS:
		case CLINCON.CLINICIAN_UPDATE_ACTION.SUCCESS:
		case CLINCON.CLINICIAN_ROLE_REMOVE_REQ_ACTION.SUCCESS:
			return {
				...state,
				loading: false,
				clinicianProfiles: payload.clinProfs,
			};
		case CONSTS.REFERRAL_NOTIFICATIONS_ACTION.FAIL:
			return {
				...state,
				loading: false,
				referralNotifications: { nReferrals: null, referrals: [] },
			};
		case LOGOUT_ACTION.SUCCESS:
			return { ...initialState };
		case CONSTS.LOAD_USER_PROFILE_ACTION.FAIL:
			return {
				...initialState,
			};
		case AUTHCON.DEACTIVATE_DELEGATE_ACTION.FAIL:
		case AUTHCON.RESTORE_DELEGATE_ACTION.FAIL:
		case CONSTS.UPDATE_USER_NOTIFICATIONS_ACTION.FAIL:
		case CLINCON.CLINICIAN_CREATE_ACTION.FAIL:
		case CLINCON.CLINICIAN_ROLE_REMOVE_REQ_ACTION.FAIL:
		case CLINCON.CLINICIAN_UPDATE_ACTION.FAIL:
		case CONSTS.UPDATE_USER_PROFILE_ACTION.FAIL:
		case CONSTS.USER_SUMMARY_ANALYTICS_ACTION.FAIL:
		case CONSTS.UPDATE_REFERRAL_CONTACTS_ACTION.FAIL:
		case AUTHCON.UPDATE_DELEGATE_PERMISSION_ACTION.FAIL:
			return {
				...state,
				loading: false,
			};
		default:
			return state;
	}
}
