import React, { useState, useEffect } from "react";
import { Form } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import PropTypes from 'prop-types';
import CheckAgeOver18FromDob from '@services/AgeCheck';

export function EmailFormField({ email, emailKey, emailLabel = null, emailPlaceholder = 'Enter email', changeValues, failedRegistration = false }){

    const [isEmailValid, setIsEmailValid] = useState(true);

	const setEmail = (e) => {
		const emailValue = e.target.value;
		const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    	const isValid = emailPattern.test(emailValue);
    	setIsEmailValid(isValid);
        changeValues({[emailKey]: emailValue, isValid });
	}
    
    return (
        <Form.Group controlId="email" className='mt-2'>
            {emailLabel && <Form.Label className='my-0'>{emailLabel}</Form.Label>}
            <Form.Control
                required
                type="email"
                placeholder={emailPlaceholder}
                value={email}
                onChange={(e) => setEmail(e)}
                isInvalid={!isEmailValid || failedRegistration}
            />
            <Form.Control.Feedback type="invalid">
                {!isEmailValid && "Please enter a valid email address."}
                {failedRegistration && "Email address has already been used by another user."}
            </Form.Control.Feedback>
        </Form.Group>
        )
};

EmailFormField.propTypes = {
    email: PropTypes.string.isRequired,
    emailKey: PropTypes.string.isRequired,
    emailLabel: PropTypes.string,
    emailPlaceholder: PropTypes.string,
	changeValues: PropTypes.func.isRequired,
    failedRegistration: PropTypes.bool,
};



export function DobFormField({ dob, dobKey, changeValues }){

    const [isDobValid, setIsDobValid] = useState(true);
    const [under18, setUnder18] = useState(false);

    const setDob = (e) => {
        const dobValue = e.target.value;
        const dobPattern = /^\d{4}-\d{2}-\d{2}$/;
    	const isPatternValid = dobPattern.test(dobValue);
        const { isValid } = CheckAgeOver18FromDob(e.target.value);
        setUnder18(!isValid);
    	setIsDobValid(isPatternValid);

        if (isValid && isPatternValid){
            changeValues({ [dobKey]: dobValue });
        }
    }
    
    return (
        <Form.Group controlId="dob" className='mt-2'>
            <Form.Label className='my-0'>Date of birth</Form.Label>
            <Form.Control
                required
                type="date"
                placeholder="Enter Date of Birth"
                defaultValue={dob}
                onChange={(e) => setDob(e)}
                isInvalid={!isDobValid || under18}
            />
            <Form.Control.Feedback type="invalid">
                {!isDobValid ? "Please enter a date of birth.":  "Users must be over 18 years old." }
            </Form.Control.Feedback>
        </Form.Group>
        )
}

DobFormField.propTypes = {
    dob: PropTypes.string.isRequired,
    dobKey: PropTypes.string.isRequired,
	changeValues: PropTypes.func.isRequired,
};


export function PhoneFormField({ currentPhoneNo, nameKey, nameLabel = null, changeValues }){
    const [phoneNo, setPhoneNo] = useState('');
    const [validPhone, setValidPhone] = useState(true);

    useEffect(() => {
        setPhoneNo(currentPhoneNo);
    }, [currentPhoneNo]);

    const isValidPhoneNumber = (phoneNumber) => {
        // Simple phone number validation using a regular expression
        const phoneRegex = /^\+(?:[0-9] ?){11,14}[0-9]$/;
        const isValid = phoneRegex.test(phoneNumber);
        setValidPhone(isValid);
        setPhoneNo(phoneNumber);
      };

    useEffect(() => {
        changeValues({ [nameKey]: phoneNo, isValid: validPhone});
    }, [phoneNo, validPhone]);

    useEffect(() => {
        if (currentPhoneNo!==phoneNo)
            setPhoneNo(currentPhoneNo);
    }, [currentPhoneNo]);

    return (
        <Form.Group controlId="phone_number" className="mb-3">
            {nameLabel && <Form.Label className='my-0'>{nameLabel} </Form.Label>}
            <PhoneInput
                placeholder="Enter phone number"
                defaultCountry="GB" 
                value={phoneNo}
                onChange={isValidPhoneNumber}
                autoComplete="off"
                />
            {!validPhone && <Form.Text className="text-danger text-left">Please enter a valid phone number</Form.Text>}
        </Form.Group>
    )
}

PhoneFormField.propTypes = {
    currentPhoneNo: PropTypes.string.isRequired,
    nameKey: PropTypes.string.isRequired,
    nameLabel: PropTypes.string,
	changeValues: PropTypes.func.isRequired,
};
