import { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getClinicCenterStr } from '@/actions/general';
import { debounce } from 'lodash';
import { useClinicianService } from '@actions';

const useClinicianCard = (
	clinician,
	hoveredClinicianUserProfileIds,
	updateHovered
) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const clinicianService = useClinicianService();

	const [displayPopup, setDisplayPopup] = useState(false);
	const [clinicianIsHovered, setClinicianIsHovered] = useState(false);
	const [showAllClinicsModal, setShowAllClinicsModal] = useState(false);
	const [clinicNames, setClinicNames] = useState([]);
	const [showClinicNames, setShowClinicNames] = useState(false);

	const { clinicAddressIds } = clinician;

	const toggleAllClinicsModal = () => setShowAllClinicsModal((state) => !state);

	const nClinics = clinician.otherClinics
		? clinician.otherClinics.length + 1
		: 1;

	const togglePopup = () => setDisplayPopup((prevState) => !prevState);

	const getClinicNames = () => {
		const names = [getClinicCenterStr(clinician.clinic)];
		if (clinician.otherClinics && clinician.otherClinics.length > 0) {
			const otherClinics = clinician.otherClinics.map((crp) =>
				getClinicCenterStr(crp.clinic)
			);
			names.push(...otherClinics);
		}
		setClinicNames(names);
	};

	const toggleClinicNames = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setShowClinicNames((state) => !state);
	};

	useEffect(() => getClinicNames(), []);

	const onMouseEnter = useCallback(
		debounce(
			() =>
				updateHovered({
					hoveredClinicianUserProfileIds: [clinician.userProfile.id],
					hoveredAddressIds: clinicAddressIds,
				}),
			100
		),
		[]
	);
	const onMouseLeave = useCallback(
		debounce(
			() =>
				updateHovered({
					hoveredClinicianUserProfileIds: [],
					hoveredAddressIds: [],
				}),
			100
		),
		[]
	);

	const checkIfClinicianIsHovered = () => {
		if (
			!hoveredClinicianUserProfileIds ||
			hoveredClinicianUserProfileIds === undefined
		) {
			setClinicianIsHovered(false);
			return;
		}
		const hovered = hoveredClinicianUserProfileIds.some(
			(id) => id === clinician.userProfile.id
		);
		setClinicianIsHovered(hovered);
	};

	useEffect(
		() => checkIfClinicianIsHovered(),
		[hoveredClinicianUserProfileIds]
	);

	const handleShowClinicsLink = (e) => {
		e.preventDefault();
		e.stopPropagation();
		toggleAllClinicsModal();
	};

	const gotToDirectReferral = (e) => {
		e.stopPropagation();
		clinicianService.goToReferToClinician(clinician.id);
	};

	return {
		displayPopup,
		togglePopup,
		onMouseEnter,
		onMouseLeave,
		clinicianIsHovered,
		nClinics,
		handleShowClinicsLink,
		showAllClinicsModal,
		toggleAllClinicsModal,
		gotToDirectReferral,
		clinicNames,
		showClinicNames,
		toggleClinicNames,
	};
};

export default useClinicianCard;
