/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SendErrorInApplictionInfo } from '../models/SendErrorInApplictionInfo';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ErrorNotificationService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Request new feature.
     * @param requestBody
     * @returns any No response body
     * @throws ApiError
     */
    public errorNotificationCreate(
        requestBody: SendErrorInApplictionInfo,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/error-notification/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
