import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import UserProfileCard from '@components/user/UserProfileCard';
import { clinicanProfilePropType } from '@constants/propTypes';
import { useClinicianService } from '@actions';

function ClinicianScreen({ clinician }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const clinicianService = useClinicianService();

	const { id } = useParams();

	const handleGetClinicianById = async () => {
		const userId = parseInt(id, 10);
		await clinicianService.getClinicianByUserId(userId);
	};

	useEffect(() => {
		handleGetClinicianById();
	}, [dispatch, id]);

	return (
		<div>
			<Row className="text-center py-1">
				<Col>
					<h1>Clinician Information</h1>
				</Col>
			</Row>
			<Row className="text-center py-1">
				<Col>
					<UserProfileCard clinicianProfile={clinician} />
				</Col>
			</Row>
		</div>
	);
}

ClinicianScreen.propTypes = {
	clinician: clinicanProfilePropType.isRequired,
};

const mapStateToProps = (state) => ({
	clinician: state.clinicians.clinician,
});

export default connect(mapStateToProps, {})(ClinicianScreen);
