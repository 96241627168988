import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Tabs, Tab } from 'react-bootstrap';
import PatientCardsDisplay from '@components/patient/PatientCardsDisplay';
import useScreenInfo from '@/hooks/app/useScreenInfo';
import PatientListTabsDropDown from '@/components/patient/PatientListTabsDropDown';
import { usePatientService } from '@actions';
import { patientsPropType } from '@/constants/propTypes';

function PatientListScreen({ added, referred, pending, accepted, completed }) {
	const [isNarrow] = useScreenInfo(769);
	const [activeTab, setActiveTab] = useState('addedPatients');
	const patientService = usePatientService();

	useEffect(() => {
		const handleGetPatients = async () =>
			await patientService.getClinicianPatientList();
		handleGetPatients();
	}, []);

	const viewLabels = {
		addedPatients: 'Patients Added',
		referredPatients: 'Patients Referred',
		pendingPatients: 'Patients Received',
		acceptedPatients: 'Patients Accepted',
		completedPatients: 'Patients Complteted',
	};

	useEffect(() => {
		window.scroll(0, 0);
	}, []);

	const handleTabChange = (tab) => {
		setActiveTab(tab);
	};

	const renderContent = () => {
		switch (activeTab) {
			case 'addedPatients':
				return <PatientCardsDisplay patientsList={added} />;
			case 'referredPatients':
				return <PatientCardsDisplay patientsList={referred} />;
			case 'pendingPatients':
				return (
					<PatientCardsDisplay patientsList={pending} showSearch={false} />
				);
			case 'acceptedPatients':
				return <PatientCardsDisplay patientsList={accepted} />;
			case 'completedPatients':
				return <PatientCardsDisplay patientsList={completed} />;
			default:
				return null;
		}
	};

	if (isNarrow) {
		return (
			<div className="patient-list">
				<h6 className="text-left p-1">Select patient group</h6>
				<PatientListTabsDropDown
					viewName={viewLabels[activeTab]}
					updateView={handleTabChange}
					viewOptions={[
						{ value: 'addedPatients', label: 'Added' },
						{ value: 'referredPatients', label: 'Referred' },
						{ value: 'pendingPatients', label: 'Received' },
						{ value: 'acceptedPatients', label: 'Accepted' },
						{ value: 'completedPatients', label: 'Completed' },
					]}
				/>
				{renderContent()}
			</div>
		);
	}

	return (
		<div className="patient-list">
			<Tabs
				defaultActiveKey="addedPatients"
				id="custom-tabs"
				className="custom-tabs mb-3"
			>
				<Tab eventKey="addedPatients" title="Added" className="custom-tabs">
					<PatientCardsDisplay patientsList={added} />
				</Tab>
				<Tab
					eventKey="referredPatients"
					title="Referred"
					className="custom-tabs"
				>
					<PatientCardsDisplay patientsList={referred} />
				</Tab>
				<Tab
					eventKey="pendingPatients"
					title="Received"
					className="custom-tabs"
				>
					<PatientCardsDisplay patientsList={pending} showSearch={false} />
				</Tab>
				<Tab
					eventKey="acceptedPatients"
					title="Accepted"
					className="custom-tabs"
				>
					<PatientCardsDisplay patientsList={accepted} />
				</Tab>
				<Tab
					eventKey="completedPatients"
					title="Completed"
					className="custom-tabs"
				>
					<PatientCardsDisplay patientsList={completed} />
				</Tab>
			</Tabs>
		</div>
	);
}

PatientListScreen.propType = {
	added: patientsPropType.isRequired,
	referred: patientsPropType.isRequired,
	pending: patientsPropType.isRequired,
	accepted: patientsPropType.isRequired,
	completed: patientsPropType.isRequired,
};

const mapToState = (state) => ({
	added: state.patients.addedPatients,
	referred: state.patients.referredPatients,
	pending: state.patients.pendingPatients,
	accepted: state.patients.acceptedPatients,
	completed: state.patients.completedPatients,
});

export default connect(mapToState, {})(PatientListScreen);
