import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { clinicianProfilesPropType } from '@constants/propTypes';
import UserClinicianProfiles from '@components/clinicians/UserClinicianProfiles';
import { useClinicianService } from '@actions';

function UserCrpsScreen({ crps }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const clinicianService = useClinicianService();

	const { id } = useParams();

	const handleGetClinicianById = async () => {
		const userId = parseInt(id, 10);
		await clinicianService.getClinicianByUserId(userId);
	};

	useEffect(() => {
		handleGetClinicianById();
	}, []);

	return (
		<div>
			<Row className="text-center py-1">
				<Col>
					<h1>Clinician Information</h1>
				</Col>
			</Row>
			<div className="d-flex justify-content-center">
				<UserClinicianProfiles crps={crps} />
			</div>
		</div>
	);
}

UserCrpsScreen.propTypes = {
	crps: clinicianProfilesPropType.isRequired,
};

const mapStateToProps = (state) => ({
	crps: state.clinicians.clinicianCrps,
});

export default connect(mapStateToProps, {})(UserCrpsScreen);
