import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import { getNameString } from '@/actions/general';
import UserAnalyticsSummary from '@components/user/UserAnalyticsSummary';
import AppUpdatesPanel from '@components/app/AppUpdatesPanel';
import useUserProfile from '@hooks/user/useUserProfile';


function DashboardClinician() {

    const navigate = useNavigate();
    const { userProfile } = useUserProfile();
	const handleNavigationNewReferral = () => navigate('/referral/add')
	const handleNavigationViewReferrals = () => navigate('/referrals')

	return (
		<>
			<Row className='medr-layout text-center'>
				<Col>
					<h4>Welcome {userProfile ? getNameString(userProfile) : null}</h4>
				</Col>
			</Row>
            <Row className='medr-layout text-center'>
                <Col>
                    <Button type="button" onClick={handleNavigationNewReferral} className="sel-button w100">
                        Make referral
                    </Button>
                </Col>
                <Col>
                    <Button type="button" onClick={handleNavigationViewReferrals} className="sel-button w100">
                        View referrals
                    </Button>
                </Col>
            </Row>

            <Row>
                <Col xs={12} md={9}>
                    <UserAnalyticsSummary />
                </Col>
                <Col xs={12} md={3}>
                    <AppUpdatesPanel />
                </Col>
            </Row>
		</>
	);
}

export default DashboardClinician;
