import { createContext, useContext } from "react";


interface RegistrationContextType {
    activeStep: number;
    setNextStep: () => void;
    setPreviousStep: () => void;
    registrationData: any;
    updateRegistrationData: ( data: any ) => void;
    resetRegistrationData: () => void;
    addJobRole: ( jobRole: any ) => void;
    removeJobRole: ( jobRole: any ) => void;
    addClinic: ( clinic: any ) => void;
    editClinic: ( clinic: any ) => void;
    removeClinic: ( clinic: any ) => void;
    updateAppointmentContacts: ( contacts: any ) => void;
    updateExisitingProviders: ( providers: any ) => void;
    updateNewProviders: ( providers: any ) => void;
    onSubmit: () => void;
}


export const RegistrationContext = createContext<RegistrationContextType | undefined>( undefined );

export function useRegistrationContext() {
    const context = useContext( RegistrationContext );

    if ( !context ) {
        throw new Error( "useRegistrationContext must be used within a RegistrationProvider" );
    }

    return context;
}