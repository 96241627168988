import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const useFilteredClinicians = (jobRoleId) => {
	const lat = useSelector((state) => state.search.lat);
	const lng = useSelector((state) => state.search.lng);
	const initData = {
		jobRoleId: jobRoleId,
		insuranceProviderId: 0,
		searchLatitude: lat,
		searchLongitude: lng,
	};
	const [filterFields, setFilterFields] = useState(initData);

	const updateFilterFields = (data) => {
		data = {
			...data,
			jobRoleSelected: data.selectedJobRole,
			jobSpecialtySelected: data.selectedJobSpecialty,
		};
		delete data['selectedJobRole'];
		delete data['selectedJobSpecialty'];
		setFilterFields((prev) => ({ ...prev, ...data }));
	};
	useEffect(() => {
		updateFilterFields({ searchLatitude: lat, searchLongitude: lng });
	}, [lat, lng]);

	return {
		filterFields,
		updateFilterFields,
	};
};

export default useFilteredClinicians;
