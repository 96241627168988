import React, { useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import {
	getNameString,
	getClinicCenterStr,
	getInsuranceAgreementString,
} from '@/actions/general';
import MissingInfoBox from '@/components/app/parts/MissingInfoBox';
import ReferralSentPopup from '../ReferralSentPopup';
import { useReferralEntryContext } from '@contexts/ReferralEntryContext';
import { useAuthService } from '@/actions';

const ErrorMessage = {
	CLINICIAN_PROFILES: 'Error loading user clinician profiles',
	REFERRAL_DATA: 'Error loading referral data',
};

const ErrorDisplay = {
	CLINICIAN_PROFILES: (
		<MissingInfoBox
			title={ErrorMessage.CLINICIAN_PROFILES}
			subtitle="Please refresh your screen."
		/>
	),
	REFERRAL_DATA: <MissingInfoBox title="Error missing data" />,
};

function RegisterReview() {
	const {
		patient,
		patientHistory,
		mainDescription,
		jobRole,
		targetClinician,
		specifiedClinician,
		insuranceProvider,
		validateClinicianProfiles,
		validateReferralData,
		onSubmit,
		referralMade,
		exitReferral,
	} = useReferralEntryContext();

	const authServ = useAuthService();

	useEffect(() => {
		window.scroll(0, 0);
	}, []);

	const handleValidationError = (errorType) => {
		authServ.sendErrorNotification(ErrorMessage[errorType]);
		return ErrorDisplay[errorType];
	};

	if (!validateClinicianProfiles()) {
		return handleValidationError('CLINICIAN_PROFILES');
	}

	if (!validateReferralData()) {
		return handleValidationError('REFERRAL_DATA');
	}

	const lg = 4;
	const md = 6;
	const sm = 12;

	const clinicianSelectedStr = specifiedClinician
		? `${getNameString(targetClinician.userProfile)} - ${getClinicCenterStr(
				targetClinician.clinic
		  )}`
		: `Referral open to all ${jobRole.jobTitle}s`;

	const rowData = [
		{ label: 'Patient:', value: getNameString(patient) },
		{
			label: 'Insurance Provider:',
			value: getInsuranceAgreementString(insuranceProvider),
		},
		{ label: 'History:', value: patientHistory },
		{ label: 'Reason for referral:', value: mainDescription },
		{ label: 'Specialist required:', value: jobRole.jobTitle },
		{ label: 'Clinician selected:', value: clinicianSelectedStr },
	];

	return (
		<div
			className="medr-layout text-center"
			style={{ maxWidth: '700px', marginLeft: 'auto', marginRight: 'auto' }}
		>
			<ReferralSentPopup showModal={referralMade} handleClose={exitReferral} />
			<Row>
				<Col>
					<h4>Review referral data</h4>
				</Col>
			</Row>
			<div className="medr-rounded bg-medr-gray bluegreen mt-4 text-left">
				<Row>
					<Col className="text-center">
						<h4 className="bluegreen">Referral summary</h4>
					</Col>
				</Row>
				{rowData.map((row, index) => (
					<Row key={index} className="mt-4">
						<Col lg={lg} md={md} sm={sm}>
							{row.label}
						</Col>
						<Col>{row.value}</Col>
					</Row>
				))}
			</div>
			<Row className="mt-4">
				<Col>
					<Button
						className="sel-button w80"
						variant="success"
						type="submit"
						onClick={() => onSubmit()}
					>
						Send referral
					</Button>
				</Col>
			</Row>
		</div>
	);
}

export default RegisterReview;
