/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InsuranceAgreementByIdResponse } from '../models/InsuranceAgreementByIdResponse';
import type { InsuranceAgreementListResponse } from '../models/InsuranceAgreementListResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class InsuranceAgreementsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Retrieve a list of insurance policies.
     * @returns InsuranceAgreementListResponse
     * @throws ApiError
     */
    public insuranceAgreementsList(): CancelablePromise<InsuranceAgreementListResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/insurance-agreements/',
        });
    }
    /**
     * Return insurance policy information using the uid.
     * @param id
     * @returns InsuranceAgreementByIdResponse
     * @throws ApiError
     */
    public insuranceAgreementsRetrieve(
        id: number,
    ): CancelablePromise<InsuranceAgreementByIdResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/insurance-agreements/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Verify insurance agreemnt using the uid.
     * @param id
     * @returns any No response body
     * @throws ApiError
     */
    public insuranceAgreementsVerifyCreate(
        id: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/insurance-agreements/verify/{id}/',
            path: {
                'id': id,
            },
        });
    }
}
