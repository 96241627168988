/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ReferralCreateRequest } from '../models/ReferralCreateRequest';
import type { ReferralCreateResponse } from '../models/ReferralCreateResponse';
import type { ReferralsListForUserResponse } from '../models/ReferralsListForUserResponse';
import type { ReferralsNotificationsForUserResponse } from '../models/ReferralsNotificationsForUserResponse';
import type { ReferralToggleAcceptedRequest } from '../models/ReferralToggleAcceptedRequest';
import type { ReferralToggleCompleteRequest } from '../models/ReferralToggleCompleteRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ReferralsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Set referral accepted by the target clinician
     * @param refId
     * @param requestBody
     * @returns ReferralsListForUserResponse
     * @throws ApiError
     */
    public referralsCompleteCreate(
        refId: number,
        requestBody: ReferralToggleCompleteRequest,
    ): CancelablePromise<ReferralsListForUserResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/referrals/complete/{ref_id}/',
            path: {
                'ref_id': refId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Creates referral using form data
     * @param requestBody
     * @returns ReferralCreateResponse
     * @throws ApiError
     */
    public referralsCreateCreate(
        requestBody: ReferralCreateRequest,
    ): CancelablePromise<ReferralCreateResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/referrals/create/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Deletes the referral using the referral uid.
     * @param refId
     * @returns ReferralsListForUserResponse
     * @throws ApiError
     */
    public referralsDeleteDestroy(
        refId: number,
    ): CancelablePromise<ReferralsListForUserResponse> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/referrals/delete/{ref_id}/',
            path: {
                'ref_id': refId,
            },
        });
    }
    /**
     * Returns referral lists for the logged in user
     * @returns ReferralsListForUserResponse
     * @throws ApiError
     */
    public referralsListRetrieve(): CancelablePromise<ReferralsListForUserResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/referrals/list/',
        });
    }
    /**
     * Returns referral notifications for the logged in user
     * @returns ReferralsNotificationsForUserResponse
     * @throws ApiError
     */
    public referralsReferralNotificationsRetrieve(): CancelablePromise<ReferralsNotificationsForUserResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/referrals/referral-notifications/',
        });
    }
    /**
     * Set referral accepted by the target clinician
     * @param refId
     * @param requestBody
     * @returns ReferralsListForUserResponse
     * @throws ApiError
     */
    public referralsToggleAcceptCreate(
        refId: number,
        requestBody: ReferralToggleAcceptedRequest,
    ): CancelablePromise<ReferralsListForUserResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/referrals/toggle-accept/{ref_id}/',
            path: {
                'ref_id': refId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
