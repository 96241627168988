/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { VerifyDelegateUserRequest } from '../models/VerifyDelegateUserRequest';
import type { VerifyDelegateUserResponse } from '../models/VerifyDelegateUserResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class VerifyDelegateService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Verify delegate user.
     * @param requestBody
     * @returns VerifyDelegateUserResponse
     * @throws ApiError
     */
    public verifyDelegateUpdate(
        requestBody: VerifyDelegateUserRequest,
    ): CancelablePromise<VerifyDelegateUserResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/verify-delegate/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
