import { Dispatch } from 'redux';
import { NavigateFunction } from 'react-router-dom';
import { getAuthApiClient, getAnyApiClient } from './apiActions';
import * as USRCONSTS from '@/constants/userConstants';
import * as PROFCONSTS from '@/constants/profileConstants';
import { checkIsAuthenticated, redirectIfInvalidToken } from './authService';
import { UpdateReferralContactsRequest, UpdateReferralContactsResponse, RequestHelpResponse, RequestHelpRequest, UpdateUserNotificationsSettingsResponse, UpdateUserNotificationsSettingsRequest, UpdateUserProfileRequest, UpdateUserProfileResponse, UpdateUserProfilePictureResponse } from '../client';


type ApiClient = ReturnType<typeof getAnyApiClient>;
type ApiClientAuth = ReturnType<typeof getAuthApiClient>;

export class UserService {
    private dispatch: Dispatch;
    private navigate: NavigateFunction;
    private isAuthenticated: boolean;
    private appAnyClient: ApiClient;
    private appAuthClient: ApiClientAuth;

    constructor ( dispatch: Dispatch, navigate: NavigateFunction ) {
        this.dispatch = dispatch;
        this.navigate = navigate;
        this.appAnyClient = getAnyApiClient();
        this.initAuthClient();
    }
    initAuthClient() {
        this.appAuthClient = getAuthApiClient();
        this.isAuthenticated = checkIsAuthenticated();
    }

    async loadUserProfile(): Promise<boolean> {
        this.dispatch( { type: PROFCONSTS.LOAD_USER_PROFILE_ACTION.REQUEST } );
        try {
            const data = await this.appAuthClient.user.userClinicianInformationRetrieve();
            this.dispatch( { type: PROFCONSTS.LOAD_USER_PROFILE_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: PROFCONSTS.LOAD_USER_PROFILE_ACTION.FAIL } );
            return false;
        }
    }

    async getReferralNotifications(): Promise<boolean> {
        this.dispatch( { type: PROFCONSTS.REFERRAL_NOTIFICATIONS_ACTION.REQUEST } );

        try {
            const data = await this.appAuthClient.referrals.referralsReferralNotificationsRetrieve();
            this.dispatch( { type: PROFCONSTS.REFERRAL_NOTIFICATIONS_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: PROFCONSTS.REFERRAL_NOTIFICATIONS_ACTION.FAIL } );
            return false;
        }
    }

    async updateProfile( inputData: UpdateUserProfileRequest ): Promise<boolean> {
        this.dispatch( { type: PROFCONSTS.UPDATE_USER_PROFILE_ACTION.REQUEST } );
        try {
            const data: UpdateUserProfileResponse = await this.appAuthClient.user.userUpdateUpdate( inputData );
            this.dispatch( { type: PROFCONSTS.UPDATE_USER_PROFILE_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: PROFCONSTS.UPDATE_USER_PROFILE_ACTION.FAIL } );
            return false;
        }
    }

    async updateProfilePicture( inputData: FormData ): Promise<boolean> {
        this.dispatch( { type: USRCONSTS.UPDATE_PROFILE_PIC_ACTION.REQUEST } );
        const image = inputData.get( 'image' );
        const payload = { image };
        try {
            const data: UpdateUserProfilePictureResponse = await this.appAuthClient.user.userProfilePicUploadCreate( payload );
            this.dispatch( { type: USRCONSTS.UPDATE_PROFILE_PIC_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: USRCONSTS.UPDATE_PROFILE_PIC_ACTION.FAIL } );
            return false;
        }
    }

    async updateUserNotificationsSettings( inputData: UpdateUserNotificationsSettingsRequest ): Promise<boolean> {
        this.dispatch( { type: PROFCONSTS.UPDATE_USER_NOTIFICATIONS_ACTION.REQUEST } );

        try {
            const data: UpdateUserNotificationsSettingsResponse = await this.appAuthClient.user.userNotificationsUpdateUpdate( inputData );
            this.dispatch( { type: PROFCONSTS.UPDATE_USER_NOTIFICATIONS_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: PROFCONSTS.UPDATE_USER_NOTIFICATIONS_ACTION.FAIL } );
            return false;
        }
    }

    async getUserSummaryAnalytics( number: number ): Promise<boolean> {
        this.dispatch( { type: PROFCONSTS.USER_SUMMARY_ANALYTICS_ACTION.REQUEST } );

        try {
            const data: UpdateUserNotificationsSettingsResponse = await this.appAuthClient.user.userAnalyticsDataRetrieve( number );
            this.dispatch( { type: PROFCONSTS.USER_SUMMARY_ANALYTICS_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: PROFCONSTS.USER_SUMMARY_ANALYTICS_ACTION.FAIL } );
            return false;
        }
    }

    async updateReferralContacts( inputData: UpdateReferralContactsRequest ): Promise<boolean> {
        this.dispatch( { type: PROFCONSTS.UPDATE_REFERRAL_CONTACTS_ACTION.REQUEST } );

        try {
            const data: UpdateReferralContactsResponse = await this.appAuthClient.user.userReferralContactsUpdateUpdate( inputData );
            this.dispatch( { type: PROFCONSTS.UPDATE_REFERRAL_CONTACTS_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: PROFCONSTS.UPDATE_REFERRAL_CONTACTS_ACTION.FAIL } );
            return false;
        }
    }

    async requestHelp( inputData: RequestHelpRequest ): Promise<boolean> {
        this.dispatch( { type: PROFCONSTS.REQUEST_HELP_ACTION.REQUEST } );

        let data: RequestHelpResponse;
        try {
            if ( !this.isAuthenticated ) {
                data = await this.appAnyClient.user.userRequestHelpCreate( inputData );
            } else {
                data = await this.appAuthClient.user.userRequestHelpCreate( inputData );
            }
            this.dispatch( { type: PROFCONSTS.REQUEST_HELP_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: PROFCONSTS.REQUEST_HELP_ACTION.FAIL } );
            return false;
        }
    }

    public redirectToDashboard(): void {
        this.navigate( '/dashboard' );
    }


}