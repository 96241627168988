import * as CONSTS from '@constants/clinicianConstants';
import * as INIT from '@constants/initDataConstants';

const initialState = {
	loading: false,
	loadingClinicians: false,
	clinician: INIT.initCRP,
	clinicianCrps: [],
	cliniciansData: {
		role: 'Any',
		targetCoords: { lat: 0, lng: 0 },
		clinicianList: [],
	},
	jobRoles: [],
	doctorIds: [],
	clinicianTypes: [],
	specialties: {},
	insuranceAgreements: [],
	clinics: [],
	doctorClinics: [],
	search: {
		jobRoleId: 1,
		insuranceAgreementId: 0,
		lat: 0,
		lng: 0,
	},
	clinicianList: [],
	addressesList: [],
};

// eslint-disable-next-line default-param-last
export default function clinicians(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case CONSTS.CLINICS_LIST_ACTION.REQUEST:
		case CONSTS.CLINICIAN_BY_ID_ACTION.REQUEST:
		case CONSTS.USER_CRPS_GET_ACTION.REQUEST:
		case CONSTS.CLINICIAN_ROLE_DEACTIVATE_ACTION.REQUEST:
		case CONSTS.JOB_ROLE_LIST_ACTION.REQUEST:
		case CONSTS.JOB_ROLE_BY_ID_ACTION.REQUEST:
		case CONSTS.INSURANCE_AGREEMENT_LIST_ACTION.REQUEST:
		// case CONSTS.INSURANCE_AGREEMENT_SET_ID_REQUEST:
		case CONSTS.CLINICIAN_INSURANCE_UPDATE_ACTION.REQUEST:
			return {
				...state,
				loading: true,
			};
		case CONSTS.CLINICIAN_LIST_ACTION.REQUEST:
			return {
				...state,
				loadingClinicians: true,
			};
		case CONSTS.CLINICIAN_LIST_ACTION.SUCCESS:
			return {
				...state,
				loadingClinicians: false,
				cliniciansData: payload,
				clinicianList: payload.clinicianList,
				addressesList: payload.addressesList,
			};
		case CONSTS.CLINICS_LIST_ACTION.SUCCESS:
			return {
				...state,
				...payload,
				loading: false,
			};
		case CONSTS.CLINICIAN_BY_ID_ACTION.SUCCESS:
			return {
				...state,
				loading: false,
				clinician: payload.crp,
				clinicianCrps: payload.allCrps,
			};
		case CONSTS.USER_CRPS_GET_ACTION.SUCCESS:
			return {
				...state,
				loading: false,
				clinician: payload.crp,
				clinicianCrps: payload.allCrps,
			};
		case CONSTS.CLINICIAN_BY_ID_ACTION.FAIL:
		case CONSTS.USER_CRPS_GET_ACTION.FAIL:
			return {
				...state,
				loading: false,
				clinician: INIT.initCRP,
				clinicianCrps: [],
			};
		case CONSTS.JOB_ROLE_LIST_ACTION.SUCCESS:
			return {
				...state,
				loading: false,
				jobRoles: payload.jobRoles,
				doctorIds: payload.doctorIds,
				clinicianTypes: payload.clinicianTypes,
				specialties: payload.specialties,
			};
		case CONSTS.JOB_ROLE_BY_ID_ACTION.SUCCESS:
			return {
				...state,
				loading: false,
				search: {
					jobRoleId: payload.id,
				},
			};
		case CONSTS.INSURANCE_AGREEMENT_LIST_ACTION.SUCCESS:
			return {
				...state,
				loading: false,
				insuranceAgreements: payload.insuranceAgreements,
			};
		// case CONSTS.INSURANCE_AGREEMENT_SET_ID_SUCCESS:
		// 	return {
		// 		...state,
		// 		loading: false,
		// 		search: {
		// 			insuranceAgreementId: payload.insuranceAgreement.id,
		// 		},
		// 	};
		case CONSTS.CLINICIAN_LIST_ACTION.FAIL:
			return {
				...state,
				loadingClinicians: false,
			};
		case CONSTS.CLINICS_LIST_ACTION.FAIL:
		case CONSTS.JOB_ROLE_LIST_ACTION.FAIL:
		case CONSTS.CLINICIAN_ROLE_DEACTIVATE_ACTION.SUCCESS:
		case CONSTS.CLINICIAN_ROLE_DEACTIVATE_ACTION.FAIL:
		case CONSTS.JOB_ROLE_BY_ID_ACTION.FAIL:
		case CONSTS.CLINICIAN_INSURANCE_UPDATE_ACTION.SUCCESS:
		case CONSTS.CLINICIAN_INSURANCE_UPDATE_ACTION.FAIL:
		case CONSTS.INSURANCE_AGREEMENT_LIST_ACTION.FAIL:
			return {
				...state,
				loading: false,
			};
		default:
			return state;
	}
}
