interface User {
    id: number | null;
    username: string;
    email: string;
    first_name: string;
    last_name: string;
    is_staff: boolean;
}

interface JobRole {
    id: number | null;
    jobRole: string;
    specialty: string;
    registrationBody: string;
}

interface UserProfile {
    user: User;
    jobRole: JobRole;
    title: string;
    firstName: string;
    lastName: string;
    profilePicture: string;
    isPatient: boolean;
    registrationNumber: string;
}

interface Address {
    firstLine: string;
    city: string;
    postcode: string;
    longitude: number | null;
    latitude: number | null;
}

interface Patient {
    id: number | null;
    createdBy: UserProfile;
    currentAge: number | null;
    viewType: string;
    detailsInImage: boolean;
    title: string;
    firstName: string;
    lastName: string;
    patientReference: string;
    sex: string;
    dob: string;
    primaryEmail: string;
    primaryPhoneNumber: string;
    address: Address;
    detailsImage: string;
    patientActive: boolean;
    createdOn: string;
    isSelfPayer: boolean;
    insurancePolicy: any | null;
}

interface CRP {
    id: number | null;
    userProfile: UserProfile;
    centreName: string;
    addressFirstLine: string;
    addressCity: string;
    addressPostcode: string;
    addressLongitude: number | null;
    addressLatitude: number | null;
}

interface Referral {
    id: number | null;
    patient: Patient;
    referrer: CRP;
    specifiedClinician: boolean;
    targetClinician: CRP;
    mainDescription: string;
    histoy: string;
    isPrivateView: boolean;
    targetHasAccepted: boolean;
    createdOn: string;
}

interface CrpFormData {
    centreName: string;
    addressFirstLine: string;
    addressCity: string;
    addressPostcode: string;
}

interface Postcode {
    lat: number;
    lng: number;
    zoom: number;
}

export const initUser: User = {
    id: null,
    username: "",
    email: "",
    first_name: "",
    last_name: "",
    is_staff: false,
};

export const initJobRole: JobRole = {
    id: null,
    jobRole: "",
    specialty: "",
    registrationBody: "",
};

export const initUserProfile: UserProfile = {
    user: initUser,
    jobRole: initJobRole,
    title: "",
    firstName: "",
    lastName: "",
    profilePicture: "",
    isPatient: false,
    registrationNumber: "",
};

export const initPatient: Patient = {
    id: null,
    createdBy: initUserProfile,
    currentAge: null,
    viewType: "",
    detailsInImage: false,
    title: "",
    firstName: "",
    lastName: "",
    patientReference: "",
    sex: "",
    dob: "",
    primaryEmail: "",
    primaryPhoneNumber: "",
    address: {
        firstLine: "",
        city: "",
        postcode: "",
        longitude: null,
        latitude: null,
    },
    detailsImage: "",
    patientActive: false,
    createdOn: "",
    isSelfPayer: true,
    insurancePolicy: null
};

export const initCRP: CRP = {
    id: null,
    userProfile: initUserProfile,
    centreName: "",
    addressFirstLine: "",
    addressCity: "",
    addressPostcode: "",
    addressLongitude: null,
    addressLatitude: null,
}

export const initReferral: Referral = {
    id: null,
    patient: initPatient,
    referrer: initCRP,
    specifiedClinician: false,
    targetClinician: initCRP,
    mainDescription: "",
    histoy: "",
    isPrivateView: true,
    targetHasAccepted: false,
    createdOn: ""
}

export const initCrpFormData: CrpFormData = {
    centreName: "",
    addressFirstLine: "",
    addressCity: "",
    addressPostcode: ""
};

export const initPostcode: Postcode = {
    lat: 51.5074,
    lng: 0.1278,
    zoom: 11,
}
