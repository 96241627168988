/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MedrAppVersion } from '../models/MedrAppVersion';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AppVersionService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get current applicaion version
     * @returns MedrAppVersion
     * @throws ApiError
     */
    public appVersionRetrieve(): CancelablePromise<MedrAppVersion> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/app-version/',
        });
    }
}
