import React, { useEffect, useState } from 'react';
import { Table, Button, Tabs, Tab } from 'react-bootstrap';
import { connect } from 'react-redux';
import { userProfilesPropType } from '@constants/propTypes';
import PaginationBar from '../app/PaginationBar';
import VerifyClinicianPopup from './popups/VerifyClinicianPopup';
import { convertDateTime } from '@/actions/general';
import { useAdminService } from '@actions';
import Loader from '@components/app/Loader';

function AdminUsersTable({
	usersList,
	unverifiedUsersList,
	deactivatedUsersList,
	operationsUsersList,
}) {
	const adminService = useAdminService();
	const nUsersWindow = 18;
	const start = 0;
	const end = nUsersWindow;

	const [users, setUsers] = useState(null);
	const [usersLength, setUsersLength] = useState(0);
	const [userSlice, setUserSlice] = useState({ start, end });

	const [unverifiedUsers, setUnverifiedUsers] = useState(null);
	const [unverifiedUsersLength, setUnverifiedUsersLength] = useState(0);
	const [unverifiedUsersSlice, setUnverifiedUsersSlice] = useState({
		start,
		end,
	});

	const [deactivatedUsers, setDeactivatedUsers] = useState(null);
	const [deactivatedUsersLength, setDeactivatedUsersLength] = useState(0);
	const [deactivatedUsersSlice, setDeactivatedUsersSlice] = useState({
		start,
		end,
	});

	const [operationsUsers, setOperationsUsers] = useState(null);
	const [operationsUsersLength, setOperationsUsersLength] = useState(0);
	const [operationsUsersSlice, setOperationsUsersSlice] = useState({
		start,
		end,
	});

	const [showVerificationPopup, setShowVerificationPopup] = useState(false);
	const [clinicianToChangeVerification, setClinicianToChangeVerification] =
		useState(null);

	useEffect(() => {
		const handleGetAdminUsers = async () =>
			await adminService.getAdminUsersList();
		handleGetAdminUsers();
	}, []);

	const updateUsersSliceIndices = (startInd, endInd) =>
		setUserSlice({ start: startInd, end: endInd });
	const updateUnverifiedUsersSliceIndices = (startInd, endInd) =>
		setUnverifiedUsersSlice({ start: startInd, end: endInd });
	const updateDeactivatedUsersSliceIndices = (startInd, endInd) =>
		setDeactivatedUsersSlice({ start: startInd, end: endInd });
	const updateOperationsUsersSliceIndices = (startInd, endInd) =>
		setOperationsUsersSlice({ start: startInd, end: endInd });

	const updateUsersSlice = () => {
		if (!usersList || usersList.length === 0) {
			setUsers([]);
			return;
		}
		const slice = usersList.slice(userSlice.start, userSlice.end);
		setUsers(slice);
		setUsersLength(usersList.length);
	};

	const updateUnverifiedUsersSlice = () => {
		if (!unverifiedUsersList || unverifiedUsersList.length === 0) {
			setUnverifiedUsers([]);
			return;
		}
		const slice = unverifiedUsersList.slice(
			unverifiedUsersSlice.start,
			unverifiedUsersSlice.end
		);
		setUnverifiedUsers(slice);
		setUnverifiedUsersLength(unverifiedUsersList.length);
	};

	const updateDeactivatedUsersSlice = () => {
		if (!deactivatedUsersList || deactivatedUsersList.length === 0) {
			setDeactivatedUsers([]);
			return;
		}
		const slice = deactivatedUsersList.slice(
			deactivatedUsersSlice.start,
			deactivatedUsersSlice.end
		);
		setDeactivatedUsers(slice);
		setDeactivatedUsersLength(deactivatedUsersList.length);
	};

	const updateOperationsUsersSlice = () => {
		if (!operationsUsersList || operationsUsersList.length === 0) {
			setOperationsUsers([]);
			return;
		}
		const slice = operationsUsersList.slice(
			operationsUsersSlice.start,
			operationsUsersSlice.end
		);
		setOperationsUsers(slice);
		setOperationsUsersLength(operationsUsersList.length);
	};

	useEffect(() => updateUsersSlice(), [usersList, userSlice]);
	useEffect(
		() => updateUnverifiedUsersSlice(),
		[unverifiedUsersList, unverifiedUsersSlice]
	);
	useEffect(
		() => updateDeactivatedUsersSlice(),
		[deactivatedUsersList, deactivatedUsersSlice]
	);
	useEffect(
		() => updateOperationsUsersSlice(),
		[operationsUsersList, operationsUsersSlice]
	);

	useEffect(() => {
		if (
			clinicianToChangeVerification === null ||
			clinicianToChangeVerification === undefined
		)
			return;
		setShowVerificationPopup(true);
	}, [clinicianToChangeVerification]);

	const handleCloseVerificationPopup = () => {
		setShowVerificationPopup(false);
		setClinicianToChangeVerification(null);
	};

	const unverifiedUsersTableContents = unverifiedUsers
		? unverifiedUsers.map((userProfile, index) => (
				<tr key={userProfile.id}>
					<td>{index + 1}</td>
					<td>{userProfile.id}</td>
					<td>
						{userProfile.firstName} {userProfile.lastName}
					</td>
					<td>{userProfile.user.email}</td>
					<td>
						{userProfile.user.is_staff ? (
							<i className="fas fa-check" style={{ color: 'green' }} />
						) : (
							<i className="fas fa-check" style={{ color: 'red' }} />
						)}
					</td>
					<td>
						<Button
							id={`verifyClinician${userProfile.id}`}
							variant="light"
							className="btn-sm"
							onClick={() =>
								setClinicianToChangeVerification({
									userProfile,
									setVerified: true,
								})
							}
						>
							<i className="fas fa-user-plus" style={{ color: 'green' }} />
						</Button>
					</td>
				</tr>
		  ))
		: null;

	const deactivatedUsersTableContents = deactivatedUsers
		? deactivatedUsers.map((userProfile, index) => (
				<tr key={userProfile.id}>
					<td>{index + 1}</td>
					<td>{userProfile.id}</td>
					<td>
						{userProfile.firstName} {userProfile.lastName}
					</td>
					<td>{userProfile.user.email}</td>
				</tr>
		  ))
		: null;

	const operationsUsersTableContents = operationsUsers
		? operationsUsers.map((userProfile, index) => (
				<tr key={userProfile.id}>
					<td>{index + 1}</td>
					<td>{userProfile.id}</td>
					<td>
						{userProfile.firstName} {userProfile.lastName}
					</td>
				</tr>
		  ))
		: null;

	const usersTableContents = users
		? users.map((userProfile, index) => (
				<tr key={userProfile.id}>
					<td>{index + 1}</td>
					<td>{userProfile.id}</td>
					<td>
						{userProfile.firstName} {userProfile.lastName}
					</td>
					<td>{userProfile.user.email}</td>
					<td>
						{userProfile.user.accessedInLastStatus}{' '}
						{userProfile.user.last_login && (
							<small>({convertDateTime(userProfile.user.last_login)})</small>
						)}
					</td>
					<td>
						{userProfile.user.is_staff ? (
							<i className="fas fa-check" style={{ color: 'green' }} />
						) : (
							<i className="fas fa-check" style={{ color: 'red' }} />
						)}
					</td>
					<td>
						<Button
							id={`verifyClinician${userProfile.id}`}
							variant="light"
							className="btn-sm"
							onClick={() =>
								setClinicianToChangeVerification({
									userProfile,
									setVerified: false,
								})
							}
						>
							<i className="fas fa-user-minus" style={{ color: 'red' }} />
						</Button>
					</td>
				</tr>
		  ))
		: null;

	const unverifiedHeadersRow = (
		<tr>
			<th>Row</th>
			<th>ID</th>
			<th>NAME</th>
			<th>EMAIL</th>
			<th>MEDR STAFF</th>
			<th>Verify</th>
		</tr>
	);
	const deactivatedHeadersRow = (
		<tr>
			<th>Row</th>
			<th>ID</th>
			<th>NAME</th>
			<th>EMAIL</th>
		</tr>
	);

	const operationsHeadersRow = (
		<tr>
			<th>Row</th>
			<th>ID</th>
			<th>NAME</th>
		</tr>
	);

	const verifiedHeadersRow = (
		<tr>
			<th>Row</th>
			<th>ID</th>
			<th>NAME</th>
			<th>EMAIL</th>
			<th>LAST LOGIN</th>
			<th>MEDR STAFF</th>
			<th>Unverify</th>
		</tr>
	);

	const noUsersDisplay = (
		<div className="center-box-parent">
			<div className="center-box-child">
				<h4 className="medr-text-inv">No users to display at the moment</h4>
			</div>
		</div>
	);

	const loadingView = (
		<>
			<div className="gap mt-5" />
			<Loader text="Loading admin referrals list..." />
		</>
	);

	const isLoading = adminService.requestLoading;
	return (
		<Tabs
			defaultActiveKey="unverifiedUsers"
			id="custom-tabs"
			className="custom-tabs mb-3"
		>
			<Tab
				eventKey="unverifiedUsers"
				title="Unverified users"
				className="custom-tabs"
			>
				{isLoading && loadingView}
				{!isLoading && (
					<VerifyClinicianPopup
						userInfo={clinicianToChangeVerification}
						showModal={showVerificationPopup}
						handleClose={handleCloseVerificationPopup}
					/>
				)}
				{!isLoading && unverifiedUsersLength === 0 && noUsersDisplay}
				{!isLoading && unverifiedUsersLength > 0 && (
					<Table striped bordered hover responsive className="table-sm">
						<thead>{unverifiedHeadersRow}</thead>
						<tbody>{unverifiedUsersTableContents}</tbody>
					</Table>
				)}
				{!isLoading && unverifiedUsersLength > nUsersWindow && (
					<div className="mb-3">
						<PaginationBar
							arrayLength={unverifiedUsersLength}
							setSliceValues={updateUnverifiedUsersSliceIndices}
							windowSize={nUsersWindow}
						/>
					</div>
				)}
			</Tab>
			<Tab eventKey="users" title="Users" className="custom-tabs">
				{isLoading && loadingView}
				{!isLoading && usersLength === 0 && noUsersDisplay}
				{!isLoading && usersLength > 0 && (
					<Table striped bordered hover responsive className="table-sm">
						<thead>{verifiedHeadersRow}</thead>
						<tbody>{usersTableContents}</tbody>
					</Table>
				)}
				{!isLoading && usersLength > nUsersWindow && (
					<div className="mb-3">
						<PaginationBar
							arrayLength={usersLength}
							setSliceValues={updateUsersSliceIndices}
							windowSize={nUsersWindow}
						/>
					</div>
				)}
			</Tab>
			<Tab
				eventKey="deactivatedUsers"
				title="Deactivated Users"
				className="custom-tabs"
			>
				{isLoading && loadingView}
				{!isLoading && deactivatedUsersLength === 0 && noUsersDisplay}
				{!isLoading && deactivatedUsersLength > 0 && (
					<Table striped bordered hover responsive className="table-sm">
						<thead>{deactivatedHeadersRow}</thead>
						<tbody>{deactivatedUsersTableContents}</tbody>
					</Table>
				)}
				{!isLoading && deactivatedUsersLength > nUsersWindow && (
					<div className="mb-3">
						<PaginationBar
							arrayLength={deactivatedUsersLength}
							setSliceValues={updateDeactivatedUsersSliceIndices}
							windowSize={nUsersWindow}
						/>
					</div>
				)}
			</Tab>
			<Tab
				eventKey="operationsUsers"
				title="Operations Users"
				className="custom-tabs"
			>
				{isLoading && loadingView}
				{!isLoading && operationsUsersLength === 0 && noUsersDisplay}
				{!isLoading && operationsUsersLength > 0 && (
					<Table striped bordered hover responsive className="table-sm">
						<thead>{operationsHeadersRow}</thead>
						<tbody>{operationsUsersTableContents}</tbody>
					</Table>
				)}
				{!isLoading && operationsUsersLength > nUsersWindow && (
					<div className="mb-3">
						<PaginationBar
							arrayLength={operationsUsersLength}
							setSliceValues={updateOperationsUsersSliceIndices}
							windowSize={nUsersWindow}
						/>
					</div>
				)}
			</Tab>
		</Tabs>
	);
}

AdminUsersTable.propTypes = {
	usersList: userProfilesPropType.isRequired,
	unverifiedUsersList: userProfilesPropType.isRequired,
	deactivatedUsersList: userProfilesPropType.isRequired,
	operationsUsersList: userProfilesPropType.isRequired,
};

const mapStateToProps = (state) => ({
	usersList: state.admin.users.users,
	unverifiedUsersList: state.admin.users.unverifiedUsers,
	deactivatedUsersList: state.admin.users.deactivatedUsers,
	operationsUsersList: state.admin.users.operationsUsers,
});

export default connect(mapStateToProps, {})(AdminUsersTable);
