import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';

function Loader({ inverse = true, text = 'Loading...' }) {
	const color = inverse ? 'white' : '#247986';
	return (
		<div className='text-center'>
			<Spinner
				animation="border"
				role="status"
				style={{
					color: color,
					height: '100px',
					width: '100px',
					margin: 'auto',
					display: 'block',
				}}
			>
				<span className="sr-only">{text}</span>
			</Spinner>
			<p className='medr-text' style={{ color }}>{text}</p>
		</div>
	);
}
Loader.propTypes = {
	text: PropTypes.string,
};

export default Loader;
