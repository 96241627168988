import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { EmailFormField } from '@components/app/parts/FormFields';
import HelpRequestPopup from '@components/app/HelpRequestPopup';
import { useUserService } from '@actions';

function HelpScreen({ authenticated }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const userService = useUserService();

	const initData = {
		email: '',
		name: '',
		subject: '',
		message: '',
	};
	const [formData, setFormData] = useState(initData);
	const [showSuccess, setShowSuccess] = useState(false);
	const { email, name, subject, message } = formData;

	const changeValues = (e) => {
		setFormData({ ...formData, [e.key]: e.value });
	};

	const handleRequestHelp = async () => {
		const requestSuccess = await userService.requestHelp(formData);
		if (!requestSuccess) return;
		setFormData(initData);
		setShowSuccess(true);
	};
	const onSubmit = (e) => {
		e.preventDefault();
		handleRequestHelp();
	};

	return (
		<div className="medr-layout text-center register-user-page">
			<HelpRequestPopup
				showModal={showSuccess}
				handleClose={() => setShowSuccess(false)}
			/>
			<Row className="medr-layout text-center">
				<Col>
					<h4>Contact the MedR Team</h4>
				</Col>
			</Row>

			<Form onSubmit={onSubmit} className="text-left">
				{authenticated ? null : (
					<>
						<Form.Group controlId="firstName" className="mt-2">
							<Form.Label className="my-0">Name</Form.Label>
							<Form.Control
								required
								type="name"
								placeholder="Enter first name"
								value={name}
								onChange={(e) =>
									changeValues({ key: 'name', value: e.target.value })
								}
							/>
						</Form.Group>
						<EmailFormField
							email={email}
							emailKey="email"
							emailLabel="User contact email"
							changeValues={changeValues}
						/>
					</>
				)}
				<Form.Group controlId="subject" className="mt-2">
					<Form.Label className="my-0">Help request subject</Form.Label>
					<Form.Control
						required
						type="name"
						placeholder="Enter topic"
						value={subject}
						onChange={(e) =>
							changeValues({ key: 'subject', value: e.target.value })
						}
					/>
				</Form.Group>
				<Form.Group controlId="message" className="mt-2">
					<Form.Label className="my-0">Request details</Form.Label>
					<Form.Control
						required
						as="textarea"
						placeholder="Enter request details"
						rows={6}
						value={message}
						onChange={(e) =>
							changeValues({ key: 'message', value: e.target.value })
						}
					/>
				</Form.Group>
				<Button
					className="sel-button mt-4 w100"
					type="submit"
					variant="primary"
				>
					Send Request
				</Button>
			</Form>
		</div>
	);
}

HelpScreen.propTypes = {
	authenticated: PropTypes.bool.isRequired,
};

export default HelpScreen;
