import { useEffect } from 'react';
import { Dispatch } from 'redux';
import { NavigateFunction } from 'react-router-dom';
import { PatientByIdResponse, ClinicianInfoByIdResponse } from '@client';
import { usePatientService, useClinicianService, useSearchService, useReferralService } from '@actions';
import { Patient, JobRole, ClinicianRoleProfile, InsuranceAgreement, ListCliniciansRequest } from '@client';


function isNullOrUndefined( value: any ): boolean {
    return value === null || value === undefined;
}

type Coordinates = {
    lat: number;
    lng: number;
}


export type ReferralEntryData = {
    // Step 1
    selectedPatient: boolean,
    patientId: number,
    currentPatient: Patient,
    currentPostcode: string,
    searchLatitude: number,
    searchLongitude: number,
    insuranceProviderId: number,

    // Step 2
    patientHistory: string,
    mainDescription: string,

    // Step 3
    jobRoleId: number,
    jobRole: JobRole,
    selectedJobRole: string,
    selectedJobSpecialty: string,
    selectedJobSubSpecialty: string,
    specifiedClinician: boolean,
    targetClinicianId: number,
    targetClinician: ClinicianRoleProfile,
};

export type successfulPxFormInput = {
    success: boolean,
    patient: Patient,
}

export type ReferralInfoInput = {
    patientHistory?: string,
    mainDescription?: string,
}

export type ReferralJobInput = {
    jobRole: JobRole,
    jobRoleId: number,
    selectedJobRole: string,
    selectedJobSpecialty: string,
    selectedJobSubSpecialty: string,
}



export type ReferralFormHandlerInput = {
    maxSteps: number,
}

export type ReferralReviewData = {
    currentPatient: Patient,
    patientHistory: string,
    mainDescription: string,
    jobRole: JobRole,
    targetClinician: ClinicianRoleProfile,
    specifiedClinician: boolean,
    insuranceProvider: InsuranceAgreement
};


export type ReferralRequestData = {
    patientId: number,
    crpId: number,
    jobRoleId: number,
    mainDescription: string,
    patientHistory: string,
    specifiedClinician: boolean,
    receiverCrpId?: number,
}

export class ReferralFormHandler {
    private patientService: PatientService;
    private clinicianService: ClinicianService;
    private searchService: SearchService;
    private referralService: ReferralService;
    private maxSteps: number;


    constructor ( input: ReferralFormHandlerInput ) {
        this.maxSteps = input.maxSteps;
        this.patientService = new usePatientService();
        this.clinicianService = new useClinicianService();
        this.searchService = new useSearchService();
        this.referralService = new useReferralService();
    }

    initialiseData(): ReferralEntryData {
        return {
            // Step 1
            selectedPatient: false,
            patientId: 0,
            currentPatient: {} as Patient,
            currentPostcode: '',
            searchLatitude: 0,
            searchLongitude: 0,
            insuranceProviderId: 0,

            // Step 2
            patientHistory: '',
            mainDescription: '',

            // Step 3
            jobRoleId: 0,
            jobRole: {} as JobRole,
            selectedJobRole: '',
            selectedJobSpecialty: '',
            selectedJobSubSpecialty: '',
            specifiedClinician: false,
            targetClinicianId: 0,
            targetClinician: {} as ClinicianRoleProfile,
        };
    }

    nextActiveStep( setActiveStep: ( data: any ) => void ): void {
        setActiveStep( ( prevStep: any ) => {
            if ( prevStep === this.maxSteps ) {
                return prevStep;
            }
            return prevStep + 1
        } );
    }

    previousActiveStep( setActiveStep: ( data: any ) => void ): void {
        setActiveStep( ( prevStep: any ) => {
            if ( prevStep === 0 ) {
                return prevStep;
            }
            return prevStep - 1
        } );
    }

    clearPatient( setReferralData: ( data: any ) => void ): void {
        this.patientService.resetCurrentPatient();
        setReferralData( ( prevData: any ) => ( {
            ...prevData,
            selectedPatient: false,
            patientId: 0,
            currentPatient: {} as Patient,
            currentPostcode: '',
            searchLatitude: 0,
            searchLongitude: 0,
            insuranceProviderId: 0,
        } ) );
    }

    // initialisation functions

    initialiseBlankReferral( setReferralData: ( data: any ) => void, setIsInitialised: ( value: boolean ) => void ): void {
        this.clearPatient( setReferralData );
        setIsInitialised( true );
    }

    async initialiseToClinicianReferral( clinicianId: number, referralData: ReferralEntryData, setReferralData: ( data: any ) => void, setIsInitialised: ( value: boolean ) => void ): Promise<void> {
        this.clearPatient( setReferralData );
        await this.selectTargetClinician( clinicianId, referralData, setReferralData, () => { } );
        setIsInitialised( true );
    }

    async initialiseToPatientReferral( patientId: number, setReferralData: ( data: any ) => void, setIsInitialised: ( value: boolean ) => void ): Promise<void> {
        this.clearPatient( setReferralData );
        await this.getSelectPatient( patientId, setReferralData );
        setIsInitialised( true );
    }


    // patient selection functions

    async getSelectPatient( patientId: number, setReferralData: ( data: any ) => void ): Promise<boolean> {
        const data: PatientByIdResponse = await this.patientService.getPxDataById( patientId );
        if ( !data ) {
            this.clearPatient( setReferralData );
            return false;
        }
        const { currentPatient } = data;
        const { address, insurancePolicy } = currentPatient;

        const insuranceProviderId = insurancePolicy ? insurancePolicy.id : 0;

        const { postcode, latitude, longitude } = address;

        setReferralData( ( prevData: any ) => ( {
            ...prevData,
            selectedPatient: true,
            patientId: patientId,
            currentPatient: currentPatient as Patient,
            currentPostcode: postcode,
            searchLatitude: latitude,
            searchLongitude: longitude,
            insuranceProviderId: insuranceProviderId,
        } ) );
        return true;
    }

    async updateSelectedPatient( patientId: number, setReferralData: ( data: any ) => void, handleNext: () => void ): Promise<void> {
        const success = await this.getSelectPatient( patientId, setReferralData );
        if ( !success ) {
            this.clearPatient( setReferralData );
            return;
        }
        handleNext();
    }

    async successfulPxForm( results: successfulPxFormInput, setReferralData: ( data: any ) => void, handleNext: () => void ): Promise<void> {
        if ( !results.success ) return;
        this.updateSelectedPatient( results.patient.id, setReferralData, handleNext );
    };


    // referral info functions
    updateReferralInfo( info: ReferralInfoInput, setReferralData: ( data: any ) => void ): void {
        setReferralData( ( prevData: ReferralEntryData ) => ( {
            ...prevData,
            ...info,
        } ) );
    }


    // referral clinician functions
    updateGPS( coordinates: Coordinates, setReferralData: ( data: any ) => void ): void {
        setReferralData( ( prevData: any ) => ( {
            ...prevData,
            searchLatitude: coordinates.lat,
            searchLongitude: coordinates.lng,
        } ) );
    }


    clearJobRole( setReferralData: ( data: any ) => void ): void {
        setReferralData( ( prevData: any ) => ( {
            ...prevData,
            jobRoleId: 0,
            jobRole: {} as JobRole,
            selectedJobRole: '',
            selectedJobSpecialty: '',
            selectedJobSubSpecialty: '',
        } ) );
    }

    setJobRole( jobRoleInput: ReferralJobInput, setReferralData: ( data: any ) => void ): void {
        if ( jobRoleInput.jobRoleId === 0 ) {
            return;
        }
        const { jobRole } = jobRoleInput;
        setReferralData( ( prevData: any ) => ( {
            ...prevData,
            jobRoleId: jobRole.id,
            jobRole: jobRole as JobRole,
            selectedJobRole: jobRole.jobRole,
            selectedJobSpecialty: jobRole.specialty,
            selectedJobSubSpecialty: jobRole.subSpecialty,
        } ) );
    }

    selectRefreshData( referralData: ReferralEntryData ): ListCliniciansRequest {
        return {
            roleId: referralData.jobRoleId,
            lat: referralData.searchLatitude,
            lng: referralData.searchLongitude,
            jobRoleSelected: referralData.selectedJobRole,
            jobSpecialtySelected: referralData.selectedJobSpecialty,
            insuranceProviderId: referralData.insuranceProviderId,
        }
    }

    async refreshClinicians( referralData: ReferralEntryData ): Promise<boolean> {
        console.log( 'refreshing clinicians: ', this.selectRefreshData( referralData ) );
        return await this.clinicianService.getCliniciansList( this.selectRefreshData( referralData ) );
    }

    clearTargetClinician( setReferralData: ( data: any ) => void ): void {
        setReferralData( ( prevData: any ) => ( {
            ...prevData,
            specifiedClinician: false,
            targetClinicianId: 0,
            targetClinician: {} as ClinicianRoleProfile,
        } ) );
    }

    async selectTargetClinician( clinicianId: number, referralData: ReferralEntryData, setReferralData: ( data: any ) => void, handleNext: () => void ): Promise<void> {
        const data: ClinicianInfoByIdResponse = await this.clinicianService.getClinicianDataById( clinicianId )
        const { crp } = data;
        if ( referralData.jobRoleId === 0 ) {
            const jobRole = crp.userProfile.jobRole[ 0 ];
            setReferralData( ( prevData: any ) => ( {
                ...prevData,
                specifiedClinician: true,
                targetClinicianId: crp.id,
                targetClinician: crp as ClinicianRoleProfile,
                jobRoleId: jobRole.id,
                jobRole: jobRole as JobRole,
                selectedJobRole: jobRole.jobRole,
                selectedJobSpecialty: jobRole.specialty,
                selectedJobSubSpecialty: jobRole.subSpecialty,
            } ) );
            handleNext();
            return;
        }

        const jobRoleIn = crp.userProfile.jobRole.some( ( jr: JobRole ) => jr.id === referralData.jobRoleId );
        if ( jobRoleIn ) {
            setReferralData( ( prevData: any ) => ( {
                ...prevData,
                specifiedClinician: true,
                targetClinicianId: crp.id,
                targetClinician: crp as ClinicianRoleProfile,
            } ) );
            handleNext();
            return;
        }
        // TODO: Handle error
    }

    setOpenReferral( referralData: ReferralEntryData, setReferralData: ( data: any ) => void, handleNext: () => void ): void {
        if ( referralData.jobRoleId === 0 ) {
            return;
        }
        setReferralData( ( prevData: any ) => ( {
            ...prevData,
            specifiedClinician: false,
            targetClinicianId: 0,
            targetClinician: {} as ClinicianRoleProfile,
        } ) );
        handleNext();
    }


    validateReferralData( referralData: ReferralEntryData ): boolean {
        if ( isNullOrUndefined( referralData.currentPatient ) ) {
            throw new Error( 'Current patient data is missing' );
        }
        if ( isNullOrUndefined( referralData.patientHistory ) ) {
            throw new Error( 'Patient history is missing' );
        }
        if ( isNullOrUndefined( referralData.mainDescription ) ) {
            throw new Error( 'Main description is missing' );
        }
        if ( isNullOrUndefined( referralData.jobRole ) ) {
            throw new Error( 'Job role is missing' );
        }
        if ( isNullOrUndefined( referralData.specifiedClinician ) ) {
            throw new Error( 'Specified clinician is missing' );
        }

        const data = {
            currentPatient: referralData.currentPatient,
            patientHistory: referralData.patientHistory,
            mainDescription: referralData.mainDescription,
            jobRole: referralData.jobRole,
            targetClinician: referralData.targetClinician,
            specifiedClinician: referralData.specifiedClinician,
            insuranceProvider: referralData.currentPatient.insurancePolicy
        };
        return true;
    }

    getRequestData( crpId: number, referralData: ReferralEntryData ): ReferralRequestData {
        const { currentPatient, patientHistory, mainDescription, jobRole, specifiedClinician, targetClinician } = referralData;
        let data = {
            patientId: currentPatient.id,
            crpId: crpId,
            jobRoleId: jobRole.id,
            mainDescription: mainDescription,
            patientHistory: patientHistory,
            specifiedClinician: specifiedClinician,
        };

        if ( specifiedClinician ) {
            data = { ...data, receiverCrpId: targetClinician.id };
        }
        return data;
    }

    async submitReferral( crpId: number, referralData: ReferralEntryData, setReferralMade: ( value: boolean ) => void ): Promise<boolean> {
        const data = this.getRequestData( crpId, referralData );
        const success = await this.referralService.createReferral( data );
        setReferralMade( success );
    }

}