import { useState } from 'react';
import { useDelegateService } from '@actions';

const useDelegateUserCard = (delegate) => {
	const delegateService = useDelegateService();

	const [showDectivateModal, setShowDeactivateModal] = useState(false);
	const toggleShowDeactivate = () => setShowDeactivateModal((prev) => !prev);

	const deactivateDelegate = async () => {
		const success = await delegateService.deactivateDelegateUser(delegate);
		if (!success) return;
		setShowDeactivateModal(false);
	};

	const updatePermission = async (data) => {
		const payload = {
			delegateId: delegate.id,
			delegateUserId: delegate.delegateUserProfile.id,
			permissions: data,
		};
		await delegateService.updateDelegateUserPermission(payload);
	};

	return {
		deactivateDelegate,
		updatePermission,
		showDectivateModal,
		toggleShowDeactivate,
	};
};

export default useDelegateUserCard;
