import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import { useAuthService } from '@actions';

function LandingPageScreen() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const authService = useAuthService();

	authService.RedirectIfAuthDetailsAvailable();

	return (
		<div
			className="medr-layout text-center landing-page"
			style={{ maxWidth: '650px', marginLeft: 'auto', marginRight: 'auto' }}
		>
			<Col>
				<Row className="medr-layout text-center">
					<Col>
						<h1>Welcome to MedR</h1>
					</Col>
				</Row>
				<Row className="medr medr-layout description">
					<Col>
						At MedR we aim to bridge the gap between clinicians and transform
						the way healthcare professionals collaborate. In the ever-evolving
						landscape of healthcare, effective communication and seamless
						referrals are paramount. Our easy to use platform empowers
						clinicians to strengthen their relationships, streamline patient
						referrals, and ultimately enhance the quality of care. Join us on
						this journey to create a healthcare ecosystem where collaboration
						thrives, and patients receive the best possible outcomes.
					</Col>
				</Row>
				<Row className="py-2 sel-button-row">
					<Col className="sel-button-row mb-3 mb-md-0" md={6}>
						<Button className="sel-button" variant="primary" href="/login">
							Login
						</Button>
					</Col>
					<Col className="sel-button-row">
						<Button className="sel-button" variant="primary" href="/register">
							Register as a clinician
						</Button>
					</Col>
				</Row>
			</Col>
		</div>
	);
}

export default LandingPageScreen;
