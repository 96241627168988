import { useEffect } from 'react';
import { Dispatch } from 'redux';
import { NavigateFunction } from 'react-router-dom';
import { getAuthApiClient } from '@/actions/apiActions';
import * as CONSTS from '@/constants/referralConstants';
import { ReferralToggleCompleteRequest, ReferralsListForUserResponse, ReferralToggleAcceptedRequest, ReferralCreateResponse, ReferralCreateRequest, ReferralsNotificationsForUserResponse } from '@client';
import { redirectIfInvalidToken } from './authService';

type ApiClientAuth = ReturnType<typeof getAuthApiClient>;


export class ReferralService {
    private dispatch: Dispatch;
    private navigate: NavigateFunction;
    private appAuthClient: ApiClientAuth;

    constructor ( dispatch: Dispatch, navigate: NavigateFunction ) {
        this.dispatch = dispatch;
        this.navigate = navigate;
        this.appAuthClient = getAuthApiClient();
    }

    async getClinicianReferralsList(): Promise<boolean> {
        this.dispatch( { type: CONSTS.CLINICIAN_REFERRALS_ACTION.REQUEST } );

        try {
            const data: ReferralsNotificationsForUserResponse = await this.appAuthClient.referrals.referralsListRetrieve();
            this.dispatch( { type: CONSTS.CLINICIAN_REFERRALS_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: CONSTS.CLINICIAN_REFERRALS_ACTION.FAIL } );
            return false;
        }
    }

    async createReferral( inputData: ReferralCreateRequest ): Promise<boolean> {
        this.dispatch( { type: CONSTS.CREATE_REFERRAL_ACTION.REQUEST } );

        try {
            const data: ReferralCreateResponse = await this.appAuthClient.referrals.referralsCreateCreate( inputData );
            this.dispatch( { type: CONSTS.CREATE_REFERRAL_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: CONSTS.CREATE_REFERRAL_ACTION.FAIL } );
            return false;
        }
    }

    async toggleAcceptReferral( rxId: number, inputData: ReferralToggleAcceptedRequest ): Promise<boolean> {
        this.dispatch( { type: CONSTS.REFERRAL_TOGGLE_ACCEPT_ACTION.REQUEST } );

        try {
            const data: ReferralsListForUserResponse = await this.appAuthClient.referrals.referralsToggleAcceptCreate( rxId, inputData );
            this.dispatch( { type: CONSTS.REFERRAL_TOGGLE_ACCEPT_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: CONSTS.REFERRAL_TOGGLE_ACCEPT_ACTION.FAIL } );
            return false;
        }
    }

    async cancelReferral( rxId: number ): Promise<boolean> {
        this.dispatch( { type: CONSTS.REFERRAL_CANCEL_ACTION.REQUEST } );

        try {
            const data: ReferralsListForUserResponse = await this.appAuthClient.referrals.referralsDeleteDestroy( rxId );
            this.dispatch( { type: CONSTS.REFERRAL_CANCEL_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: CONSTS.REFERRAL_CANCEL_ACTION.FAIL } );
            return false;
        }
    }

    async toggleCompleteReferral( rxId: number, inputData: ReferralToggleCompleteRequest ): Promise<boolean> {
        this.dispatch( { type: CONSTS.REFERRAL_TOGGLE_COMPLETE_ACTION.REQUEST } );

        try {
            const data: ReferralsListForUserResponse = await this.appAuthClient.referrals.referralsCompleteCreate( rxId, inputData );
            this.dispatch( { type: CONSTS.REFERRAL_TOGGLE_COMPLETE_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: CONSTS.REFERRAL_TOGGLE_COMPLETE_ACTION.FAIL } );
            return false;
        }
    }

}