import React, { useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useSearchService } from '@actions';

function PostcodeSearch({ lat, lng, currentPostcode = '', changeValues }) {
	const valueKeys = {
		postcodeKey: 'postcode',
		latKey: 'searchLatitude',
		lngKey: 'searchLongitude',
	};

	const searchService = useSearchService();

	const handleSetSearchpostcode = async (postCode) => {
		const result = await searchService.getSearchPostcodeData({ postCode });
		if (!result) {
			setError(true);
			return;
		}

		if (
			!result.lat ||
			!result.lng ||
			result.lat === undefined ||
			result.lng === undefined
		) {
			setError(true);
			return;
		}
		changeValues({
			[latKey]: result.lat,
			[lngKey]: result.lng,
			[postcodeKey]: result.postcode,
		});
	};

	const { postcodeKey, latKey, lngKey } = valueKeys;

	const [data, setData] = useState({ postcode: currentPostcode, lat, lng });
	const [error, setError] = useState(false);

	const onChange = (e) => {
		setData((prevState) => ({
			...prevState,
			postcode: e.target.value.toUpperCase(),
		}));
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		setError(false);
		await handleSetSearchpostcode(data.postcode);
	};

	return (
		<Form onSubmit={onSubmit}>
			<Row>
				<Col>
					<Form.Group controlId="postcode">
						<Form.Control
							required
							type="name"
							placeholder={
								currentPostcode !== '' ? currentPostcode : 'Enter postcode'
							}
							name="postcode"
							value={data.postcode}
							onChange={(e) => onChange(e)}
							isInvalid={error}
						/>
						<Form.Control.Feedback type="invalid">
							Error locating postcode
						</Form.Control.Feedback>
					</Form.Group>
				</Col>
				<Col>
					<Button type="submit" variant="primary" className="mt-0">
						Search
					</Button>
				</Col>
			</Row>
		</Form>
	);
}

PostcodeSearch.propTypes = {
	lat: PropTypes.number.isRequired,
	lng: PropTypes.number.isRequired,
	currentPostcode: PropTypes.string,
	changeValues: PropTypes.func.isRequired,
};

export default PostcodeSearch;
