import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import '../index.css';

import Header from '@components/app/Header';
import Footer from '@components/app/Footer';
import Message from '@components/app/Message';
import { useAuthService, checkIsAuthenticated, useUserService } from '@actions';
import { MEDR_APP_VERSION } from '@/Environment';

function Layout({ children }) {
	const authService = useAuthService();
	const userService = useUserService();
	const userProfileData = useSelector((state) => state.profile.userProfile);

	const checkCurrentAppVersion = async () => {
		const appVersion = await authService.getAppVersion();
		if (appVersion === MEDR_APP_VERSION) return;
		window.location.reload();
	};

	const handleCheckAuth = async () => {
		if (checkIsAuthenticated()) {
			const isAuth = await authService.checkAuth();
			if (isAuth && userProfileData.id === null) {
				await userService.loadUserProfile();
			}
		}
	};

	useEffect(() => {
		checkCurrentAppVersion();
		return () => {};
	}, []);

	useEffect(() => {
		handleCheckAuth();
		return () => {};
	}, []);

	return (
		<>
			<Header />
			<div id="mainContainer" className="py-3 medr-body">
				<Container>
					<Message />
					{children}
				</Container>
			</div>
			<Footer />
		</>
	);
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default connect(null, {})(Layout);
