import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { getAddressMultiline } from '@/actions/general';
import { clinicanProfilePropType } from '@constants/propTypes';
import { useAuthService } from '@actions';

function ClinicsDisplayCard({
	values,
	editable,
	removeClinic,
	editClinic,
	isDoctor,
}) {
	const authService = useAuthService();

	const [showEditForm, setShowEditForm] = useState(false);
	const [formData, setFormData] = useState(values);
	const [gpsError, setGpsError] = useState(false);

	useEffect(() => {
		const checkAddress = async () => {
			const addressValid = await authService.registerCheckClinic(
				formData.address
			);
			setGpsError(!addressValid);
		};
		checkAddress();
	}, [formData.address]);

	if (values === null || values === undefined) {
		return (
			<div className="medr rounded">
				<Row>
					<Col>
						<h4>Error retrieving clinic details</h4>
					</Col>
				</Row>
			</div>
		);
	}

	const { clinicName, centreName, address } = values;
	const clinicRef = `clinic${uuidv4()}`;

	const displayAddress = (
		<>
			<Col sm={6}>
				<h4>{clinicName}</h4>
				<h6>{centreName}</h6>
			</Col>
			<Col sm={6}>{getAddressMultiline(address)}</Col>
		</>
	);

	const toggleEditForm = () => {
		setShowEditForm((prev) => !prev);
		setFormData(values);
	};

	const sendEditClinic = (formData) => {
		editClinic(formData);
		setShowEditForm(false);
	};

	const updateForm = (
		<Form className="w100">
			<Form.Group controlId="clinicName">
				<Form.Label className="my-0 medr-text-imp">Clinic Name</Form.Label>
				<Form.Control
					required
					type="name"
					placeholder="Enter clinic name"
					className="mb-3"
					value={formData.clinicName}
					onChange={(e) =>
						setFormData({ ...formData, clinicName: e.target.value })
					}
				/>
			</Form.Group>
			{isDoctor && (
				<Form.Group controlId="centerName">
					<Form.Label className="my-0  medr-text-imp">
						Hospital/practice
					</Form.Label>
					<Form.Control
						required
						type="name"
						placeholder="Enter hospital/practice"
						className="mb-3"
						value={formData.centreName}
						onChange={(e) =>
							setFormData({ ...formData, centreName: e.target.value })
						}
					/>
				</Form.Group>
			)}

			<Form.Group controlId="firstLine">
				<Form.Label className="my-0 medr-text-imp">First line</Form.Label>
				<Form.Control
					required
					type="name"
					placeholder="Enter first line of address"
					className="mb-3"
					value={formData.address.firstLine}
					onChange={(e) =>
						setFormData({
							...formData,
							address: { ...address, firstLine: e.target.value },
						})
					}
				/>
			</Form.Group>

			<Form.Group controlId="city">
				<Form.Label className="my-0 medr-text-imp">City</Form.Label>
				<Form.Control
					required
					type="name"
					placeholder="Enter city"
					className="mb-3"
					value={formData.address.city}
					onChange={(e) =>
						setFormData({
							...formData,
							address: { ...address, city: e.target.value },
						})
					}
				/>
			</Form.Group>

			<Form.Group controlId="postcode">
				<Form.Label className="my-0 medr-text-imp">Postcode</Form.Label>
				<Form.Control
					required
					type="name"
					placeholder="Enter postcode"
					className="mb-3"
					value={formData.address.postcode}
					onChange={(e) =>
						setFormData({
							...formData,
							address: { ...address, postcode: e.target.value },
						})
					}
					isInvalid={gpsError}
				/>
				<Form.Control.Feedback type="invalid" className="medr-text-imp">
					Address not found. Please check details.
				</Form.Control.Feedback>
			</Form.Group>
			<Row>
				<Col className="text-center mt-4">
					<Button
						className="sel-button sel-button-inv  w100"
						onClick={() => sendEditClinic(formData)}
					>
						Update clinic information
					</Button>
				</Col>
			</Row>
		</Form>
	);

	return (
		<div className="medr-rounded-inv mb-2" id={clinicRef}>
			<Row className="text-left p-3">
				{showEditForm ? updateForm : displayAddress}
			</Row>
			{editable && (
				<div
					className="row p-3"
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<div
						className="col-md-6 mt-1"
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<Button
							variant="info"
							className="sel-button p-1"
							id={values.id}
							onClick={() => toggleEditForm()}
						>
							{showEditForm ? 'Discard changes' : 'Edit'}
						</Button>
					</div>
					<div
						className="col-md-6 mt-1"
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<Button
							variant="danger"
							className="sel-button p-1"
							id={values.id}
							onClick={() => removeClinic(values)}
						>
							Remove
						</Button>
					</div>
				</div>
			)}
		</div>
	);
}

ClinicsDisplayCard.propTypes = {
	values: clinicanProfilePropType.isRequired,
	editable: PropTypes.bool.isRequired,
	removeClinic: PropTypes.func.isRequired,
	editClinic: PropTypes.func.isRequired,
	isDoctor: PropTypes.bool.isRequired,
};

export default ClinicsDisplayCard;
