import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import ClinicianCard from './ClinicianCard';
import Loader from '@components/app/Loader';
import { useMapViewContext } from './MapViewContext';


function ClinicianPanel() {
	const { clinicians, hoveredClinicianUserProfileIds } = useMapViewContext();

	const isLoading = useSelector((state) => state.clinicians.loadingClinicians);

	const noClinicianCard = (
		<Card className="mb-2 rounded d-flex flex-fill" style={{ padding: '1rem' }}>
			<Card.Title>No Clinicians Available</Card.Title>
			<Card.Subtitle className="text-muted">
				Unfortunately, we couldn't find any clinicians that fit your search.
				Please modify your search criteria and try again.
			</Card.Subtitle>
		</Card>
	);

	const cards =
		clinicians &&
		clinicians.map((clinician) => (
			<ClinicianCard
				key={clinician.id}
				clinician={clinician}
				hoveredClinicianUserProfileIds={hoveredClinicianUserProfileIds}
			/>
		));

	return (
		<div>
			{isLoading && <Loader text="Finding clinicians..." />}
			{!isLoading && clinicians.length === 0 && noClinicianCard}
			{!isLoading && cards}
		</div>
	);
}


export default ClinicianPanel;
