/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DelegateUserRegistrationInfoResponse } from '../models/DelegateUserRegistrationInfoResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class GetVerifyDelegateService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Verify delegate user.
     * @param code
     * @param time
     * @param user
     * @returns DelegateUserRegistrationInfoResponse
     * @throws ApiError
     */
    public getVerifyDelegateUserTimeCodeRetrieve(
        code: string,
        time: string,
        user: string,
    ): CancelablePromise<DelegateUserRegistrationInfoResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/get-verify-delegate/user={user}&time={time}&code={code}/',
            path: {
                'code': code,
                'time': time,
                'user': user,
            },
        });
    }
}
