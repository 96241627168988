/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CliniciansListForAdminResponse } from '../models/CliniciansListForAdminResponse';
import type { CreateAdminUserRequest } from '../models/CreateAdminUserRequest';
import type { CreateAdminUserResponse } from '../models/CreateAdminUserResponse';
import type { GetPlatformStatisticsResponse } from '../models/GetPlatformStatisticsResponse';
import type { InsurerListForAdminResponse } from '../models/InsurerListForAdminResponse';
import type { PatientsListForAdminResponse } from '../models/PatientsListForAdminResponse';
import type { ReferralsListForAdminResponse } from '../models/ReferralsListForAdminResponse';
import type { SetUserVerificationRequest } from '../models/SetUserVerificationRequest';
import type { SetUserVerificationResponse } from '../models/SetUserVerificationResponse';
import type { UserListForAdminResponse } from '../models/UserListForAdminResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AdminService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Returns a list of clinician profiles for logged in admin users.
     * @returns CliniciansListForAdminResponse
     * @throws ApiError
     */
    public adminClinicianRoleProfilesRetrieve(): CancelablePromise<CliniciansListForAdminResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/admin/clinician-role-profiles/',
        });
    }
    /**
     * Return a list of insurers.
     * @returns InsurerListForAdminResponse
     * @throws ApiError
     */
    public adminInsuranceAgreementsRetrieve(): CancelablePromise<InsurerListForAdminResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/admin/insurance-agreements/',
        });
    }
    /**
     * Updates the users password while logged in
     * @param requestBody
     * @returns CreateAdminUserResponse
     * @throws ApiError
     */
    public adminNewAdminUserCreate(
        requestBody: CreateAdminUserRequest,
    ): CancelablePromise<CreateAdminUserResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/admin/new-admin-user/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Returns list of patients for logged in admin user
     * @returns PatientsListForAdminResponse
     * @throws ApiError
     */
    public adminPatientsRetrieve(): CancelablePromise<PatientsListForAdminResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/admin/patients/',
        });
    }
    /**
     * Get platform statistics.
     * @returns GetPlatformStatisticsResponse
     * @throws ApiError
     */
    public adminPlatformStatsRetrieve(): CancelablePromise<GetPlatformStatisticsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/admin/platform-stats/',
        });
    }
    /**
     * Returns referral lists for the logged in admin user
     * @returns ReferralsListForAdminResponse
     * @throws ApiError
     */
    public adminReferralsRetrieve(): CancelablePromise<ReferralsListForAdminResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/admin/referrals/',
        });
    }
    /**
     * Set user verification status.
     * @param userProfileId
     * @param requestBody
     * @returns SetUserVerificationResponse
     * @throws ApiError
     */
    public adminSetVerificationUserProfileCreate(
        userProfileId: number,
        requestBody: SetUserVerificationRequest,
    ): CancelablePromise<SetUserVerificationResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/admin/set-verification/user-profile/{user_profile_id}/',
            path: {
                'user_profile_id': userProfileId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Return a list of users to logged in admin users.
     * @returns UserListForAdminResponse
     * @throws ApiError
     */
    public adminUserProfilesRetrieve(): CancelablePromise<UserListForAdminResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/admin/user-profiles/',
        });
    }
}
