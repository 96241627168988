import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
	userProfilePropType,
	boolPropType,
	funcPropType,
} from '@constants/propTypes';
import { useAdminService } from '@actions';

function VerifyClinicianPopup({ userInfo = null, showModal, handleClose }) {
	const adminService = useAdminService();
	if (
		!userInfo ||
		userInfo === undefined ||
		!userInfo.userProfile ||
		userInfo.userProfile === undefined
	) {
		return null;
	}

	const { userProfile, setVerified } = userInfo;
	const { id } = userProfile;

	const sendVerifcationData = async () => {
		await adminService.setUserVerified(id, setVerified);
		handleClose();
	};

	return (
		<Modal show={showModal} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title style={{ color: 'black' }}>
					Set user to verified!
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<ul>
					<li>Title: {userProfile.title}</li>
					<li>First Name: {userProfile.firstName}</li>
					<li>Last Name: {userProfile.lastName}</li>
					<li>Email: {userProfile.user.email}</li>
					<li>Job Role: {userProfile.jobRole[0].jobTitle} </li>
					<li>
						*** Registration Body: {userProfile.jobRole[0].registrationBody}
					</li>
					<li>*** Registration Number: {userProfile.registrationNumber}</li>
				</ul>
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant={setVerified ? 'primary' : 'danger'}
					onClick={() => sendVerifcationData(id, setVerified)}
				>
					{setVerified ? 'Verify' : 'Unverify'}
				</Button>
				<Button variant="secondary" onClick={handleClose}>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

VerifyClinicianPopup.propTypes = {
	userInfo: PropTypes.shape({
		userProfile: userProfilePropType,
		setVerified: boolPropType,
	}),
	showModal: boolPropType.isRequired,
	handleClose: funcPropType.isRequired,
};

export default VerifyClinicianPopup;
