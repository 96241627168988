import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import axios from 'axios';
import store from './store';
import './index.css';
import './bootstrap.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { unauthResponseClear } from '@actions';
import * as Sentry from '@sentry/react';
import { initGA, logPageView } from './utils/GoogleAnalytics.jsx';

Sentry.init({
	dsn: import.meta.env.REACT_APP_SENTRY_DNS,
	authToken: import.meta.env.REACT_APP_SENTRY_AUTH_TOKEN,
	release: { name: import.meta.env.REACT_APP_SENTRY_RELEASE },
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration(),
	],
	enabled: window.location.origin !== 'http://localhost:3000',
	// Tracing
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (window.location.origin === 'http://localhost:3000') {
	axios.defaults.baseURL = 'http://localhost:8000/';
} else {
	axios.defaults.baseURL = `${window.location.origin}`;
}

axios.interceptors.response.use(
	(response) => response,
	(error) => {
		if (error.response && error.response.status === 401) {
			unauthResponseClear();
			window.location.href = '/login';
		}
		return Promise.reject(error);
	}
);

initGA();
logPageView();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Provider store={store}>
		<App />
	</Provider>
);

reportWebVitals();
