import React, { useEffect, useState } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { referralsPropType } from '@constants/propTypes';
import PaginationBar from '../../app/PaginationBar';
import ReferralBar from '../ReferralBar';
import useFilterReferralsList from '@hooks/referrals/useFilterReferralsList';
import { useSelector } from 'react-redux';
import Loader from '@components/app/Loader';

function ReferralSentCardsDisplay({ referralsList }) {
	const nReferralsWindow = 25;
	const start = 0;
	const end = nReferralsWindow;
	const [refPending, setRefPending] = useState([]);
	const [refAccepted, setRefAccepted] = useState([]);
	const [refCompleted, setRefCompleted] = useState([]);
	const [seeAccepted, setSeeAccepted] = useState(false);
	const [seeCompleted, setSeeCompleted] = useState(false);

	const isLoading = useSelector((state) => state.referrals.loading);

	useEffect(() => {
		if (referralsList === null || referralsList === undefined) {
			return;
		}
		const refPend = referralsList.filter(
			(referral) => referral.targetHasAccepted === false
		);
		const refAcc = referralsList.filter(
			(referral) =>
				referral.targetHasAccepted === true && referral.completed === false
		);
		const refComp = referralsList.filter(
			(referral) =>
				referral.targetHasAccepted === true && referral.completed === true
		);
		const sortedPending = refPend; // .sort((a, b) => new Date(a.createdOn) - new Date(b.createdOn));
		const sortedAccepted = refAcc; // .sort((a, b) => new Date(a.createdOn) - new Date(b.createdOn));
		const sortedCompleted = refComp; // .sort((a, b) => new Date(a.createdOn) - new Date(b.createdOn));
		setRefPending(sortedPending);
		setRefAccepted(sortedAccepted);
		setRefCompleted(sortedCompleted);
	}, [referralsList]);

	const [
		referralsPending,
		updateReferralsPendingSlice,
		referralsPendingLength,
	] = useFilterReferralsList(refPending, start, end);
	const [
		referralsAccepted,
		updateReferralsAcceptedSlice,
		referralsAcceptedLength,
	] = useFilterReferralsList(refAccepted, start, end);
	const [
		referralsCompleted,
		updateReferralsCompletedSlice,
		referralsCompletedLength,
	] = useFilterReferralsList(refCompleted, start, end);

	const headers = (
		<div className="py-1" style={{ marginLeft: '12px' }}>
			<div className="align-items-center p-0 referral-bar referral-bar-header d-none d-md-flex">
				<div className="flex-fill text-start referral-bar-item lines-max-1 referral-bar-item-1">
					<h4 className="m-0">Patient</h4>
				</div>
				<div className="flex-fill text-left referral-bar-item lines-max-1 referral-bar-item-2">
					<h4 className="m-0">DOB</h4>
				</div>
				<div className="flex-fill text-left referral-bar-item lines-max-1 referral-bar-item-3">
					<h4 className="m-0">Sex</h4>
				</div>
				<div className="flex-fill text-left referral-bar-item lines-max-1 referral-bar-item-4">
					<h4 className="m-0">Clinician</h4>
				</div>
				<div className="flex-fill text-left referral-bar-item lines-max-1 referral-bar-item-5">
					<h4 className="m-0">&nbsp;</h4>
				</div>
			</div>
		</div>
	);

	const pendingHeaders = (
		<>
			<h4 className="mt-2 p-1">Pending referrals</h4>
			{headers}
		</>
	);

	const acceptedHeaders = (
		<>
			<h4 className="mt-2 p-1">Accepted referrals</h4>
			{headers}
		</>
	);

	const completedHeaders = (
		<>
			<h4 className="mt-2 p-1">Completed referrals</h4>
			<div className="py-1" style={{ marginLeft: '12px' }}>
				<div className="align-items-center p-0 referral-bar referral-bar-header d-none d-md-flex">
					<div className="flex-fill text-start referral-bar-item lines-max-1 referral-bar-item-1">
						<h4 className="m-0">Patient</h4>
					</div>
					<div className="flex-fill text-left referral-bar-item lines-max-1 referral-bar-item-2">
						<h4 className="m-0">DOB</h4>
					</div>
					<div className="flex-fill text-left referral-bar-item lines-max-1 referral-bar-item-4">
						<h4 className="m-0">Clinician</h4>
					</div>
					<div className="flex-fill text-left referral-bar-item lines-max-1 referral-bar-item-5">
						<h4 className="m-0"> </h4>
					</div>
					<div className="flex-fill text-left referral-bar-item lines-max-1">
						<h4 className="m-0"> </h4>
					</div>
				</div>
			</div>
		</>
	);

	const noReferralsDisplay = (
		<div className="center-box-parent">
			<div className="center-box-child">
				<h4 className="medr-text-inv">No referrals to display at the moment</h4>
			</div>
		</div>
	);

	return (
		<>
			{isLoading && (
				<>
					<div className="gap mt-5" />
					<Loader text="Loading referrals..." />
				</>
			)}
			{!isLoading &&
				referralsPendingLength === 0 &&
				referralsAcceptedLength === 0 &&
				noReferralsDisplay}
			{!isLoading && referralsPending.length > 0 && pendingHeaders}
			{!isLoading &&
				referralsPending.map((referral) => (
					<div className="p-1" key={uuidv4()}>
						<ReferralBar key={uuidv4()} referral={referral} />
					</div>
				))}
			{!isLoading && referralsPendingLength > nReferralsWindow && (
				<>
					<hr className="medr" />
					<PaginationBar
						arrayLength={referralsPendingLength}
						setSliceValues={updateReferralsPendingSlice}
						windowSize={nReferralsWindow}
					/>
				</>
			)}

			{!isLoading && referralsAcceptedLength > 0 && (
				<Row className="text-center mt-4">
					<Col>
						<Button
							className="sel-button w50"
							variant={seeAccepted ? 'info' : 'primary'}
							onClick={() => setSeeAccepted((prev) => !prev)}
						>
							{seeAccepted ? 'Hide' : 'See'} accepted referrals
						</Button>
					</Col>
				</Row>
			)}

			{!isLoading &&
				seeAccepted &&
				referralsAccepted.length > 0 &&
				acceptedHeaders}
			{!isLoading &&
				seeAccepted &&
				referralsAccepted.map((referral) => (
					<div className="p-1" key={uuidv4()}>
						<ReferralBar key={uuidv4()} referral={referral} />
					</div>
				))}
			{!isLoading &&
				seeAccepted &&
				referralsAcceptedLength > nReferralsWindow && (
					<>
						<hr className="medr" />
						<PaginationBar
							arrayLength={referralsAcceptedLength}
							setSliceValues={updateReferralsAcceptedSlice}
							windowSize={nReferralsWindow}
						/>
					</>
				)}

			{!isLoading && referralsCompletedLength > 0 && (
				<Row className="text-center mt-4">
					<Col>
						<Button
							className="sel-button w50"
							variant={seeCompleted ? 'info' : 'primary'}
							onClick={() => setSeeCompleted((prev) => !prev)}
						>
							{seeCompleted ? 'Hide' : 'See'} completed referrals
						</Button>
					</Col>
				</Row>
			)}

			{!isLoading &&
				seeCompleted &&
				referralsCompleted.length > 0 &&
				completedHeaders}
			{!isLoading &&
				seeCompleted &&
				referralsCompleted.map((referral) => (
					<div className="p-1" key={uuidv4()}>
						<ReferralBar key={uuidv4()} referral={referral} />
					</div>
				))}
			{!isLoading &&
				seeCompleted &&
				referralsCompletedLength > nReferralsWindow && (
					<>
						<hr className="medr" />
						<PaginationBar
							arrayLength={referralsCompletedLength}
							setSliceValues={updateReferralsCompletedSlice}
							windowSize={nReferralsWindow}
						/>
					</>
				)}
		</>
	);
}

ReferralSentCardsDisplay.propTypes = {
	referralsList: referralsPropType.isRequired,
};

export default ReferralSentCardsDisplay;
