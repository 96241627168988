/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckClinicAddressRequest } from '../models/CheckClinicAddressRequest';
import type { CheckClinicAddressResponse } from '../models/CheckClinicAddressResponse';
import type { CheckRegisteringEmailRequest } from '../models/CheckRegisteringEmailRequest';
import type { CheckRegisteringEmailResponse } from '../models/CheckRegisteringEmailResponse';
import type { ReceiveEmailVerificationResponse } from '../models/ReceiveEmailVerificationResponse';
import type { RegisterUserRequest } from '../models/RegisterUserRequest';
import type { RegisterUserResponse } from '../models/RegisterUserResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class RegisterService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Registers user, creates user profile and main clinician profile.
     * @param requestBody
     * @returns RegisterUserResponse
     * @throws ApiError
     */
    public registerCreate(
        requestBody: RegisterUserRequest,
    ): CancelablePromise<RegisterUserResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/register/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Checks for duplicate email in users.
     * @param requestBody
     * @returns CheckClinicAddressResponse
     * @throws ApiError
     */
    public registerCheckClinicCreate(
        requestBody: CheckClinicAddressRequest,
    ): CancelablePromise<CheckClinicAddressResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/register/check-clinic/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Checks for duplicate email in users.
     * @param requestBody
     * @returns CheckRegisteringEmailResponse
     * @throws ApiError
     */
    public registerCheckEmailCreate(
        requestBody: CheckRegisteringEmailRequest,
    ): CancelablePromise<CheckRegisteringEmailResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/register/check-email/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Updates the specified user to verified.
     * @param code
     * @param time
     * @param user
     * @returns ReceiveEmailVerificationResponse
     * @throws ApiError
     */
    public registerVerifyEmailUserTimeCodeCreate(
        code: string,
        time: string,
        user: string,
    ): CancelablePromise<ReceiveEmailVerificationResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/register/verify-email/user={user}&time={time}&code={code}/',
            path: {
                'code': code,
                'time': time,
                'user': user,
            },
        });
    }
}
