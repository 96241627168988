/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OpsAuditLogs } from '../models/OpsAuditLogs';
import type { SignupData } from '../models/SignupData';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class OperationsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Return audit logs.
     * @returns OpsAuditLogs
     * @throws ApiError
     */
    public operationsAuditLogRetrieve(): CancelablePromise<OpsAuditLogs> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/operations/audit-log/',
        });
    }
    /**
     * Return a list of users with creation dates.
     * @returns SignupData
     * @throws ApiError
     */
    public operationsUserListRetrieve(): CancelablePromise<SignupData> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/operations/user-list/',
        });
    }
}
