import Cookies from 'js-cookie';
import { AppClient } from '@/client/AppClient';


const baseUrl = (): string => {
    if (window.location.origin === "http://localhost:3000") {
        return "http://localhost:8000/";
    } 
    return `${window.location.origin}`;
}

const formatToken = (token: string): string => {
    return `Token ${token}`;
}

export const getAnyApiClient = (): AppClient => {
    return new AppClient({ 
        BASE: baseUrl(),
    });
}

export const getAuthApiClient = (): AppClient => {
    const authToken = Cookies.get('authToken');
    return new AppClient({ 
        BASE: baseUrl(),
        HEADERS: {  
            Authorization: formatToken(authToken || '')
        }
    });
}