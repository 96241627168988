import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import ClinicsDisplayCard from './ClinicDisplayCard';
import { clinicianProfilesPropType } from '@constants/propTypes';

function CrpsDisplayBox({
	clinicianProfiles,
	editable,
	editClinic,
	removeClinic,
}) {
	let crps;
	if (clinicianProfiles === null || clinicianProfiles === undefined) {
		crps = null;
	} else {
		crps = clinicianProfiles.map((crp) => (
			<ClinicsDisplayCard
				key={`clinic${crp.clinic.id}`}
				values={crp.clinic}
				isNew={false}
				editable={editable}
				editClinic={editClinic}
				removeClinic={removeClinic}
				isDoctor={crp.isDoctor}
			/>
		));
	}

	return (
		<div className="medr-rounded bg-medr-gray text-left px-3">
			<Row>
				<Col>
					<h4 className="bluegreen">Assigned Clinics</h4>

					{crps}
				</Col>
			</Row>
		</div>
	);
}

CrpsDisplayBox.propTypes = {
	clinicianProfiles: clinicianProfilesPropType.isRequired,
	editable: PropTypes.bool.isRequired,
	editClinic: PropTypes.func.isRequired,
	removeClinic: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	clinicianProfiles: state.profile.clinicianProfiles,
});

export default connect(mapStateToProps, {})(CrpsDisplayBox);
