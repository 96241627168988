import React from 'react';
import { parseISO, format } from 'date-fns';
import { JobRole, InsuranceAgreement, ClinicianRoleProfile, Patient, UserProfile, Address, ClinicCenter } from '@/client'

export function reformatDate(dateString: string | null): string | null {
    if (dateString===null){
      return null;
    }
    const parts = dateString.split("-");
    if (parts.length !== 3) {
      throw new Error("Invalid date format. Expected 'YYYY-MM-DD'");
    }
  
    const [year, month, day] = parts;
    return `${day}/${month}/${year}`;
};

export function reformatDateTime(datetimeString: string | null): string | null {
  const date = new Date(datetimeString);
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };
  const formattedDate = date.toLocaleDateString(undefined, options);
  return `${formattedDate}`;
}

export function convertDateTime(dateTimeStr: string | null): string | null {
  if (!dateTimeStr || dateTimeStr === undefined) return null;
  try {
    const date = parseISO(dateTimeStr);
    return format(date, 'HH:mm dd/MM/yyyy');
  } catch (error) {
    console.error('Error parsing date:', error);
    return 'Invalid Date';
  }
}


interface ObjectWithId {
  id: number | string;
  [key: string]: any;
}

export function findObjectById<T extends ObjectWithId>(array: T[], id: number | string): T | null {
  for (let i = 0; i < array.length; i += 1) {
    if (array[i].id === id) {
      return array[i];
    }
  }
  return null; // Return null if the object with the given id is not found
}

export function getJobRoleString(jobRole: JobRole | null): string {
  const isReady = jobRole!==null && jobRole!==undefined && jobRole.jobRole!==null && jobRole.jobRole!==undefined;
  if (!isReady){
    return 'Job role missing';
  }

  if (jobRole.subSpecialty!=='nan' && jobRole.specialty!=='nan'){
    return `${jobRole.subSpecialty} - ${jobRole.specialty} (${jobRole.jobRole})`;
  }
  if (jobRole.specialty!=='nan'){
    return `${jobRole.specialty} (${jobRole.jobRole})`;
  }
  return `${jobRole.jobRole}`;
};


export function getInsuranceAgreementString(insuranceAgreement: InsuranceAgreement | null): string{
  if (insuranceAgreement===null){
    return 'Insurance agreement missing';
  }
  if (insuranceAgreement.policyName===null || insuranceAgreement.policyName===undefined){
    return 'Policy name  missing';
  }
  if (insuranceAgreement.provider===null || insuranceAgreement.provider===undefined){
    return 'Provider  missing';
  }
  return insuranceAgreement.provider;
};


interface Profile {
  centreName: string | null;
  addressFirstLine: string | null;
  addressPostcode: string | null;
}

export function getClinicianProfileString(profile: Profile | null): string{
  if (profile===null){
    return 'Clinician profile missing';
  }

  const { centreName, addressFirstLine, addressPostcode } = profile;
  let outStr = ""

  if (centreName!==null && centreName!==undefined){
    outStr += centreName;
    outStr += ', '
  }
  if (addressFirstLine!==null && addressFirstLine!==undefined){
    outStr += addressFirstLine;
    outStr += ', '
  }
  if (addressPostcode!==null && addressPostcode!==undefined){
    outStr += addressPostcode;
  }

  return outStr;
};


interface ClinicianRoleProfileRank extends ClinicianRoleProfile {
  distanceRank: number | null;
  centreName: string | null;
}

export function getClinicianDistString(clinician: ClinicianRoleProfileRank | null): string{
  if (clinician===null){
    return 'Clinician profile missing';
  }

  const { distanceRank, userProfile, centreName, distance } = clinician;
  const { title, firstName, lastName } = userProfile;

  let outStr = ""

  if (distanceRank!==null && distanceRank!==undefined){
    outStr += distanceRank;
    outStr += '. '
  }
  if (title!==null && title!==undefined){
    outStr += title;
    outStr += ' '
  }
  if (firstName!==null && firstName!==undefined){
    outStr += firstName;
    outStr += ' '
  }
  if (lastName!==null && lastName!==undefined){
    outStr += lastName;
    outStr += ' - '
  }
  if (centreName!==null && centreName!==undefined){
    outStr += centreName;
    outStr += ' ('
  }
  if (distance!==null && distance!==undefined){
    outStr += distance;
    outStr += 'km)'
  }

  return outStr;
};


export function getPatientNameString(patient: Patient | null): string{
  if (patient===null || patient===undefined){
    return 'Patient missing';
  }

  const { id, title, firstName, lastName, detailsInImage } = patient;

  let outStr: string;
  if (detailsInImage===true){
    const ID = String(id).padStart(5, '0');
    outStr = `PX_IMG_${ID}`;
  } else {
    const fieldsPresent = title!==null && title!==undefined && firstName!==null && firstName!==undefined && lastName!==null && lastName!==undefined;
    if (!fieldsPresent){
      return 'Patient data missing';
    }
    outStr = `${title} ${firstName} ${lastName}`;
  }
  return outStr;
};


export function getNameString(object: UserProfile | null): string{
  if (object===null){
    return 'Name missing';
  }

  const { title, firstName, lastName } = object;
  let outStr = ""
  if (title!==null && title!==undefined){
    outStr += title;
    outStr += ' '
  }
  if (firstName!==null && firstName!==undefined){
    outStr += firstName;
    outStr += ' '
  }
  if (lastName!==null && lastName!==undefined){
    outStr += lastName;
  }
  return outStr;
};

export function getAddressStr(object: Address | null): string{
  if (!object){
    return 'Name missing';
  }
  const { firstLine, city, postcode } = object;

  let outStr = "";
  if (firstLine!==null && firstLine!==undefined && firstLine!==''){
    outStr += firstLine;
    outStr += ', '
  }
  if (city!==null && city!==undefined && city!==''){
    outStr += city;
    outStr += ', '
  }
  if (postcode!==null && postcode!==undefined && postcode!==''){
    outStr += postcode;
  }
  return outStr;
}

export function getAddressMultiline(object: Address | null | undefined): JSX.Element {
  const notReadyLabel = "Address missing";
  
  if (object===null || object===undefined){
    return <p>{notReadyLabel}</p>;
  }

  const isReady = object.firstLine!==null && object.firstLine!==undefined && object.city!==null && object.city!==undefined && object.postcode!==null && object.postcode!==undefined;


  if (!isReady){
    return <p>{notReadyLabel}</p>;
  }

  const { firstLine, city, postcode } = object;

  return (
    <>
      <h6>{firstLine}</h6>
      <h6>{city}</h6>
      <h6>{postcode}</h6>
    </>
  );
}

export function getClinicCenterStr(object: ClinicCenter | null): string {
  
  if (!object || !object.clinicName || !object.address){
    return 'Clinic info missing';
  }
  let outStr = "";
  if (object.centreName!==null && object.centreName!==undefined && object.centreName!==''){
    outStr += object.centreName;
    outStr += ', '
  }
  if (object.clinicName!==null && object.clinicName!==undefined && object.clinicName!==''){
    outStr += object.clinicName;
    outStr += ', '
  }
  outStr += getAddressStr(object.address);
  return outStr;
}

export function getShortClinicCenterStr(object: ClinicCenter | null): string {
  
  if (!object || !object.clinicName ){
    return 'Clinic info missing';
  }
  let outStr = "";
  if (object.centreName!==null && object.centreName!==undefined && object.centreName!==''){
    outStr += object.centreName;
  }
  return outStr;
}