/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { JobRoleByIdResponse } from '../models/JobRoleByIdResponse';
import type { JobRoleListResponse } from '../models/JobRoleListResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class JobRolesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Returns a list of job roles.
     * @returns JobRoleListResponse
     * @throws ApiError
     */
    public jobRolesList(): CancelablePromise<JobRoleListResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/job-roles/',
        });
    }
    /**
     * Return job role information using the uid.
     * @param id
     * @returns JobRoleByIdResponse
     * @throws ApiError
     */
    public jobRolesRetrieve(
        id: number,
    ): CancelablePromise<JobRoleByIdResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/job-roles/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Returns a list of job roles.
     * @returns JobRoleListResponse
     * @throws ApiError
     */
    public jobRolesFilteredRetrieve(): CancelablePromise<JobRoleListResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/job-roles/filtered/',
        });
    }
}
