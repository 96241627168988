import React from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import TermsAndConditionsPopup from '../../app/alerts/TermsAndConditionsPopup';
import useDelegateVerificationForm from './useDelegateVerifcationForm';
import { getNameString } from '@/actions/general';
import TitleDropdown from '../TitleDropdown/TitleDropdown';
import DelegateRegistrationSuccessPopup from './DelegateRegistrationSuccessPopup';

function DelegateVerificationForm() {
	const {
		userData,
		updateUserData,
		termsAccepted,
		showTerms,
		setShowTerms,
		updateTermsAccepted,
		onSubmit,
		showSuccess,
		passwordsMatch,
	} = useDelegateVerificationForm();

	const { title, firstName, lastName, password, rePassword } = userData;

	return (
		<>
			<DelegateRegistrationSuccessPopup showModal={showSuccess} />
			<TermsAndConditionsPopup
				showModal={showTerms}
				handleClose={() => setShowTerms(false)}
			/>
			<div
				className="medr-layout text-center register-page"
				style={{ width: '700px', marginLeft: 'auto', marginRight: 'auto' }}
			>
				<Form onSubmit={onSubmit}>
					<p className="medr-text py-2">
						<b>
							Hello {getNameString(userData)}, welcome to the MedR community!
						</b>{' '}
						{getNameString(userData.clinician)} has invited to you to be a
						delegate user. This account will allow you send and receive
						referrals on behalf of {getNameString(userData.clinician)}.
					</p>
					<p className="medr-text py-1">
						Please fill in the form below to access your account.
					</p>
					<div className="row">
						<div
							className="col-md-4"
							style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'flex-end',
							}}
						>
							<Form.Group controlId="title">
								<Form.Label className="my-0">Title</Form.Label>
								<TitleDropdown title={title} sendTitle={updateUserData} />
								{/* {!formChecks.title ? <p className='medr-invalid-text'>Please select a title</p> : null} */}
							</Form.Group>
						</div>
						<div className="col-md-8 py-1">
							<Form.Group controlId="firstName">
								<Form.Label className="my-0">First name</Form.Label>
								<Form.Control
									required
									type="name"
									placeholder="Enter first name"
									name="firstName"
									value={firstName}
									onChange={(e) =>
										updateUserData({ [e.target.name]: e.target.value })
									}
									// isInvalid={!formChecks.firstName}
								/>
								<Form.Control.Feedback type="invalid">
									Please enter a first name.
								</Form.Control.Feedback>
							</Form.Group>
						</div>
					</div>
					<Form.Group controlId="lastName" className="mb-3">
						<Form.Label className="my-0">Last name</Form.Label>
						<Form.Control
							required
							type="name"
							placeholder="Enter last name"
							name="lastName"
							value={lastName}
							onChange={(e) =>
								updateUserData({ [e.target.name]: e.target.value })
							}
						/>
						<Form.Control.Feedback type="invalid">
							Please enter a last name.
						</Form.Control.Feedback>
					</Form.Group>

					<Form.Group controlId="password" className="py-1">
						<Form.Label>Password</Form.Label>
						<Form.Control
							required
							type="password"
							placeholder="Enter New Password"
							name="password"
							value={password}
							onChange={(e) => updateUserData({ password: e.target.value })}
						/>
					</Form.Group>

					<Form.Group controlId="rePassword" className="py-1">
						<Form.Label>Confirm Password</Form.Label>
						<Form.Control
							required
							type="password"
							placeholder="Confirm New Password"
							name="rePassword"
							value={rePassword}
							onChange={(e) => updateUserData({ rePassword: e.target.value })}
							isInvalid={!passwordsMatch}
						/>
						<Form.Control.Feedback type="invalid">
							Passwords do not match!
						</Form.Control.Feedback>
					</Form.Group>

					<Row className="text-left mt-4">
						<Col className="my-0">
							<Form.Group controlId="consent" className="mb-3">
								<Form.Label className="my-0 custom-checkbox w100 text-left">
									<Form.Check className="custom-hidden-checkbox">
										<input
											type="checkbox"
											data-testid="consent-checkbox"
											id="consent"
											checked={termsAccepted}
											onChange={updateTermsAccepted}
										/>
										<div className="d-flex align-items-center">
											<i className="fa-regular fa-square-check big-checkbox custom-unchecked" />
											<i className="fa-solid fa-square-check big-checkbox custom-checked" />
											I agree to the terms and conditions
											<span style={{ marginRight: '0.5em' }}></span>
											<button
												type="button"
												onClick={() => setShowTerms(true)}
												className="btn btn-link p-0 m-0 align-baseline  medr-text-inv"
												style={{ fontWeight: 'bold' }}
											>
												(see terms and conditions)
											</button>
										</div>
									</Form.Check>
								</Form.Label>
							</Form.Group>
						</Col>
					</Row>

					<Row>
						<Col className="text-center">
							<Button
								className="sel-button w80 my-4"
								variant="success"
								type="submit"
								disabled={!termsAccepted}
							>
								Complete registration
							</Button>
						</Col>
					</Row>
				</Form>
			</div>
		</>
	);
}

export default DelegateVerificationForm;
