import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { funcPropType, boolPropType } from '@constants/propTypes';
import useJobRoleFilter from '@hooks/clinicians/useJobRoleFilter';
import { dropBarStyle } from '@constants/styles';

function JobRoleSearchBar({
	values,
	changeValues,
	filtered = false,
	clearAfterSelect = false,
}) {
	const {
		jobRoles,
		jobSpecialties,
		jobSubSpecialties,
		selectedJobRole,
		selectedJobSpecialty,
		selectedJobSubSpecialty,
		showSecondBar,
		showThirdBar,
		updateSelectedJobRole,
		updateSelectedJobSpecialty,
		updateSelectedJobSubSpecialty,
	} = useJobRoleFilter(values, filtered, changeValues, clearAfterSelect);

	return (
		<>
			<Row className="mb-3 custom-dropdown">
				<Col>
					<Select
						name="clinicianType"
						options={jobRoles}
						value={selectedJobRole}
						onChange={(opt) => updateSelectedJobRole(opt)}
						placeholder="Select clinician role"
						styles={dropBarStyle}
						className="custom-dropdown-select"
					/>
				</Col>
			</Row>

			{showSecondBar && (
				<Row className="mb-3">
					<Col>
						<Select
							name="specialty"
							options={jobSpecialties}
							value={selectedJobSpecialty}
							onChange={(opt) => updateSelectedJobSpecialty(opt)}
							placeholder="Select specialty"
							styles={dropBarStyle}
							className="custom-dropdown-select"
						/>
					</Col>
				</Row>
			)}
			{showThirdBar && (
				<Row className="mb-3">
					<Col>
						<Select
							name="subSpecialty"
							options={jobSubSpecialties}
							value={selectedJobSubSpecialty}
							onChange={(opt) => updateSelectedJobSubSpecialty(opt)}
							placeholder="Select sub-specialty"
							styles={dropBarStyle}
							className="custom-dropdown-select"
						/>
					</Col>
				</Row>
			)}
		</>
	);
}

JobRoleSearchBar.propTypes = {
	filtered: boolPropType,
	values: PropTypes.shape({
		jobRoleId: PropTypes.number,
		selectedJobRole: PropTypes.string,
		selectedJobSpecialty: PropTypes.string,
		selectedJobSubSpecialty: PropTypes.string,
	}),
	changeValues: funcPropType.isRequired,
	clearAfterSelect: boolPropType,
};

export default JobRoleSearchBar;
