import { useEffect } from 'react';
import { Dispatch } from 'redux';
import { useSelector } from 'react-redux';
import { NavigateFunction } from 'react-router-dom';
import * as CONSTS from '@/constants';
import { getAuthApiClient } from './apiActions';
import { redirectIfInvalidToken } from './authService';
import { Patient, UserProfile, Referral, InsurerListForAdminResponse, InsuranceAgreement, GetPlatformStatisticsResponse, RequestNewFeatureRequest, RequestNewFeatureResponse, CliniciansListForAdminResponse, ReferralsListForAdminResponse, UserListForAdminResponse, SetUserVerificationResponse, CreateAdminUserRequest, PatientsListForAdminResponse } from '@client'

type ApiClient = ReturnType<typeof getAuthApiClient>;

type ReferralLists = {
    open: Array<Referral>;
    pending: Array<Referral>;
    accepted: Array<Referral>;
};

export class AdminService {
    private dispatch: Dispatch;
    private navigate: NavigateFunction;
    private appAuthClient: ApiClient;
    public requestLoading: boolean;
    public statsData: GetPlatformStatisticsResponse;
    public insurers: Array<InsuranceAgreement>;
    public unverifiedInsurers: Array<InsuranceAgreement>;
    public patients: Array<Patient>;
    public users: Array<UserProfile>;
    public unverifiedUsers: Array<UserProfile>;


    constructor ( dispatch: Dispatch, navigate: NavigateFunction ) {
        this.dispatch = dispatch;
        this.navigate = navigate;
        this.appAuthClient = getAuthApiClient();
        this.requestLoading = useSelector( ( state ) => state.admin.loading );
        this.statsData = useSelector( ( state ) => state.admin.platformStats );
        this.insurers = useSelector( ( state ) => state.admin.insurers.insurers );
        this.unverifiedInsurers = useSelector( ( state ) => state.admin.insurers.unverifiedInsurers );
    }

    async addNewAdminUser( inputData: CreateAdminUserRequest ): Promise<boolean> {
        this.dispatch( { type: CONSTS.ADD_NEW_ADMIN_ACTION.REQUEST } );

        try {
            await this.appAuthClient.admin.adminNewAdminUserCreate( inputData );
            this.dispatch( { type: CONSTS.ADD_NEW_ADMIN_ACTION.SUCCESS } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: CONSTS.ADD_NEW_ADMIN_ACTION.FAIL } );
            return false;
        }
    }

    async getPlatformStats(): Promise<boolean> {
        this.dispatch( { type: CONSTS.PLATFORM_STATS_ACTION.REQUEST } );

        try {
            const data: GetPlatformStatisticsResponse = await this.appAuthClient.admin.adminPlatformStatsRetrieve();
            this.dispatch( { type: CONSTS.PLATFORM_STATS_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: CONSTS.PLATFORM_STATS_ACTION.FAIL } );
            return false;
        }

    }

    async getAdminUsersList(): Promise<boolean> {
        this.dispatch( { type: CONSTS.ADMIN_USERS_LIST_ACTION.REQUEST } );

        try {
            const data: UserListForAdminResponse = await this.appAuthClient.admin.adminUserProfilesRetrieve();
            this.dispatch( { type: CONSTS.ADMIN_USERS_LIST_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: CONSTS.ADMIN_USERS_LIST_ACTION.FAIL } );
            return false;
        }
    }

    async getAdminPatientsList(): Promise<boolean> {
        this.dispatch( { type: CONSTS.ADMIN_PATIENTS_LIST_ACTION.REQUEST } );

        try {
            const data: PatientsListForAdminResponse = await this.appAuthClient.admin.adminPatientsRetrieve();
            this.dispatch( { type: CONSTS.ADMIN_PATIENTS_LIST_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: CONSTS.ADMIN_PATIENTS_LIST_ACTION.FAIL } );
            return false;
        }
    }

    async getAdminReferralsList(): Promise<boolean> {
        this.dispatch( { type: CONSTS.ADMIN_REFERRALS_LIST_ACTION.REQUEST } );

        try {
            const data: ReferralsListForAdminResponse = await this.appAuthClient.admin.adminReferralsRetrieve();
            this.dispatch( { type: CONSTS.ADMIN_REFERRALS_LIST_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: CONSTS.ADMIN_REFERRALS_LIST_ACTION.FAIL } );
            return false;
        }

    }

    async getAdminClinicansList(): Promise<boolean> {
        this.dispatch( { type: CONSTS.ADMIN_CLINICIANS_LIST_ACTION.REQUEST } );

        try {
            const data: CliniciansListForAdminResponse = await this.appAuthClient.admin.adminClinicianRoleProfilesRetrieve();
            this.dispatch( { type: CONSTS.ADMIN_CLINICIANS_LIST_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: CONSTS.ADMIN_CLINICIANS_LIST_ACTION.FAIL } );
            return false;
        }
    }

    async getAdminInsurers(): Promise<boolean> {
        this.dispatch( { type: CONSTS.ADMIN_INSURERS_LIST_ACTION.REQUEST } );

        try {
            const data: InsurerListForAdminResponse = await this.appAuthClient.admin.adminInsuranceAgreementsRetrieve();
            this.dispatch( { type: CONSTS.ADMIN_INSURERS_LIST_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: CONSTS.ADMIN_INSURERS_LIST_ACTION.FAIL } );
            return false;
        }

    }

    async verifyInsurer( insId: number ): Promise<boolean> {
        this.dispatch( { type: CONSTS.ADMIN_INSURER_VERIFY_ACTION.REQUEST } );

        try {
            await this.appAuthClient.insuranceAgreements.insuranceAgreementsVerifyCreate( insId );
            this.dispatch( { type: CONSTS.ADMIN_INSURER_VERIFY_ACTION.SUCCESS } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: CONSTS.ADMIN_INSURER_VERIFY_ACTION.FAIL } );
            return false;
        }
    }

    async setUserVerified( usrId: number, setVerified: boolean ): Promise<boolean> {
        this.dispatch( { type: CONSTS.ADMIN_SET_USER_VERIFIED_ACTION.REQUEST } );

        try {
            const data: SetUserVerificationResponse = await this.appAuthClient.admin.adminSetVerificationUserProfileCreate( usrId, { setVerified } );
            this.dispatch( { type: CONSTS.ADMIN_SET_USER_VERIFIED_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: CONSTS.ADMIN_SET_USER_VERIFIED_ACTION.FAIL } );
            return false;
        }
    }

    async requestNewFeature( suggestion: string ): Promise<boolean> {
        if ( !suggestion || suggestion.length === 0 ) {
            this.dispatch( { type: CONSTS.REQUEST_NEW_FEATURE_ACTION.FAIL, payload: 'Please enter a suggestion' } );
            return false;
        }

        this.dispatch( { type: CONSTS.REQUEST_NEW_FEATURE_ACTION.REQUEST } );

        try {
            const data: RequestNewFeatureResponse = await this.appAuthClient.user.userRequestFeatureCreate( { suggestion } );
            this.dispatch( { type: CONSTS.REQUEST_NEW_FEATURE_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: CONSTS.REQUEST_NEW_FEATURE_ACTION.FAIL } );
            return false;
        }
    }

    getPatientsListSlice( start: number, end: number ): Array<Patient> {
        return this.patients.slice( start, end );
    }

    getUserListSlice( start: number, end: number ): Array<UserProfile> {
        return this.users.slice( start, end );
    }

    getUnverifiedUserListSlice( start: number, end: number ): Array<InsuranceAgreement> {
        return this.unverifiedUsers.slice( start, end );
    }

    filterReferralsByStatus( referralsList: Array<Referral> ): ReferralLists {
        const open = referralsList.filter( ( referral ) => !referral.specifiedClinician );
        const selectedReferrals = referralsList.filter( ( referral ) => referral.specifiedClinician );
        const pending = selectedReferrals.filter( ( referral ) => !referral.targetHasAccepted );
        const accepted = selectedReferrals.filter( ( referral ) => referral.targetHasAccepted );
        return { open, pending, accepted };
    };

    getReferralsSlice( referrals: Array<Referral>, start: number, end: number ): Array<Referral> {
        return referrals.slice( start, end );
    }

    goToUserListPage(): void {
        this.navigate( '/staff/users' );
    }

    goToCliniciansMapPage(): void {
        this.navigate( '/clinicians' );
    }

    goToDashboardPage(): void {
        this.navigate( '/dashboard' );
    }

}