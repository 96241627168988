import * as CONSTS from '@constants/adminConstants';
import { LOGOUT_ACTION } from '@constants/authConstants';

const initialState = {
	loading: false,
	platformStats: {},
	users: {
		users: [],
		unverifiedUsers: [],
		deactivatedUsers: [],
		operationsUsers: [],
	},
	clinicians: {
		staff: [],
		unverified: [],
		clinicians: [],
		deactivated: [],
		delegates: [],
	},
	insurers: {
		insuerers: [],
		unverifiedInsurers: [],
	},
	patientsList: [],
	referrals: {
		open: [],
		pending: [],
		accepted: [],
		completed: [],
	},
};

// eslint-disable-next-line default-param-last
export default function admin(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case CONSTS.PLATFORM_STATS_ACTION.REQUEST:
		case CONSTS.ADMIN_USERS_LIST_ACTION.REQUEST:
		case CONSTS.ADMIN_PATIENTS_LIST_ACTION.REQUEST:
		case CONSTS.ADMIN_REFERRALS_LIST_ACTION.REQUEST:
		case CONSTS.ADMIN_CLINICIANS_LIST_ACTION.REQUEST:
		case CONSTS.ADMIN_INSURERS_LIST_ACTION.REQUEST:
		case CONSTS.ADMIN_SET_USER_VERIFIED_ACTION.REQUEST:
			return {
				...state,
				loading: true,
			};
		case CONSTS.PLATFORM_STATS_ACTION.SUCCESS:
		case CONSTS.ADMIN_USERS_LIST_ACTION.SUCCESS:
		case CONSTS.ADMIN_PATIENTS_LIST_ACTION.SUCCESS:
		case CONSTS.ADMIN_REFERRALS_LIST_ACTION.SUCCESS:
		case CONSTS.ADMIN_CLINICIANS_LIST_ACTION.SUCCESS:
		case CONSTS.ADMIN_INSURERS_LIST_ACTION.SUCCESS:
		case CONSTS.ADMIN_SET_USER_VERIFIED_ACTION.SUCCESS:
			return {
				...state,
				...payload,
				loading: false,
			};
		case CONSTS.ADMIN_USERS_LIST_ACTION.FAIL:
		case CONSTS.ADMIN_PATIENTS_LIST_ACTION.FAIL:
		case CONSTS.ADMIN_REFERRALS_LIST_ACTION.FAIL:
		case CONSTS.ADMIN_CLINICIANS_LIST_ACTION.FAIL:
		case CONSTS.ADMIN_INSURERS_LIST_ACTION.FAIL:
		case CONSTS.PLATFORM_STATS_ACTION.FAIL:
		case CONSTS.ADMIN_SET_USER_VERIFIED_ACTION.FAIL:
			return {
				...state,
				loading: false,
			};
		case LOGOUT_ACTION.SUCCESS:
			return { ...initialState };
		default:
			return state;
	}
}
