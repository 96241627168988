import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Navigate, useParams, useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { isAuthenticatedProptype } from '@constants/propTypes';
import { useAuthService } from '@actions';

function RegistrationProgressScreen({ step, isAuthenticated }) {
	if (isAuthenticated) return <Navigate to="/dashboard" />;

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const authService = useAuthService();

	const [stepHasFailed, setStepHasFailed] = useState(false);

	const step1 = (
		<>
			<Row className="registration-steps text-center mt-5">
				<Col>
					<i className="fa-solid fa-check-circle" />
				</Col>
				<Col>
					<i className="fa-regular fa-check-circle" />
				</Col>
				<Col>
					<i className="fa-regular fa-check-circle" />
				</Col>
			</Row>
			<Row>
				<Col className="mt-5 text-center">
					<p className="registration-step-info">
						Please check your email for the verification link.
					</p>
				</Col>
			</Row>
		</>
	);
	const step2 = stepHasFailed ? (
		<>
			<Row className="registration-steps text-center mt-5">
				<Col>
					<i className="fa-solid fa-check-circle" />
				</Col>
				<Col>
					<i className="fa-regular fa-check-circle" />
				</Col>
				<Col>
					<i className="fa-regular fa-check-circle" />
				</Col>
			</Row>
			<Row>
				<Col className="mt-5 text-center text-center">
					<p className="registration-step-info">
						Email verification was unsuccessful. The MedR team will review your
						registration request, which may take up to 48 hours. Thank you for
						your patience.{' '}
					</p>
				</Col>
			</Row>
		</>
	) : (
		<>
			<Row className="registration-steps text-center mt-5">
				<Col>
					<i className="fa-solid fa-check-circle" />
				</Col>
				<Col>
					<i className="fa-solid fa-check-circle" />
				</Col>
				<Col>
					<i className="fa-regular fa-check-circle" />
				</Col>
			</Row>
			<Row>
				<Col className="mt-5 text-center text-center">
					<p className="registration-step-info">
						Your email has been successfully verified. Please wait for the MedR
						team to approve your account, which may take up to 48 hours. Thank
						you for your patience!{' '}
					</p>
				</Col>
			</Row>
		</>
	);

	const step3 = (
		<>
			<Row className="registration-steps mt-5">
				<Col>
					<i className="fa-solid fa-check-circle" />
				</Col>
				<Col>
					<i className="fa-solid fa-check-circle" />
				</Col>
				<Col>
					<i className="fa-solid fa-check-circle" />
				</Col>
			</Row>
			<Row>
				<Col className="mt-5 text-center">
					<p className="registration-step-info">
						Your account has been approved!
					</p>
				</Col>
			</Row>
			<Row>
				<Col>
					<Button>Please Login</Button>
				</Col>
			</Row>
		</>
	);

	const s2Success = (value) => {
		setStepHasFailed(!value);

		if (value === true) {
			<Navigate to="/registered/verification-pending" />;
		}
	};
	const params = useParams();

	useEffect(() => {
		const checkVerification = async () => {
			const { userHash, timeHash, verificationCode } = params;
			const isSuccess = await authService.verifyUserEmail(
				verificationCode,
				timeHash,
				userHash
			);
			s2Success(isSuccess);
		};

		window.scrollTo(0, 0);
		if (step === 1) checkVerification();
	}, []);

	return (
		<>
			<Row>
				<Col className="text-center">
					<h1>Registration step {step + 1} of 3</h1>
				</Col>
			</Row>
			{step === 0 ? step1 : null}
			{step === 1 ? step2 : null}
			{step === 2 ? step3 : null}
		</>
	);
}

RegistrationProgressScreen.propTypes = {
	isAuthenticated: isAuthenticatedProptype.isRequired,
	step: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {})(RegistrationProgressScreen);
