import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import useReferralsContactsDisplay from './useReferralsContactsDisplay';
import { EmailFormField, PhoneFormField } from '../../app/parts/FormFields';
import useScreenInfo from '@/hooks/app/useScreenInfo';

const plusIcon = <i className="fa-solid fa-plus" />;
const crossIcon = <i className="fa-solid fa-x" />;
const emailIcon = <i className="fa-regular fa-envelope" />;
const phoneIcon = <i className="fa-solid fa-phone-volume" />;

export function ReferralContact({ isNarrow, contact, removeContact = null }) {
	if (!contact) {
		return (
			<div>
				<i className="fa-solid fa-user" />
			</div>
		);
	}
	const { type, email, phoneNumber } = contact;

	const textSize = isNarrow ? '0.7em' : '1em';

	const narrowRemoveButton = (
		<Button
			className="rounded-circle p-1"
			variant="danger"
			onClick={() => removeContact(contact)}
			style={{
				width: '30px',
				height: '30px',
				fontSize: textSize,
			}}
		>
			{crossIcon}
		</Button>
	);

	const wideRemoveButton = (
		<Button
			className="sel-button p-1"
			variant="danger"
			onClick={() => removeContact(contact)}
		>
			Remove
		</Button>
	);

	return (
		<div className="medr-rounded-inv m-1 p-1 notif-setting-bar d-flex justify-content-between align-items-center">
			<div
				className="d-flex align-items-center"
				style={{ paddingLeft: '10px', maxWidth: '80%' }}
			>
				<span
					className="me-2 flex-shrink-0"
					style={{ paddingLeft: '10px', fontSize: textSize }}
				>
					{type === 'email' ? emailIcon : phoneIcon}
				</span>
				<span
					className="text-truncate"
					style={{ paddingLeft: '10px', fontSize: textSize }}
				>
					{type === 'email' ? email : phoneNumber}
				</span>
			</div>
			{removeContact && !contact.isMain && (
				<div>{isNarrow ? narrowRemoveButton : wideRemoveButton}</div>
			)}
		</div>
	);
}

ReferralContact.propTypes = {
	isNarrow: PropTypes.bool,
	contact: PropTypes.shape({
		type: PropTypes.string.isRequired,
		email: PropTypes.string,
		phoneNumber: PropTypes.string,
		isMain: PropTypes.bool,
	}).isRequired,
	removeContact: PropTypes.func,
};

function ReferralContactsDisplay({
	contacts,
	updateAppointmentContacts,
	requiredEmails,
}) {
	const {
		newContacts,
		showForm,
		setShowForm,
		newContactFormData,
		updateNewContactEmail,
		updateNewContactPhoneNumber,
		addNewContact,
		removeNewContact,
	} = useReferralsContactsDisplay(
		contacts,
		updateAppointmentContacts,
		requiredEmails
	);
	const [isNarrow] = useScreenInfo(768);

	return (
		<div className="medr-rounded bg-medr-gray bluegreen mt-4">
			<h4 className="bluegreen text-center">Referral Contacts</h4>
			<p className="bluegreen medr-small">
				The email addresses listed below will receive notifications regarding
				the sending, receiving, accepting, or rejecting of referrals, based on
				your notification settings. Additionally, you will be alerted about open
				referrals that meet the specified criteria. Any phone numbers provided
				will be added to your contact information.
			</p>
			<p>
				<strong>
					Please note that at least one email address is required.
				</strong>
			</p>
			{newContacts &&
				newContacts.map((contact) => (
					<ReferralContact
						key={uuidv4()}
						isNarrow={isNarrow}
						contact={contact}
						removeContact={removeNewContact}
					/>
				))}

			<div
				className="row m-1"
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					gap: '1rem',
				}}
			>
				<Button
					className="sel-button p-1"
					variant="primary"
					onClick={() => setShowForm('email')}
				>
					{plusIcon} {emailIcon}
				</Button>
				<Button
					className="sel-button p-1"
					variant="primary"
					onClick={() => setShowForm('phoneNumber')}
				>
					{plusIcon} {phoneIcon}
				</Button>
			</div>
			{showForm === 'email' && (
				<div
					className="mt-3 d-flex add-new-email"
					style={{ alignItems: 'center', justifyContent: 'space-between' }}
				>
					<div>
						<EmailFormField
							email={newContactFormData.email}
							emailKey="email"
							changeValues={updateNewContactEmail}
						/>
					</div>
					<div>
						<Button className="sel-button" onClick={addNewContact}>
							Add email
						</Button>
					</div>
				</div>
			)}
			{showForm === 'phoneNumber' && (
				<div
					className="mt-3 d-flex add-new-phone"
					style={{ alignItems: 'center', justifyContent: 'space-between' }}
				>
					<div className="app-contact-disp-phone-left">
						<PhoneFormField
							currentPhoneNo={newContactFormData.phoneNumber}
							nameKey="phoneNumber"
							changeValues={updateNewContactPhoneNumber}
						/>
					</div>
					<div className="app-contact-disp-phone-left">
						<Button className="sel-button" onClick={addNewContact}>
							Add phone
						</Button>
					</div>
				</div>
			)}
		</div>
	);
}

ReferralContactsDisplay.propTypes = {
	contacts: PropTypes.arrayOf(
		PropTypes.shape({
			type: PropTypes.string.isRequired,
			email: PropTypes.string,
			phoneNumber: PropTypes.string,
		})
	).isRequired,
	updateAppointmentContacts: PropTypes.func.isRequired,
};

export default ReferralContactsDisplay;
