import React, { useState } from 'react';
import { Form, Button, Row } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';
import PasswordField from '@components/app/parts/PasswordField';
import { useAuthService, useUserService } from '@actions';

function LoginScreen() {
	const authService = useAuthService();
	authService.RedirectIfAuthDetailsAvailable();

	const userService = useUserService();

	const [formData, setFormData] = useState({
		username: '',
		password: '',
	});
	const [error, setError] = useState(false);

	const { username, password } = formData;

	const onChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};
	const updateChanges = (newData) => {
		setFormData({ ...formData, ...newData });
	};

	async function handleLogin(username, password) {
		const loginSuccess = await authService.login(username, password);

		setError(!loginSuccess);
		if (!loginSuccess) return;

		userService.initAuthClient();

		const loadProfileSuccess = await userService.loadUserProfile();
		if (!loadProfileSuccess) return;

		const referralNotificationSuccess =
			await userService.getReferralNotifications();
		if (!referralNotificationSuccess) return;
		userService.redirectToDashboard();
	}

	const onSubmit = (e) => {
		e.preventDefault();
		handleLogin(username, password);
	};

	return (
		<div
			className="medr-layout text-center login-page"
			style={{ maxWidth: '380px', marginLeft: 'auto', marginRight: 'auto' }}
		>
			<h1>Sign In</h1>
			<Form onSubmit={(e) => onSubmit(e)}>
				<Form.Group className="py-0" controlId="email">
					<Form.Label className="my-0">Email address</Form.Label>
					<Form.Control
						type="email"
						placeholder="Enter Email"
						name="username"
						value={username}
						onChange={(e) => onChange(e)}
						autoComplete="username"
						isInvalid={error}
					/>
					<Form.Control.Feedback type="invalid">
						Email and password combination not found.
					</Form.Control.Feedback>
				</Form.Group>
				<PasswordField
					controlId="password"
					label="Password"
					password={password}
					keyLabel="password"
					changeValues={updateChanges}
					autoComplete="current-password"
				/>
				<div>
					<p className="medr-text mt-2 medr-small">
						Forgotten your password?{' '}
						<Link
							className="medr-small"
							style={{ fontSize: '1em' }}
							to="/user/send-reset-email"
						>
							Reset here
						</Link>
					</p>
				</div>
				<Row className="mt-4">
					<div className="col-12">
						<Button className="sel-button w100" type="submit" variant="primary">
							Sign In
						</Button>
					</div>
				</Row>
				<Row className="mt-3">
					<div className="col-12">
						<LinkContainer to="/register">
							<Button className="sel-button w100" variant="dark">
								Register
							</Button>
						</LinkContainer>
					</div>
				</Row>
			</Form>
		</div>
	);
}

export default LoginScreen;
