/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClinicianAnalyticsResponse } from '../models/ClinicianAnalyticsResponse';
import type { ClinicianInsuranceUpdateRequest } from '../models/ClinicianInsuranceUpdateRequest';
import type { ClinicianInsuranceUpdateResponse } from '../models/ClinicianInsuranceUpdateResponse';
import type { ClinicianProfileCreateRequest } from '../models/ClinicianProfileCreateRequest';
import type { ClinicianProfileCreateResponse } from '../models/ClinicianProfileCreateResponse';
import type { ClinicianProfileDeactivateResponse } from '../models/ClinicianProfileDeactivateResponse';
import type { ClinicianProfileUpdateRequest } from '../models/ClinicianProfileUpdateRequest';
import type { ClinicianProfileUpdateResponse } from '../models/ClinicianProfileUpdateResponse';
import type { ClinicianUserInfoResponse } from '../models/ClinicianUserInfoResponse';
import type { DeactivateDelegateRequest } from '../models/DeactivateDelegateRequest';
import type { DeactivateUserAccountResponse } from '../models/DeactivateUserAccountResponse';
import type { DelegatesListResponse } from '../models/DelegatesListResponse';
import type { FavouriteClinicianCreateResponse } from '../models/FavouriteClinicianCreateResponse';
import type { FavouriteClinicianDeleteResponse } from '../models/FavouriteClinicianDeleteResponse';
import type { FavouriteClinicianGetResponse } from '../models/FavouriteClinicianGetResponse';
import type { InviteClinicianRequest } from '../models/InviteClinicianRequest';
import type { InviteClinicianResponse } from '../models/InviteClinicianResponse';
import type { LoginUserResponse } from '../models/LoginUserResponse';
import type { RemoveClinicianProfileRequest } from '../models/RemoveClinicianProfileRequest';
import type { RemoveClinicianProfileResponse } from '../models/RemoveClinicianProfileResponse';
import type { RequestHelpRequest } from '../models/RequestHelpRequest';
import type { RequestHelpResponse } from '../models/RequestHelpResponse';
import type { RequestNewFeatureRequest } from '../models/RequestNewFeatureRequest';
import type { RequestNewFeatureResponse } from '../models/RequestNewFeatureResponse';
import type { ResetUserPasswordRequest } from '../models/ResetUserPasswordRequest';
import type { ResetUserPasswordResponse } from '../models/ResetUserPasswordResponse';
import type { ResetUserPasswordSetNewRequest } from '../models/ResetUserPasswordSetNewRequest';
import type { ResetUserPasswordSetNewResponse } from '../models/ResetUserPasswordSetNewResponse';
import type { RestoreDelegateRequest } from '../models/RestoreDelegateRequest';
import type { UpdateDelegatePermissionRequest } from '../models/UpdateDelegatePermissionRequest';
import type { UpdateReferralContactsRequest } from '../models/UpdateReferralContactsRequest';
import type { UpdateReferralContactsResponse } from '../models/UpdateReferralContactsResponse';
import type { UpdateUserNotificationsSettingsRequest } from '../models/UpdateUserNotificationsSettingsRequest';
import type { UpdateUserNotificationsSettingsResponse } from '../models/UpdateUserNotificationsSettingsResponse';
import type { UpdateUserPasswordRequest } from '../models/UpdateUserPasswordRequest';
import type { UpdateUserPasswordResponse } from '../models/UpdateUserPasswordResponse';
import type { UpdateUserProfilePictureRequest } from '../models/UpdateUserProfilePictureRequest';
import type { UpdateUserProfilePictureResponse } from '../models/UpdateUserProfilePictureResponse';
import type { UpdateUserProfileRequest } from '../models/UpdateUserProfileRequest';
import type { UpdateUserProfileResponse } from '../models/UpdateUserProfileResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class UserService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Return the analytics for the logged in user.
     * @param number
     * @returns ClinicianAnalyticsResponse
     * @throws ApiError
     */
    public userAnalyticsDataRetrieve(
        number: number,
    ): CancelablePromise<ClinicianAnalyticsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/user/analytics-data/{number}/',
            path: {
                'number': number,
            },
        });
    }
    /**
     * Checks current authentication token
     * @returns LoginUserResponse
     * @throws ApiError
     */
    public userCheckAuthRetrieve(): CancelablePromise<LoginUserResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/user/check-auth/',
        });
    }
    /**
     * Returns clinician profile information for the logged in user.
     * @returns ClinicianUserInfoResponse
     * @throws ApiError
     */
    public userClinicianInformationRetrieve(): CancelablePromise<ClinicianUserInfoResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/user/clinician-information/',
        });
    }
    /**
     * Creates a new clinician profile for the logged in user
     * @param requestBody
     * @returns ClinicianProfileCreateResponse
     * @throws ApiError
     */
    public userClinicianInformationCreateCreate(
        requestBody: ClinicianProfileCreateRequest,
    ): CancelablePromise<ClinicianProfileCreateResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/user/clinician-information/create/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Deactivates the clinician account.
     * @param id
     * @returns ClinicianProfileDeactivateResponse
     * @throws ApiError
     */
    public userClinicianInformationDeactivateUpdate(
        id: number,
    ): CancelablePromise<ClinicianProfileDeactivateResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/user/clinician-information/deactivate/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Request to remove the clinician account
     * @param requestBody
     * @returns RemoveClinicianProfileResponse
     * @throws ApiError
     */
    public userClinicianInformationRemoveClinicCreate(
        requestBody: RemoveClinicianProfileRequest,
    ): CancelablePromise<RemoveClinicianProfileResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/user/clinician-information/remove-clinic/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Updates the list of accepted insurers for the logged in user account
     * @param requestBody
     * @returns ClinicianInsuranceUpdateResponse
     * @throws ApiError
     */
    public userClinicianInformationUpdateInsuranceAgreementsUpdate(
        requestBody: ClinicianInsuranceUpdateRequest,
    ): CancelablePromise<ClinicianInsuranceUpdateResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/user/clinician-information/update-insurance-agreements/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Updates the clinician profile.
     * @param clinicId
     * @param requestBody
     * @returns ClinicianProfileUpdateResponse
     * @throws ApiError
     */
    public userClinicianInformationUpdateUpdate(
        clinicId: number,
        requestBody: ClinicianProfileUpdateRequest,
    ): CancelablePromise<ClinicianProfileUpdateResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/user/clinician-information/update/{clinic_id}/',
            path: {
                'clinic_id': clinicId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Updates the users password while logged in
     * @param userProfileId
     * @returns DeactivateUserAccountResponse
     * @throws ApiError
     */
    public userDeactivateAccountDestroy(
        userProfileId: number,
    ): CancelablePromise<DeactivateUserAccountResponse> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/user/deactivate-account/{userProfileId}/',
            path: {
                'userProfileId': userProfileId,
            },
        });
    }
    /**
     * Deactivate user delegate.
     * @param requestBody
     * @returns DelegatesListResponse
     * @throws ApiError
     */
    public userDelegateDeactivateCreate(
        requestBody: DeactivateDelegateRequest,
    ): CancelablePromise<DelegatesListResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/user/delegate/deactivate/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Restore user delegate.
     * @param requestBody
     * @returns DelegatesListResponse
     * @throws ApiError
     */
    public userDelegateRestoreCreate(
        requestBody: RestoreDelegateRequest,
    ): CancelablePromise<DelegatesListResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/user/delegate/restore/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update delegate permission.
     * @param requestBody
     * @returns DelegatesListResponse
     * @throws ApiError
     */
    public userDelegateUpdatePermisisonCreate(
        requestBody: UpdateDelegatePermissionRequest,
    ): CancelablePromise<DelegatesListResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/user/delegate/update-permisison/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get delegate user.
     * @returns DelegatesListResponse
     * @throws ApiError
     */
    public userDelegatesRetrieve(): CancelablePromise<DelegatesListResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/user/delegates/',
        });
    }
    /**
     * Creates a favourite clinician for the logged in user.
     * @param clinicianUserProfileId
     * @returns FavouriteClinicianCreateResponse
     * @throws ApiError
     */
    public userFavouriteClinicianAddCreate(
        clinicianUserProfileId: number,
    ): CancelablePromise<FavouriteClinicianCreateResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/user/favourite-clinician/add/{clinician_user_profile_id}/',
            path: {
                'clinician_user_profile_id': clinicianUserProfileId,
            },
        });
    }
    /**
     * Returns a list of favourite clinicians.
     * @returns FavouriteClinicianGetResponse
     * @throws ApiError
     */
    public userFavouriteClinicianGetRetrieve(): CancelablePromise<FavouriteClinicianGetResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/user/favourite-clinician/get/',
        });
    }
    /**
     * Deletes the favourite clinician object for the logged in user.
     * @param clinicianUserProfileId
     * @returns FavouriteClinicianDeleteResponse
     * @throws ApiError
     */
    public userFavouriteClinicianRemoveDestroy(
        clinicianUserProfileId: number,
    ): CancelablePromise<FavouriteClinicianDeleteResponse> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/user/favourite-clinician/remove/{clinician_user_profile_id}/',
            path: {
                'clinician_user_profile_id': clinicianUserProfileId,
            },
        });
    }
    /**
     * Invites a clinician to join the platform.
     * @param requestBody
     * @returns InviteClinicianResponse
     * @throws ApiError
     */
    public userInviteClinicianCreate(
        requestBody: InviteClinicianRequest,
    ): CancelablePromise<InviteClinicianResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/user/invite-clinician/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update the user profile details of the logged in user.
     * @param requestBody
     * @returns UpdateUserNotificationsSettingsResponse
     * @throws ApiError
     */
    public userNotificationsUpdateUpdate(
        requestBody: UpdateUserNotificationsSettingsRequest,
    ): CancelablePromise<UpdateUserNotificationsSettingsResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/user/notifications/update/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Resets the users password
     * @param requestBody
     * @returns ResetUserPasswordResponse
     * @throws ApiError
     */
    public userPasswordResetCreate(
        requestBody: ResetUserPasswordRequest,
    ): CancelablePromise<ResetUserPasswordResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/user/password/reset/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Set new password for user, not logged in
     * @param requestBody
     * @returns ResetUserPasswordSetNewResponse
     * @throws ApiError
     */
    public userPasswordResetSetNewCreate(
        requestBody: ResetUserPasswordSetNewRequest,
    ): CancelablePromise<ResetUserPasswordSetNewResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/user/password/reset/set-new/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Updates the users password while logged in
     * @param requestBody
     * @returns UpdateUserPasswordResponse
     * @throws ApiError
     */
    public userPasswordUpdateUpdate(
        requestBody: UpdateUserPasswordRequest,
    ): CancelablePromise<UpdateUserPasswordResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/user/password/update/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Upload a profile picture to the logged in users account.
     * @param formData
     * @returns UpdateUserProfilePictureResponse
     * @throws ApiError
     */
    public userProfilePicUploadCreate(
        formData: UpdateUserProfilePictureRequest,
    ): CancelablePromise<UpdateUserProfilePictureResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/user/profile-pic/upload/',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * Update referral contacts for the logged in user.
     * @param requestBody
     * @returns UpdateReferralContactsResponse
     * @throws ApiError
     */
    public userReferralContactsUpdateUpdate(
        requestBody?: UpdateReferralContactsRequest,
    ): CancelablePromise<UpdateReferralContactsResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/user/referral-contacts-update/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Request new feature.
     * @param requestBody
     * @returns RequestNewFeatureResponse
     * @throws ApiError
     */
    public userRequestFeatureCreate(
        requestBody: RequestNewFeatureRequest,
    ): CancelablePromise<RequestNewFeatureResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/user/request-feature/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Request help.
     * @param requestBody
     * @returns RequestHelpResponse
     * @throws ApiError
     */
    public userRequestHelpCreate(
        requestBody: RequestHelpRequest,
    ): CancelablePromise<RequestHelpResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/user/request-help/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update the user profile details of the logged in user.
     * @param requestBody
     * @returns UpdateUserProfileResponse
     * @throws ApiError
     */
    public userUpdateUpdate(
        requestBody: UpdateUserProfileRequest,
    ): CancelablePromise<UpdateUserProfileResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/user/update/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
