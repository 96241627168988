import { useEffect } from 'react';
import { Dispatch } from 'redux';
import { NavigateFunction } from 'react-router-dom';
import { getAnyApiClient, getAuthApiClient } from '@/actions/apiActions';
import { GpsFromPostcodeRequest, GpsFromPostcodeResponse } from '@client';
import * as SEARCONSTS from '@/constants/searchConstants';
import { PatientByIdResponse, ClinicianInfoByIdResponse } from '@client';
import { checkIsAuthenticated, redirectIfInvalidToken } from './authService';

type ApiClient = ReturnType<typeof getAnyApiClient>;
type ApiClientAuth = ReturnType<typeof getAuthApiClient>;


type Location = {
    postcode: string;
    lat: number;
    lng: number;
}


export class SearchService {
    private dispatch: Dispatch;
    private navigate: NavigateFunction;
    private isAuthenticated: boolean;
    private appAnyClient: ApiClient;
    private appAuthClient: ApiClientAuth;

    constructor ( dispatch: Dispatch, navigate: NavigateFunction ) {
        this.dispatch = dispatch;
        this.navigate = navigate;
        this.appAnyClient = getAnyApiClient();
        this.appAuthClient = getAuthApiClient();
        this.isAuthenticated = checkIsAuthenticated();
    }

    setSearchPatientNull(): void {
        this.dispatch( { type: SEARCONSTS.SET_PATIENT_NULL_ACTION.SUCCESS } );
    }

    setSearchCrpNull(): void {
        this.dispatch( { type: SEARCONSTS.SET_CRP_NULL_ACTION.SUCCESS } );
    }

    setJobRoleNull(): void {
        this.dispatch( { type: SEARCONSTS.SET_JOB_ROLE_NULL_ACTION.SUCCESS } );
    }

    setClinicianNull(): void {
        this.dispatch( { type: SEARCONSTS.SET_CLINICIAN_NULL_ACTION.SUCCESS } );
    }

    async setSearchPatientById( pxId: number ): Promise<boolean> {
        this.dispatch( { type: SEARCONSTS.SET_PATIENT_ACTION.REQUEST } );

        try {
            const data: PatientByIdResponse = await this.appAuthClient.patient.patientRetrieve( pxId );
            this.dispatch( { type: SEARCONSTS.SET_PATIENT_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: SEARCONSTS.SET_PATIENT_ACTION.FAIL, payload: error } );
            return false;
        }
    }

    async setSearchCrpById( crpId: number ): Promise<boolean> {
        this.dispatch( { type: SEARCONSTS.SET_CRP_ACTION.REQUEST } );

        let data: ClinicianInfoByIdResponse;
        try {
            if ( !this.isAuthenticated ) {
                data = await this.appAnyClient.clinicianInformation.clinicianInformationRetrieve( crpId );
            } else {
                data = await this.appAuthClient.clinicianInformation.clinicianInformationRetrieve( crpId );
            }
            this.dispatch( { type: SEARCONSTS.SET_CRP_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: SEARCONSTS.SET_CRP_ACTION.FAIL } );
            return false;
        }
    }

    async setClinicianById( crpId: number ): Promise<boolean> {
        this.dispatch( { type: SEARCONSTS.SET_CLINICIAN_ACTION.REQUEST } );

        let data: ClinicianInfoByIdResponse;
        try {
            if ( !this.isAuthenticated ) {
                data = await this.appAnyClient.clinicianInformation.clinicianInformationRetrieve( crpId );
            } else {
                data = await this.appAuthClient.clinicianInformation.clinicianInformationRetrieve( crpId );
            }
            this.dispatch( { type: SEARCONSTS.SET_CLINICIAN_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            redirectIfInvalidToken( { error, navigate: this.navigate, dispatch: this.dispatch } );
            this.dispatch( { type: SEARCONSTS.SET_CLINICIAN_ACTION.FAIL } );
            return false;
        }
    }


    setSearchGps( postCode: string, lat: number, lng: number ) {
        this.dispatch( {
            type: SEARCONSTS.SET_GPS_ACTION.REQUEST,
            payload: { postCode, lat, lng }
        } );
    };

    async setSearchPostcode( inputData: GpsFromPostcodeRequest ): Promise<boolean> {
        this.dispatch( { type: SEARCONSTS.SET_POSTCODE_ACTION.REQUEST } );
        try {
            const data: GpsFromPostcodeResponse = await this.appAnyClient.getGps.getGpsCreate( inputData );
            this.dispatch( { type: SEARCONSTS.SET_POSTCODE_ACTION.SUCCESS, payload: data } );
            return true;
        } catch ( error ) {
            this.dispatch( { type: SEARCONSTS.SET_POSTCODE_ACTION.FAIL, payload: error } );
            return false;
        }
    }
    async getSearchPostcodeData( inputData: GpsFromPostcodeRequest ): Promise<Location> {
        this.dispatch( { type: SEARCONSTS.SET_POSTCODE_ACTION.REQUEST } );
        try {
            const data: GpsFromPostcodeResponse = await this.appAnyClient.getGps.getGpsCreate( inputData );
            this.dispatch( { type: SEARCONSTS.SET_POSTCODE_ACTION.SUCCESS, payload: data } );
            return data;
        } catch ( error ) {
            this.dispatch( { type: SEARCONSTS.SET_POSTCODE_ACTION.FAIL, payload: error } );
            return null;
        }
    }
}