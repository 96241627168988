import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDelegateService } from '@actions';

const useRegisterDelegates = () => {
	const delegateService = useDelegateService();

	useEffect(() => {
		const loadDelegates = async () => {
			await delegateService.getUserDelegateList();
		};
		loadDelegates();
	}, []);

	const delegates = useSelector((state) => state.profile.delegates);
	const inactiveDelegates = useSelector(
		(state) => state.profile.pendingDelegates
	);
	return { delegates, inactiveDelegates };
};

export default useRegisterDelegates;
