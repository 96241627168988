import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import InviteUserForm from '../user/InviteUserForm';
import useReferralNotifications from '@hooks/referrals/useReferralNotifications';
import useUserPermissions from '@hooks/user/useUserPermissions';
import { useAuthService, useUserService } from '@actions';
import useScreenInfo from '@/hooks/app/useScreenInfo';

function Header() {
	const authService = useAuthService();
	const userService = useUserService();
	const isAuthenticated = authService.hasAuthToken();
	const userProfile = useSelector((state) => state.auth.user);
	const location = useLocation();
	const { pathname } = location;

	const [isNarrow] = useScreenInfo(768);
	const maxLogoHeight = isNarrow ? 60 : 100;

	const allowAllPaths = ['/', '/login', '/register', '/policy', '/faqs'];

	const handleCheckAuth = async () => {
		if (userProfile.id === null && !allowAllPaths.includes(pathname)) {
			await authService.checkAuth();
			await userService.loadUserProfile();
		}
	};

	useEffect(() => {
		handleCheckAuth();
		return () => {};
	}, []);

	const isVerified = useSelector((state) => state.auth.user.isVerified);
	const userGroup = useSelector((state) => state.auth.userGroup);
	const isAdmin = userGroup === 'Staff' || userGroup === 'Full Admin';
	const isFullAdmin = userGroup === 'Full Admin';
	const isDelegate = userGroup === 'Delegate';
	const { permisisonCreateReferrals } = useUserPermissions();
	const { nReferrals } = useReferralNotifications(isAuthenticated);
	const [displayInviteUserForm, setDisplayInviteUserForm] = useState(false);

	function handleLogout() {
		authService.logout();
	}

	const authMainLinks = (
		<>
			<LinkContainer to="/dashboard">
				<Nav.Link>Dashboard</Nav.Link>
			</LinkContainer>
			<NavDropdown title="Patients" id="patientsmenue">
				{permisisonCreateReferrals && (
					<LinkContainer to="/patients/add">
						<NavDropdown.Item>Add new patient</NavDropdown.Item>
					</LinkContainer>
				)}
				<LinkContainer to="/patients">
					<NavDropdown.Item>View patients</NavDropdown.Item>
				</LinkContainer>
			</NavDropdown>
			<NavDropdown
				title={
					<span style={{ position: 'relative' }}>
						Referrals
						{nReferrals > 0 && (
							<span className="header-badge header-badge-red">
								{nReferrals}
							</span>
						)}
					</span>
				}
				id="referralsmenue"
			>
				{permisisonCreateReferrals && (
					<LinkContainer to="/referral/add">
						<NavDropdown.Item>Make referral</NavDropdown.Item>
					</LinkContainer>
				)}
				<LinkContainer to="/referrals">
					<NavDropdown.Item>View Referrals</NavDropdown.Item>
				</LinkContainer>
			</NavDropdown>
			<LinkContainer to="/clinicians">
				<Nav.Link>Find a clinician</Nav.Link>
			</LinkContainer>
			<Nav.Link onClick={() => setDisplayInviteUserForm(true)}>
				Invite a clinician
			</Nav.Link>
			{displayInviteUserForm ? (
				<InviteUserForm
					showModal={displayInviteUserForm}
					handleClose={() => setDisplayInviteUserForm(false)}
				/>
			) : null}
		</>
	);

	const adminLinks = (
		<>
			<LinkContainer to="/clinicians">
				<Nav.Link>Find a clinician</Nav.Link>
			</LinkContainer>
			<Nav.Link onClick={() => setDisplayInviteUserForm(true)}>
				Invite a clinician
			</Nav.Link>
			{displayInviteUserForm ? (
				<InviteUserForm
					showModal={displayInviteUserForm}
					handleClose={() => setDisplayInviteUserForm(false)}
				/>
			) : null}
			<NavDropdown title="Admin" id="adminmenue">
				<LinkContainer to="/staff/users">
					<NavDropdown.Item>Users</NavDropdown.Item>
				</LinkContainer>
				<LinkContainer to="/staff/clinician-roles">
					<NavDropdown.Item>Clinician Roles</NavDropdown.Item>
				</LinkContainer>
				<LinkContainer to="/staff/patients">
					<NavDropdown.Item>Patients</NavDropdown.Item>
				</LinkContainer>
				<LinkContainer to="/staff/referrals">
					<NavDropdown.Item>Referrals</NavDropdown.Item>
				</LinkContainer>
				<LinkContainer to="/staff/insurers">
					<NavDropdown.Item>Insurers</NavDropdown.Item>
				</LinkContainer>
			</NavDropdown>
		</>
	);

	return (
		<header>
			<Navbar bg="dark" variant="dark" expand="lg" collapseOnSelect>
				<Container>
					<LinkContainer to="/">
						<Navbar.Brand>
							<img
								src="/logos/white_logo_transparent_background.png"
								alt="MedR Logo"
								style={{ maxHeight: maxLogoHeight }}
							/>
						</Navbar.Brand>
					</LinkContainer>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="responsive-navbar-nav">
						<Nav className="mr-auto">
							{isAuthenticated && isVerified && !isAdmin && authMainLinks}
							{isAdmin && adminLinks}
							{isAuthenticated ? (
								<NavDropdown
									id="username"
									title={
										<span>
											<i className="fa-solid fa-address-card" /> Profile
										</span>
									}
								>
									{isFullAdmin && (
										<LinkContainer to="/staff/add-admin">
											<NavDropdown.Item>
												<i className="fa-solid fa-people-group" /> Add new admin
											</NavDropdown.Item>
										</LinkContainer>
									)}
									{!isAdmin && (
										<LinkContainer to="/profile">
											<NavDropdown.Item>
												<i className="fa-solid fa-address-card" /> View/update
											</NavDropdown.Item>
										</LinkContainer>
									)}
									{!isDelegate && !isAdmin && (
										<LinkContainer to="/delegates">
											<NavDropdown.Item>
												<i className="fa-solid fa-people-group" /> Delegate
												Accounts
											</NavDropdown.Item>
										</LinkContainer>
									)}

									<LinkContainer to="/auth/help">
										<NavDropdown.Item>
											<i className="fa-solid fa-circle-info" /> Help
										</NavDropdown.Item>
									</LinkContainer>
									<LinkContainer to="/">
										<NavDropdown.Item onClick={handleLogout}>
											<i className="fa-solid fa-right-from-bracket" /> Logout
										</NavDropdown.Item>
									</LinkContainer>
								</NavDropdown>
							) : (
								<>
									<LinkContainer to="/login">
										<Nav.Link>
											<i className="fas fa-user" /> Login
										</Nav.Link>
									</LinkContainer>
									{pathname.includes('/register') === false && (
										<LinkContainer to="/register">
											<Nav.Link>
												<i className="fas fa-notes-medical" /> Register
											</Nav.Link>
										</LinkContainer>
									)}
									<LinkContainer to="/help">
										<Nav.Link>
											<i className="fas fa-circle-info" /> Help
										</Nav.Link>
									</LinkContainer>
								</>
							)}
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</header>
	);
}

export default Header;
