import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Modal, Button, Form } from 'react-bootstrap';
import { EmailFormField } from '../app/parts/FormFields';
import { funcPropType, boolPropType } from '@constants/propTypes';
import { useClinicianService } from '@actions';

function InviteUserForm({ showModal, handleClose }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const clinicianService = useClinicianService();

	const handleInviteClinician = async (formData) => {
		const inviteSuccess = await clinicianService.inviteClinicianToMedr(
			formData
		);
		if (!inviteSuccess) return;
		setFormData(initData);
		handleClose();
	};

	const initData = { name: '', email: '' };

	const [formData, setFormData] = useState(initData);
	const { name, email } = formData;
	const onChange = (e) =>
		setFormData({ ...formData, [e.target.name]: e.target.value });
	const changeValue = (e) => setFormData({ ...formData, ...e });

	const onSubmit = () => handleInviteClinician({ name, email });

	return (
		<Modal show={showModal} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title className="medr-inv">
					Invite a clinician to MedR
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="medr">
				<Form
					className="text-left w80"
					style={{ marginLeft: 'auto', marginRight: 'auto' }}
				>
					<Form.Group controlId="invitedUserName" className="mb-3">
						<Form.Label className="my-0v">Name</Form.Label>
						<Form.Control
							required
							type="name"
							placeholder=""
							name="name"
							value={name}
							onChange={(e) => onChange(e)}
						/>
					</Form.Group>

					<div className="mb-3">
						<EmailFormField
							email={email}
							emailKey="email"
							emailLabel="Email"
							emailPlaceholder=""
							changeValues={changeValue}
						/>
					</div>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="primary" onClick={() => onSubmit(formData)}>
					Invite
				</Button>
				<Button variant="danger" onClick={handleClose}>
					Cancel
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

InviteUserForm.propTypes = {
	showModal: boolPropType.isRequired,
	handleClose: funcPropType.isRequired,
};

export default InviteUserForm;
