/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RegisterDelegateUserRequest } from '../models/RegisterDelegateUserRequest';
import type { RegisterDelegateUserResponse } from '../models/RegisterDelegateUserResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class RegisterDelegateService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Registers user, creates user profile and main clinician profile.
     * @param requestBody
     * @returns RegisterDelegateUserResponse
     * @throws ApiError
     */
    public registerDelegateCreate(
        requestBody: RegisterDelegateUserRequest,
    ): CancelablePromise<RegisterDelegateUserResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/register-delegate/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
