import * as CONSTS from '@constants/searchConstants';

const initialState = {
	loading: false,
	errorMessage: '',
	referrerCrp: {},
	targetCrp: {},
	patient: {},
	jobRole: { id: 0 },
	postCode: '',
	lat: CONSTS.DEFAULT_LAT,
	lng: CONSTS.DEFAULT_LNG,
	mapsZoom: 10,
};

// eslint-disable-next-line default-param-last
export default function search(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case CONSTS.SET_JOB_ROLE_REQUEST:
		case CONSTS.SET_JOB_ROLE_NULL_ACTION.REQUEST:
		case CONSTS.SET_PATIENT_ACTION.REQUEST:
		case CONSTS.SET_PATIENT_NULL_ACTION.REQUEST:
		case CONSTS.SET_CRP_ACTION.REQUEST:
		case CONSTS.SET_CRP_NULL_ACTION.REQUEST:
		case CONSTS.SET_CLINICIAN_ACTION.REQUEST:
		case CONSTS.SET_CLINICIAN_NULL_ACTION.REQUEST:
		case CONSTS.SET_POSTCODE_ACTION.REQUEST:
			return {
				...state,
				loading: true,
			};
		case CONSTS.SET_GPS_ACTION.REQUEST:
			return {
				...state,
				postCode: payload.postCode,
				lat: payload.lat,
				lng: payload.lng,
			};
		case CONSTS.SET_JOB_ROLE_SUCCESS:
			return {
				...state,
				loading: false,
				jobRole: payload.jobRole,
			};
		case CONSTS.SET_JOB_ROLE_NULL_ACTION.SUCCESS:
			return {
				...state,
				loading: false,
				jobRole: { id: 0 },
			};
		case CONSTS.SET_PATIENT_ACTION.SUCCESS:
			return {
				...state,
				loading: false,
				patient: payload.patient,
				lat: payload.patient.addressLatitude,
				lng: payload.patient.addressLongitude,
			};
		case CONSTS.SET_PATIENT_NULL_ACTION.SUCCESS:
			return {
				...state,
				loading: false,
				patient: {},
				lat: CONSTS.DEFAULT_LAT,
				lng: CONSTS.DEFAULT_LNG,
			};
		case CONSTS.SET_CRP_ACTION.SUCCESS:
			return {
				...state,
				loading: false,
				referrerCrp: payload.crp,
			};
		case CONSTS.SET_CRP_NULL_ACTION.SUCCESS:
			return {
				...state,
				loading: false,
				referrerCrp: {},
			};
		case CONSTS.SET_CLINICIAN_ACTION.SUCCESS:
			return {
				...state,
				loading: false,
				targetCrp: payload.crp,
			};
		case CONSTS.SET_CLINICIAN_NULL_ACTION.SUCCESS:
			return {
				...state,
				loading: false,
				targetCrp: {},
			};
		case CONSTS.SET_POSTCODE_ACTION.SUCCESS:
			return {
				...state,
				loading: false,
				errorMessage: '',
				postCode: payload.postcode,
				lat: parseFloat(payload.lat, 10),
				lng: parseFloat(payload.lng, 10),
			};
		case CONSTS.SET_JOB_ROLE_FAIL:
		case CONSTS.SET_JOB_ROLE_NULL_ACTION.FAIL:
		case CONSTS.SET_PATIENT_ACTION.FAIL:
		case CONSTS.SET_PATIENT_NULL_ACTION.FAIL:
		case CONSTS.SET_CRP_ACTION.FAIL:
		case CONSTS.SET_CRP_NULL_ACTION.FAIL:
			return {
				...state,
				loading: false,
			};
		case CONSTS.SET_POSTCODE_ACTION.FAIL:
			return {
				...state,
				loading: false,
				errorMessage: payload,
			};
		default:
			return state;
	}
}
