import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PatientEntryForm from '@components/patient/PatientEntryForm';
import { usePatientService } from '@actions';

function PatientEntryScreen() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const patientService = usePatientService();

	const [isInitialised, setIsInitialised] = useState(false);

	const initialise = async () => {
		patientService.resetCurrentPatient();
		setIsInitialised(true);
	};
	useEffect(() => {
		initialise();
		return () => {};
	}, []);

	const successfullRequest = (results) => {
		if (!results.success) return;
		patientService.goToPatientsList();
	};

	return (
		<div
			className="medr-layout text-center newpatient-page"
			style={{ maxWidth: '700px', marginLeft: 'auto', marginRight: 'auto' }}
		>
			<Row className="medr-layout text-center">
				<Col>
					<h4>Enter Patient Information</h4>
				</Col>
			</Row>

			{isInitialised && (
				<PatientEntryForm
					inputPatientId={null}
					isSuccessful={successfullRequest}
				/>
			)}
		</div>
	);
}

export default PatientEntryScreen;
